import * as React from "react";
import _ from 'lodash';
import { Button } from "antd";
import { ContactPointView, OrganizationRoleView, OrganizationView, PagedPersonQuery, PersonView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { openNotification, updateCollectionFromEvent } from "../../Helpers/BasePageHelpers";
import client from "../../ApiClient/client";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorLink } from "../Actors";
import { ContactPointLink } from "../ContactPoints";
import { ColumnExpander } from "../Shared/ColumnExpander";
import { Drawer } from "../Shared/Drawer";
import { PersonCreateForm, PersonOrganizationRoleForm } from "../People";
import ListViewCard from "../Shared/ListViewCard";


interface PeopleInOrganizationListtProps {
    organization: OrganizationView,
    className?: string
}

interface PeopleInOrganizationListState {
    showCreateDrawer: boolean;
    people: PersonView[];
    error: string;
    loading: boolean;
    organizationRole: OrganizationRoleView;
    person: PersonView;
    loadingRemove: boolean;
}

export class PeopleInOrganizationList extends React.Component<PeopleInOrganizationListtProps, PeopleInOrganizationListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            people: [],
            loading: false,
            showCreateDrawer: false,
            error: null,
            organizationRole: null,
            person: null,
            loadingRemove: false
        }
    }

    componentDidMount = () => {
        if (this.props.organization) {
            this.loadPeople();

            this.context.events.people.onMany({
                'updated': this.onItemUpdatedEvent,
                'deleted': this.onItemUpdatedEvent,
                'restored': this.onItemUpdatedEvent,
            });
            this.context.events.contactPoints.onMany({
                'created': this.onCpUpdatedEvent,
                'updated': this.onCpUpdatedEvent,
                'deleted': this.onCpUpdatedEvent,
                'restored': this.onCpUpdatedEvent,
            });
        }
    }

    componentWillUnmount = () => {
        if (this.props.organization) {
            this.context.events.people.offMany({
                'updated': this.onItemUpdatedEvent,
                'deleted': this.onItemUpdatedEvent,
                'restored': this.onItemUpdatedEvent,
            });
            this.context.events.contactPoints.offMany({
                'created': this.onCpUpdatedEvent,
                'updated': this.onCpUpdatedEvent,
                'deleted': this.onCpUpdatedEvent,
                'restored': this.onCpUpdatedEvent,
            });
        }
    }

    onItemUpdatedEvent = async (person: PersonView) => {
        const people = this.state.people.slice();
        const index = _.indexOf(people, _.find(people, p => { return p.id == person.id }));
        const roleInOrganization = _.find(person.organizationRoles, r => { return r.organization.id == this.props.organization.id; });

        const removedFromOrg = () => {
            people.splice(index, 1);
            this.setState({ people });
            openNotification(`${person.name} has been removed from a role for this organization`, null, "success");
        };

        if (roleInOrganization && index != -1) { //Updated role 
            if (roleInOrganization.endDate) {
                removedFromOrg();
            }
            else {
                const newCollection = await updateCollectionFromEvent(this.state.people, person);
                this.setState({ people: newCollection as PersonView[] });
            }
        }
        else if (roleInOrganization && !roleInOrganization.endDate && index == -1) { //Added new role
            people.push(person);
            this.setState({ people });
            openNotification(`${person.name} has been assigned a role for this organization`, null, "success");
        }
        else if (!roleInOrganization && index != -1) { //Removed role
            removedFromOrg();
        }
    }

    onCpUpdatedEvent = async (cp: ContactPointView) => {
        if (cp) {
            const people = this.state.people.slice();
            const person = _.find(people, person => person.id == cp.contact.id);
            const personIndex = _.findIndex(people, p => p.id == person.id);

            if (!person.contactPoints) {
                person.contactPoints = [cp];
            } else {
                const index = _.findIndex(person.contactPoints, c => c.id == cp.id);
                if (index == -1) {
                    person.contactPoints.push(cp);
                } else {
                    person.contactPoints.splice(index, 1, cp);
                }
            }
            people.splice(personIndex, 1, person);
            this.setState({ people });
        }
    }

    loadPeople = async () => {
        this.setState({ loading: true });

        const query: Partial<PagedPersonQuery> = {
            organizationId: this.props.organization.id,
            deleted: false,
            limit: 9999
        };

        const response = await client.people.queryPeople(query).catch(exeption => this.setState({ error: exeption.error.title }));
        if (response) this.setState({ people: response.data.items });

        this.setState({ loading: false });
    }

    toggleCreateDrawer = () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onEdit = async (person: PersonView) => {
        const role = _.find(person.organizationRoles, r => { return r.organization.id == this.props.organization.id; });
        if (role) {
            this.setState({
                person: person,
                organizationRole: role
            });
        }
    }

    onEditDrawerClose = (person: PersonView) => {
        const collection = this.state.people?.slice() ?? [];
        const index = _.findIndex(collection, c => c.id == person.id);

        if (index != -1) collection[index] = person;
        else collection.push(person);

        this.setState({
            person: null,
            organizationRole: null,
            people: collection
        });
    }

    render = () => {
        if (!this.props.organization) return null;
        if (!this.context.user.hasCapability(Capabilities.PeopleReadRelated)) return null;

        const listColumns = [
            {
                title: 'Name',
                render: (person: PersonView) => <ActorLink {...person} />,
                key: 'person',
                align: 'left'
            },
            /* {
                title: 'Access level',
                render: (person: PersonView) => {
                    const orgRole = _.find(person.organizationRoles, r => {
                        return r.organization.id == this.props.organization.id;
                    });

                    return orgRole.role;
                },
                key: 'person',
                width: 115,
                responsive: ['xl']
            }, */
            {
                title: 'Job title',
                render: (person: PersonView) => {
                    const orgRole = _.find(person.organizationRoles, r => {
                        return r.organization.id == this.props.organization.id;
                    });

                    return orgRole.description;
                },
                key: 'jobTitle',
                responsive: ['xl']
            },
            {
                title: 'Description',
                render: (person: PersonView) => <div className="people-org-desc">{person.description}</div>,
                key: 'description',
                width: '35%',
                responsive: ['xxl']
            },
            {
                title: 'Contact information',
                render: (person: PersonView) => {
                    const contactPoints = _.map(person.contactPoints, cp => {
                        return <div key={cp.id}><ContactPointLink {...cp} /></div>
                    });
                    return <ColumnExpander className="actor-cp-expander">{contactPoints}</ColumnExpander>;
                },
                key: 'contactPoints',
            }
        ];

        if (this.context.user.hasCapability(Capabilities.PeopleWrite)) {
            listColumns.push(
                //@ts-ignore
                {
                    title: '',
                    render: (data: PersonView) =>
                        <Button
                            className="edit-btn"
                            type="link"
                            style={{ margin: 0, padding: 0 }}
                            onClick={() => this.onEdit(data)}
                        >Details</Button>,
                    key: 'actions',
                    align: 'right',
                    //@ts-ignore
                    width: 70
                }
            );
        }

        return (
            <>
                <Drawer
                    title="Add contact to organization"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose
                    component={
                        <PersonCreateForm
                            organizationId={this.props.organization.id}
                            onComplete={this.toggleCreateDrawer}
                            onCancel={this.toggleCreateDrawer}
                            newPerson
                        />
                    }
                />

                <Drawer
                    title="Edit organization role"
                    onClose={() => this.setState({ person: null, organizationRole: null })}
                    open={this.state.organizationRole != null}
                    destroyOnClose
                    component={
                        <PersonOrganizationRoleForm
                            organization={this.props.organization}
                            organizationRole={this.state.organizationRole}
                            actor={this.state.person}
                            onComplete={this.onEditDrawerClose}
                            onCancel={() => this.setState({ person: null, organizationRole: null })}
                        />
                    }
                />

                <ListViewCard
                    title="Contacts"
                    onAdd={this.context.user.hasCapability(Capabilities.PeopleWrite) ? this.toggleCreateDrawer : null}
                    columns={listColumns}
                    data={this.state.people}
                    loading={this.state.loading}
                    takeElements={50}
                    loadAllOnLoadMore
                    className={`people-in-organization-list ${this.props.className ? this.props.className : ''}`}
                />
            </>
        );
    }
}

export default PeopleInOrganizationList;