import * as React from "react";
import _ from 'lodash';
import { Card, Empty, Typography } from 'antd'
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown';
import exporting from 'highcharts/modules/exporting';
import exportdata from 'highcharts/modules/export-data';
import boost from 'highcharts/modules/boost';
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { getHistogramOptions } from "../../Helpers/GraphHelpers";
import { capitalizeFirstLetter } from "../../Helpers/Formatters";
import { HistogramCardProps, HistogramType, NormalHistogramProps, SeriesData, StackedHistogramCardProps, StackedHistogramProps } from "../../Definitions/_graphinterfaces";
import { SortDirection } from "../../ApiClient/swagger/data-contracts";
import { useMemo } from "react";

drilldown(Highcharts);
exporting(Highcharts);
exportdata(Highcharts);
boost(Highcharts);

const { Title } = Typography;


export function Histogram(props: NormalHistogramProps | StackedHistogramProps) {
    const handleHistogramData = (element): [any[], SeriesData] => {
        const { xField, type } = props;

        const drilldownSeries = [];
        const iterator = element.data ?? element;

        const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

        const data = _.map(iterator, (d, key) => {
            let yField = null;
            let drilldownField = null;
            let drilldownXField = null;
            let drilldownYField = null;
            let color = "#2679A1";

            if (props.hasOwnProperty("yField")) {
                const propsTyped = props as NormalHistogramProps;
                yField = propsTyped.yField;
                drilldownField = propsTyped.drilldownField;
                drilldownXField = propsTyped.drilldownXField;
                drilldownYField = propsTyped.drilldownYField;
            } else {
                yField = element.seriesYField;
                drilldownField = element.drilldownField;
                drilldownXField = element.drilldownXField;
                drilldownYField = element.drilldownYField;
                if (element.color) color = element.color;
            }

            const obj = {
                name: capitalizeFirstLetter(d[xField]),
                y: d[yField],
                yFieldUpperCase: capitalizeFirstLetter(yField),
                color: d.color ? d.color : color,
                custom: null,
                noDataLabel: d.noDataLabel ? d.noDataLabel : false
            };

            if (guidPattern.test(key)) {
                obj.custom = { id: key };
            }

            if (d[drilldownField]) {
                const drilldownId = element.seriesName
                    ? element.seriesName + d[xField] + element.drilldownYField
                    : d[xField] + (element.drilldownYField ?? d[drilldownField]);

                obj['drilldown'] = drilldownId;

                const ddown = {
                    name: element.seriesName ?? capitalizeFirstLetter(d[xField]),
                    id: drilldownId,
                    data: [],
                    color: color,
                    stacking: element.stacking ?? null
                };

                _.each(d[drilldownField], dd => {
                    ddown.data.push({
                        name: capitalizeFirstLetter(dd[drilldownXField]),
                        y: dd[drilldownYField],
                        yFieldUpperCase: capitalizeFirstLetter(drilldownYField),
                        drilldownFieldName: capitalizeFirstLetter(drilldownXField)
                    });
                });

                let sortedDrilldownData = props.stopSort ? ddown.data : _.sortBy(ddown.data, 'y').reverse();

                if (props.sortDirection && !props.stopSort) {
                    if (props.sortDirection == SortDirection.Asc) {
                        sortedDrilldownData = sortedDrilldownData.reverse();
                    }
                }

                ddown.data = sortedDrilldownData;

                drilldownSeries.push(ddown);
            }

            return obj;
        });

        let sortedData = props.stopSort ? data : props.sortBy ? _.sortBy(data, props.sortBy) : _.sortBy(data, 'y').reverse();

        if (props.sortDirection && !props.stopSort) {
            if (props.sortDirection == SortDirection.Asc) {
                sortedData = sortedData.reverse();
            }
        }

        if (props.totalDisplayCount) {
            sortedData = _.take(sortedData, props.totalDisplayCount);
        } else {
            sortedData = _.take(sortedData, 5);
        }

        const series = {
            name: element.seriesName ?? "Data",
            data: sortedData,
            color: element.color ?? null,
            type: type
        };

        if (element.stacking) {
            series["stacking"] = element.stacking;
        }

        return [drilldownSeries, series];
    };

    const getHistogramData = () => {
        let seriesData = [];
        let drilldownData = [];

        if (props.hasOwnProperty("yField")) {
            const propsTyped = props as NormalHistogramProps;
            const obj = handleHistogramData(propsTyped.data);
            drilldownData = [...drilldownData, ...obj[0]];
            seriesData = [obj[1]];
        } else {
            seriesData = _.map(props.data, s => {
                const obj = handleHistogramData(s);
                drilldownData = [...drilldownData, ...obj[0]];
                return obj[1];
            });

            const lastSortedList = seriesData && seriesData.length > 0 ? seriesData[seriesData.length - 1] ?? null : null;

            if (lastSortedList) {
                const sortedSeriesData = [];
                _.each(seriesData, s => {
                    const sortedData = s.data.sort((a, b) => {
                        const aIndex = _.findIndex(lastSortedList.data, (l: any) => l.name == a.name);
                        const bIndex = _.findIndex(lastSortedList.data, (l: any) => l.name == b.name);
                        return aIndex - bIndex;
                    });

                    s.data = sortedData;
                    sortedSeriesData.push(s);
                });

                seriesData = sortedSeriesData;
            }
        }

        if (props.hasOwnProperty("categories")) {
            seriesData = props.data;
        }

        return {
            seriesData: seriesData,
            drilldownData: drilldownData
        };
    };

    const histogramData = useMemo(() => getHistogramData(), [props.data, props.sortDirection, props.stopSort, props.totalDisplayCount, props.sortBy]);
    const options = useMemo(() => {
        const opts = getHistogramOptions(props, histogramData?.seriesData ?? []);
        opts.series = histogramData.seriesData;
        opts.drilldown = {
            series: histogramData.drilldownData,
            drillUpButton: props.drillUpOptions ? props.drillUpOptions : {}
        };
        return opts;
    }, [histogramData, props]);

    if (!props.data) return null;

    const customClass = props.className ? props.className : null;

    return (
        <HighchartsReact
            highcharts={Highcharts}
            containerProps={{
                style: {
                    textAlign: "-webkit-center"
                },
                className: `chart histogram-chart ${customClass}`
            }}
            options={options}
        />
    );
};

const BaseColumnChart = (props: HistogramCardProps | StackedHistogramCardProps) => (
    <Card
        title={
            <div className="list-title">
                {typeof props.title == "string" ? <Title level={4} className="title">{props.title}</Title> : props.title}
                {props.actions ?? null}
            </div>
        }
        className={`graph-card column-chart-card ${props.cardClassName ? props.cardClassName : ""}`}
        bordered={props.disableCardBorder ? false : true}
    >
        {props.loading ?
            <Loading3QuartersOutlined spin className="icon icon-loading" />
            : Object.keys(props.data || {}).length > 0
                ? <Histogram type={HistogramType.Column} {...props} />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    </Card>
);

export class ColumnChartCard extends React.Component<HistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseColumnChart {...this.props} />;
    }
}

export class StackedColumnChartCard extends React.Component<StackedHistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseColumnChart {...this.props} isStackedHistogram />;
    }
}


const BaseBarChart = (props: HistogramCardProps | StackedHistogramCardProps) => (
    <Card
        title={
            <div className="list-title">
                {typeof props.title == "string" ? <Title level={4} className="title">{props.title}</Title> : props.title}
                {props.actions ?? null}
            </div>
        }
        className={`graph-card bar-chart-card ${props.cardClassName ? props.cardClassName : ""}`}
        bordered={props.disableCardBorder ? false : true}
    >
        {props.loading ?
            <Loading3QuartersOutlined spin className="icon icon-loading" />
            : Object.keys(props.data || {}).length > 0
                ? <Histogram type={HistogramType.Bar} {...props} />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    </Card>
);

export class BarChartCard extends React.Component<HistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseBarChart {...this.props} />;
    }
}

export class StackedBarChartCard extends React.Component<StackedHistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseBarChart {...this.props} isStackedHistogram />;
    }
}

const BaseLineChart = (props: HistogramCardProps | StackedHistogramCardProps) => (
    <Card
        title={
            <div className="list-title">
                {typeof props.title == "string" ? <Title level={4} className="title">{props.title}</Title> : props.title}
                {props.actions ?? null}
            </div>
        }
        className={`graph-card line-chart-card ${props.cardClassName ? props.cardClassName : ""}`}
        bordered={props.disableCardBorder ? false : true}
    >
        {props.loading ?
            <Loading3QuartersOutlined spin className="icon icon-loading" />
            : Object.keys(props.data || {}).length > 0
                ? <Histogram type={HistogramType.Line} {...props} />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
    </Card>
);

export class LineChartCard extends React.Component<HistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseLineChart {...this.props} />;
    }
}

export class MultipleLineChartCard extends React.Component<StackedHistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BaseLineChart {...this.props} />;
    }
}

const BasePieChart = (props: HistogramCardProps) => (
    <Card
        title={typeof props.title == "string" ? <div><Title level={4} className="title">{props.title}</Title></div> : props.title}
        className={`graph-card line-chart-card ${props.cardClassName ? props.cardClassName : ""}`}
        bordered={props.disableCardBorder ? false : true}
    >
        {props.loading ?
            <Loading3QuartersOutlined spin className="icon icon-loading" /> :
            <Histogram type={HistogramType.Pie} {...props} />
        }
    </Card>
);

export class BasePieChartCard extends React.Component<HistogramCardProps> {
    render = () => {
        if (this.props.data === undefined) return null;
        return <BasePieChart {...this.props} />;
    }
}
