import * as React from 'react';
import _ from 'lodash';
import { Checkbox, Card, Tooltip, Button } from 'antd';
import {
    LoadingOutlined, ClockCircleOutlined, PhoneOutlined, UserOutlined, ProjectOutlined, GroupOutlined,
    IssuesCloseOutlined, ContactsOutlined, BankOutlined, ScheduleOutlined, DeliveredProcedureOutlined,
    ToolOutlined, AimOutlined, FundProjectionScreenOutlined, SlidersOutlined, ReadOutlined, TagsOutlined,
    TeamOutlined, LoginOutlined, BookOutlined, ApiOutlined, FolderOutlined, TableOutlined, FileOutlined,
    MenuOutlined, AreaChartOutlined, UpOutlined, DownOutlined, GoldOutlined, UsergroupAddOutlined, UngroupOutlined,
    FormOutlined, DashboardOutlined, LinkOutlined, InfoCircleOutlined, CarryOutOutlined, CreditCardOutlined, CarOutlined
} from '@ant-design/icons';
import AppContext from '../../Definitions/AppContext';


export interface CapabilityGroupView {
    name: string;
    hasRequirements: boolean;
    subGroups: CapabilitySubGroupView[];
}

export interface CapabilitySubGroupView {
    name: string;
    hasRequirements: boolean;
    capabilities: CapabilityView[];
}

export interface CapabilityView {
    id: string;
    name: string;
    description: string;
    includes: string[];
    requires: string[];
}

interface Capability {
    id: string;
    name: string;
    group: string;
    subGroup: string;
    description: string;
    includes: string[];
    requires: string[];
}

interface CapabilitiesEditorProps {
    apiCapabilitites: CapabilityGroupView[];
    applicationCapabilities?: CapabilityGroupView[];
    selected: string[];
    applicationSpesific?: boolean;
}

interface CapabilitiesEditorState {
    isLoading: boolean;
    openAdvancedOptions: string[];
}


export class CapabilitiesEditor extends React.Component<CapabilitiesEditorProps, CapabilitiesEditorState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);


        //var capabilities = [...this.props.apiCapabilitites, ...this.props.applicationCapabilities];
        //var selected = this.filterSelectedToExisting(this.props.initiallySelected, capabilities)
        //var included = this.getIncludesFromSelected(selected, capabilities)

        this.state = {
            isLoading: false,
            openAdvancedOptions: [],
        }
    }

    flattenCapabilities = (capabilities: CapabilityGroupView[]) => {
        return _.flatMap(capabilities, group => {
            return _.flatMap(group.subGroups, subGroup => {
                return _.map(subGroup.capabilities, c => {
                    return {
                        id: c.id,
                        name: c.name,
                        description: c.description,
                        group: group.name,
                        subGroup: subGroup.name,
                        includes: c.includes,
                        requires: c.requires,
                    } as Capability;
                })
            })
        });
    }

    filterSelectedToExisting = (selected: string[], capabilities: Capability[]) => {
        return _.filter(selected, (id) => {
            if (_.find(capabilities, (c) => c.id == id)) {
                return true;
            }
            return false;
        });
    }

    getIncludesFromSelected = (selected: string[], capabilities: Capability[]) => {

        if (!selected || selected.length == 0) return []

        const includes = _.flatMap(selected, id => {
            const capability = _.find(capabilities, c => c.id === id)
            return capability ? capability.includes : [];
        });

        return _.uniq(selected.concat(
            this.getIncludesFromSelected(includes, capabilities)
        ));
    }

    getIcon = (group:string) => {
        const icons = {
            "Applications": <ApiOutlined />,
            "Calls": <PhoneOutlined />,
            "Contact points": <BookOutlined />,
            "Dashboard setup": <DashboardOutlined />,
            "Employee": <TeamOutlined />,
            "External links": <LinkOutlined />,
            "External logins": <LoginOutlined />,
            "Documents (specific location)": <FileOutlined />,
            "Documents": <FileOutlined />,
            "Incidents": <IssuesCloseOutlined />,
            "Invoices": <FundProjectionScreenOutlined />,
            "Leads": <AimOutlined />,
            "Meetings": <ScheduleOutlined />,
            "Menu": <MenuOutlined />,
            "News": <ReadOutlined />,
            "Notes and comments": <FormOutlined />,
            "Orders": <DeliveredProcedureOutlined />,
            "Organizations": <BankOutlined />,
            "People": <ContactsOutlined />,
            "Products": <ToolOutlined />,
            "Profile": <UserOutlined />,
            "Projects": <ProjectOutlined />,
            "Roles": <UsergroupAddOutlined />,
            "Reports": <AreaChartOutlined />,
            "Resources": <GoldOutlined />,
            "Routines": <CarryOutOutlined />,
            "Sensors": <SlidersOutlined />,
            "Shared folders": <FolderOutlined />,
            "Subscriptions": <TableOutlined />,
            "Tags": <TagsOutlined />,
            "Tickets": <GroupOutlined />,
            "Time tracking": <ClockCircleOutlined />,
            "Order category": <UngroupOutlined />,
            "Customers": <CreditCardOutlined />,
            "Suppliers": <CarOutlined />,
            "Contacts and organizations": <ContactsOutlined />,
        };

        const icon = icons.hasOwnProperty(group)
            ? icons[group]
            : null;

        return icon;
    }

    getSubgroupTooltip = (subGroup:string) => {
        const tooltips = {
            "Related tickets":
                "Internal:\n" +
                "Tickets where the person is listed as assignee or requester. \n \n" +
                "External:\n" +
                "Tickets where the person is listed as requester. Tickets connected to an organization where the person is listed with access level standard. Tickets where the person is customer.",

            "Related hours":
                "Internal:\n" +
                "Hours tracked on tasks where the person is task owner. Hours tracked on tasks in projects where the person is project leader. Hours tracked on a customer where the person is listed as handler. This includes hours tracked on a customers project. \n \n" +
                "External:\n" +
                "Hours tracked on a customer where the person is listed with access level standard. This includes hours tracked on a customers project.",

            "Related calls":
                "Internal:\n" +
                "Calls from/to people connected to the same organization as the person. Calls from/to an organization connected to the person. Calls from/to a person, and organziation or people in an organization where the person is listed as handler. \n \n" +
                "External:\n" +
                "Calls from/to people connected to the organization where the person is listed with access level standard. Calls from/to the organization where the person is listed with access level standard.",

            "Related projects":
                "Internal:\n" +
                "Projects where the person is leader, participant or viewer. Projects connected to an organization where the person is listed as handler. \n \n" +
                "External:\n" +
                "Projects connected to an organization where the person is listed with access level standard.",

            "Personal routines":
                "Internal:\n" +
                "Routines where the person is owner. \n \n" +
                "External:\n" +
                "Routines where the person is owner.",

            "Related routines":
                "Internal:\n" +
                "Routines where the person is owner or responsible.\n \n" +
                "External:\n" +
                "Routines connected to an organization where the person is registered with access level limited or standard. Routines where the person is owner or responsible. Routines connected to a resource where the person is related.",

            "Related incidents":
                "Internal:\n" +
                "Incidents where the person is owner or another particpant. Incidents connected to a routine where the person is owner. \n \n" +
                "External:\n" +
                "Incidents connected to an organization where the person is listed with access level standard. Incidents connected to a resource where the person is related.",

        };
        const tooltip = tooltips.hasOwnProperty(subGroup)
            ? tooltips[subGroup]
            : null;

        return tooltip;
    }

    getSubGroupSortOrder = (subGroup:string) => {

        if (subGroup == null) return 0;

        if (subGroup.startsWith("Personal")) return 1;
        if (subGroup.startsWith("Related")) return 2;
        if (subGroup.startsWith("Everyone")) return 3;
        if (subGroup.startsWith("All")) return 3;

        return 4;

        //var subGroups = {
        //    "Personal hours": 1,
        //    "Personal calls": 1,
        //    "Personal routines": 1,
        //    "Personal meetings": 1,

        //    "Related hours": 2,
        //    "Related calls": 2,
        //    "Related incidents": 2,
        //    "Related routines": 2,
        //    "Related tickets": 2,

        //    "Everyone's hours": 3,
        //    "Everyone's calls": 3,
        //    "All routines": 3,
        //    "All tickets": 3,
        //    "All meetings": 3,

        //    "Extra": 4,
        //}

        //var sortIndex = subGroups.hasOwnProperty(subGroup)
        //    ? subGroups[subGroup]
        //    : null;

        //return sortIndex;
    }

    getGroupHeight = (group: CapabilityGroupView) => {
        if (group.name === "Other") {
            const bah = "test";
        }

        const subgroups = _.filter(group.subGroups, x => x.name).length;
        const capabilities = _.flatMap(group.subGroups, subGroup => subGroup.capabilities).length;

        const lines = (subgroups*2) + capabilities;
        let height = 1;

        if (lines >= 8) 
            height++;

        if (lines > 14)
            height++;
        
        return height;
    }

    toggleAdvancedView = (group: string) => {
        const openAdvancedOptions = this.state.openAdvancedOptions?.slice() ?? [];

        if (openAdvancedOptions.includes(group))
            openAdvancedOptions.splice(_.indexOf(openAdvancedOptions, group), 1);
        else
            openAdvancedOptions.push(group);

        this.setState({ openAdvancedOptions });
    }


    render() {
        if (this.state.isLoading) return <LoadingOutlined />;

        let allCapabilities = [...(this.props.apiCapabilitites || []), ...(this.props.applicationCapabilities ?? [])]

        const flatCapabilities = this.flattenCapabilities(allCapabilities);

        const selected = this.filterSelectedToExisting(this.props.selected, flatCapabilities);
        const included = this.getIncludesFromSelected(selected, flatCapabilities);

        if (this.props.applicationSpesific) {
            allCapabilities = (this.props.apiCapabilitites || []);
        }

        const regularGroups = _.filter(allCapabilities, c => c.hasRequirements == false);
        const advancedGroups = _.filter(allCapabilities, c => c.hasRequirements == true);
        
        const mapGroup = (group: CapabilityGroupView) => {

            const mapCapability = (capability: CapabilityView) => {

                const isSelected = _.find(selected, x => x == capability.id) != null;
                const isIncluded = _.find(included, x => x == capability.id) != null;
                let isHidden = false;
                let isAdvanced = false;

                if (capability.requires.length > 0) {
                    isAdvanced = true;

                    for (const index in capability.requires) {
                        var required = capability.requires[index];

                        if (_.find(included, x => x == required) == null) {
                            isHidden = true;
                            break;
                        }
                    }
                }

                const tooltip = capability.description && capability.description.length > 0 ? capability.description : null;

                return {
                    view: () =>
                        <div className="capability-items" style={isHidden ? { display: "none" } : {}}>
                            <Checkbox value={capability.id} indeterminate={!isSelected && isIncluded}>
                                {capability.name} {tooltip ? <Tooltip placement="right" overlayStyle={{ whiteSpace: 'pre-line' }} title={tooltip}><InfoCircleOutlined /></Tooltip> : null }
                            </Checkbox>
                        </div>
                    ,
                    hidden: isHidden,
                    advanced: isAdvanced
                }
            };

            const mapSubGroup = (subGroup: CapabilitySubGroupView) => {

                const tooltip = this.getSubgroupTooltip(subGroup.name);

                const mappedCapabilities = _.map(subGroup.capabilities, (x: CapabilityView) => mapCapability(x));
                const isHidden = _.find(mappedCapabilities, x => x.hidden == false) == null;
                const isAdvanced = _.find(mappedCapabilities, x => x.advanced == true) != null;
                const capabilityViews = _.map(mappedCapabilities, (x, i) => <x.view key={`c-${i}`} />);

                return {

                    view: () =>
                        <div className="sub-group-view" key={subGroup.name ?? "0"} style={isHidden ? { display: "none" } : {}}>
                            {subGroup.name ? <div className="subGroupTitle"><span>{subGroup.name}</span>{tooltip ? <Tooltip placement="right" overlayStyle={{ whiteSpace: 'pre-line' }} title={tooltip}><InfoCircleOutlined /></Tooltip> : null}</div> : null}
                            {capabilityViews}
                        </div>,
                    hidden: isHidden,
                    advanced: isAdvanced,
                };

            }

            const sortedSubGroups = _.sortBy(group.subGroups, s => this.getSubGroupSortOrder(s.name));
            const mappedSubGroups = _.map(sortedSubGroups, x => mapSubGroup(x));

            const icon = this.getIcon(group.name);
            const height = this.getGroupHeight(group);

            const regularSubGroups = _.filter(mappedSubGroups, x => x.advanced == false);
            const advancedSubGroups = _.filter(mappedSubGroups, x => x.advanced == true);
            const hideAdvancedSubGroups = _.find(advancedSubGroups, x => x.hidden == false) == null;

            const regularSubGroupViews = _.map(regularSubGroups, (x, i) => <x.view key={`r-${i}`} />)
            const advancedSubGroupViews = _.map(advancedSubGroups, (x, i) => <x.view key={`a-${i}`} />)


            const isAdvancedOpen = this.state.openAdvancedOptions.includes(group.name);
            const isHidden = _.find(mappedSubGroups, x => x.hidden == false) == null;

            const attributes = {
                'data-height': advancedSubGroups.length > 0 && !isAdvancedOpen ? 1 : height
            }


            return {
                
                view: () =>
                    <Card {...attributes} key={group.name} className="capability-group" title={
                        <span className="group-title-container">
                            <div className="group-title">
                                <span className="group-icon">{icon}</span>
                                <span>{group.name}</span>
                            </div>
                            {group.name === 'Documents (specific location)' ? <span style={{ fontWeight: 400, fontSize: 14 }}>Placed directly on organizations, projects, etc.</span> : null}
                        </span>}>

                        <div className="capability-items">
                            {regularSubGroupViews}
                            <div className="advanced-options-container" style={hideAdvancedSubGroups ? { display: "none" } : {} }>
                                <div className="advanced-options" onClick={() => this.toggleAdvancedView(group.name)}>Advanced options <span className="toggle-icon">{isAdvancedOpen ? <UpOutlined /> : <DownOutlined />}</span></div>
                                <div className="advanced-capabilities">{<div className="advanced-grouped-view" style={isAdvancedOpen ? {} : { display: "none" }}>{advancedSubGroupViews}</div>}</div>
                            </div>
                        </div>
                    </Card>,
                hidden: isHidden,
                height: height
            }
        }

        const combineGroups = (mappedGroups => {
            let result = [];

            const columns =
                window.innerWidth > 1600 ? 5 :
                    window.innerWidth > 1300 ? 3 :
                            1;

            _.each(mappedGroups, (g) => {
                if (g.height == 3) {
                    result.push([g]);
                    return;
                }

                const index = _.findIndex(result, x => {
                    const nulls = _.filter(x, y => y == null );
                    return nulls.length >= g.height;
                })

                if (index >= 0) {
                    const existing = result[index];
                    const used = _.filter(existing, y => y != null);
                    //var availableSpace = existing.length - used.length;

                    //if (availableSpace == g.height) {
                    result[index] = [...used, g];
                    //} else {
                    //    result[index] = [...used, g, null];
                    //}
                }
                else {
                    const space = [];
                    if (columns > 1) {
                        if (g.height == 1) {
                            if (result.length < columns) {
                                space.push(null);
                                space.push(null);
                            }
                            else if (result.length < (columns*2)) {
                                space.push(null);
                            }
                        }
                        else if (g.height == 2) {
                            if (result.length < columns) {
                                space.push(null);
                            }
                        }
                    }

                    result.push([g, ...space]);
                }



              
            });

            result = _.map(result, r => _.filter(r, r2 => r2 != null));

            //for (var i = result.length - 1; i > -1; i--) {
            //    var group = result[i];
            //    if (group.length == 2) break;

            //    var before = result.slice(0, i);
            //    var after = result.slice(i + 1);

            //    var newGroups = _.map(group, g2 => [g2]);
                
            //    result = [...before, ...newGroups, ...after];
            //}



            //var maxHeight = _.max(_.map(lastRow, x => x.height));

            return _.map(result, (r, i) => {

                if (r.length == 1) {
                    const x = r[0];
                    return <x.view key={`gr-${i}`} />;
                }

                const height = _.sumBy(r, 'height');
                const attr = {
                    'data-height': /*i >= result.length - numberOfItemsInLastRow ? maxHeight :*/ height
                }
                const views = _.map(r, (y, j) => {
                    return <y.view key={`gr-${i}-${j}`} />
                })
                return (
                    <div className="capability-group-combined" {...attr} key={`gr-${i}`}>
                        {views}
                    </div>
                );
            });
        });

        const mappedApplicationGroups = _.map(this.props.applicationCapabilities ?? [], x => mapGroup(x));
        const mappedRegularGroups = _.map(regularGroups, x => mapGroup(x));
        const mappedAdvancedGroups = _.map(advancedGroups, x => mapGroup(x));
        const hideAdvancedGroups = _.find(mappedAdvancedGroups, x => x.hidden == false) == null;

        const applicationGroupViews = combineGroups(mappedApplicationGroups);
        const regularGroupViews = combineGroups(mappedRegularGroups);
        const advancedGroupViews = combineGroups(mappedAdvancedGroups);

        return (
            <div>
                {this.props.applicationSpesific ? <div className="capability-editor">{applicationGroupViews}</div> : null}
                {this.props.applicationSpesific ? <div className="special-capabilities" style={mappedRegularGroups.length == 0 ? { display: "none" } : {}}>Permissions</div> : null}

                <div className="capability-editor">{regularGroupViews}</div>
                <div className="special-capabilities" style={hideAdvancedGroups ? { display: "none" } : {}}>Based on the given permissions</div>
                <div className="capability-editor" style={hideAdvancedGroups ? { display: "none" } : {}}>{advancedGroupViews}</div>
            </div>
        );
    }
}

export default CapabilitiesEditor;