/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AbsenceCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface AbsenceCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  requiresAdminApproval?: boolean;
  informationText?: string | null;
}

export interface AbsenceCategoryViewEntityViewModel {
  view?: AbsenceCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface AbsenceCategoryViewPagedAbsenceCategoryQueryCollectionViewModel {
  items?: AbsenceCategoryView[] | null;
  query?: PagedAbsenceCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface AccountingNumber {
  /** @format int64 */
  amount?: number;
  /** @format int32 */
  business_id?: number;
  category?: string | null;
  /** @format int32 */
  id?: number;
  subCategory?: string | null;
  /** @format int32 */
  year?: number;
  /** @format int32 */
  rowIndex?: number;
  categoryTitle?: string | null;
}

export interface ActivityAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: ActivityType[] | null;
  subTypes?: ActivitySubType[] | null;
  actorId?: string | null;
  relatedActorId?: string | null;
  relatedActorId_ExcludePurePersonal?: boolean | null;
  categoryIds?: string[] | null;
  taskId?: string | null;
  taskClosed?: boolean | null;
  projectId?: string | null;
  projectActive?: boolean | null;
  projectEnded?: boolean | null;
  relationId?: string | null;
  ticketId?: string | null;
  productId?: string | null;
  meetingId?: string | null;
  groupId?: string | null;
  participantIds?: string[] | null;
  actorIdOrParticipantId?: string | null;
  /** @format date-time */
  startAfter?: string | null;
  /** @format date-time */
  startBefore?: string | null;
  /** @format date-time */
  endBefore?: string | null;
  /** @format date-time */
  endAfter?: string | null;
  /** @format date-time */
  calendarStart?: string | null;
  /** @format date-time */
  calendarEnd?: string | null;
  /** @format date-time */
  activeAt?: string | null;
  confirmed?: boolean | null;
  locked?: boolean | null;
  running?: boolean | null;
  requiresAdminApproval?: boolean | null;
  tagIds?: string[] | null;
  categoryTypes?: HourCategoryType[] | null;
  aggs?: ActivityAggregationTypes[] | null;
  /** @format int32 */
  comparePeriods?: number | null;
  compareCategories?: string[] | null;
  historyCategories?: string[] | null;
}

export interface ActivityAggregationResult {
  totalDuration?: AggregateSingleResult;
  startMonthYear?: Record<string, Aggregate>;
  actorDuration?: Record<string, Aggregate>;
  categoryDuration?: Record<string, Aggregate>;
  customerDuration?: Record<string, Aggregate>;
  productDuration?: Record<string, Aggregate>;
  projectDuration?: Record<string, Aggregate>;
  bySubType?: Aggregate[] | null;
  taskDuration?: Record<string, Aggregate>;
  typeDuration?: Aggregate[] | null;
  categoryTypeDuration?: Aggregate[] | null;
  durationComparePeriodsByMonth?: Record<string, Record<string, Aggregate>>;
  durationComparePeriodsByYear?: Record<string, AggregateSingleResult>;
  activitiesPerCategoryPerMonth?: Record<string, SingleDrilldown>;
  employeePerCategory?: Record<string, SingleEntityDrilldown>;
  activitiesPerEmployeePerMonth?: Record<string, SingleDrilldown>;
  activitiesPerEmployeePerWeek?: Record<string, SingleDrilldown>;
  activitiesPerEmployeePerDay?: Record<string, SingleDrilldown>;
  employeePerTask?: Record<string, SingleEntityDrilldown>;
  activitiesPerTaskPerMonth?: Record<string, SingleDrilldown>;
}

export interface ActivityAggregationResultActivityAggregateQueryAggregationResults {
  results?: ActivityAggregationResult;
  query?: ActivityAggregateQuery;
}

export enum ActivityAggregationTypes {
  TotalDuration = "TotalDuration",
  MonthDuration = "MonthDuration",
  DurationComparePeriodsByMonth = "DurationComparePeriodsByMonth",
  DurationComparePeriodsByYear = "DurationComparePeriodsByYear",
  ActorDuration = "ActorDuration",
  CategoryDuration = "CategoryDuration",
  CustomerDuration = "CustomerDuration",
  ProductDuration = "ProductDuration",
  ProjectDuration = "ProjectDuration",
  BySubType = "BySubType",
  TaskDuration = "TaskDuration",
  TypeDuration = "TypeDuration",
  CategoryTypeDuration = "CategoryTypeDuration",
  CategoriesPerMonth = "CategoriesPerMonth",
  EmployeePerCategory = "EmployeePerCategory",
  EmployeePerMonth = "EmployeePerMonth",
  EmployeePerWeek = "EmployeePerWeek",
  EmployeePerDay = "EmployeePerDay",
  EmployeePerTask = "EmployeePerTask",
  TasksPerMonth = "TasksPerMonth",
}

export interface ActivityGroupView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  organizer?: ParticipantInfoView;
  participants?: ParticipantInfoView[] | null;
}

export enum ActivitySortOption {
  Actor = "Actor",
  Organization = "Organization",
  Project = "Project",
  Task = "Task",
  Start = "Start",
  End = "End",
  Category = "Category",
  Relation = "Relation",
  LastModified = "LastModified",
}

export enum ActivitySubType {
  HourlyInvoiceable = "Hourly_Invoiceable",
  HourlyNotInvoicable = "Hourly_NotInvoicable",
  Fixed = "Fixed",
  InternalWork = "Internal_Work",
  InternalAbsence = "Internal_Absence",
}

export interface ActivityTransferAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  /** @format date-time */
  lastCalculated?: string | null;
  aggs?: ActivityTransferAggregationTypes[] | null;
}

export interface ActivityTransferAggregationResults {
  transferredYearMonth?: Record<string, SingleDrilldown>;
}

export interface ActivityTransferAggregationResultsActivityTransferAggregateQueryAggregationResults {
  results?: ActivityTransferAggregationResults;
  query?: ActivityTransferAggregateQuery;
}

export enum ActivityTransferAggregationTypes {
  TransferredYearMonth = "TransferredYearMonth",
}

export enum ActivityTransferSortOption {
  Start = "Start",
  LastModified = "LastModified",
}

export interface ActivityTransferStats {
  /** @format int64 */
  count?: number;
  /** @format double */
  hours?: number;
  /** @format double */
  sum?: number;
}

export interface ActivityTransferView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  /** @format date-time */
  lastCalculated?: string | null;
  isCalculating?: boolean;
  requireTransfer?: boolean;
  hasWarnings?: boolean;
  hasConfirmed?: boolean;
  transferred?: ActivityTransferStats;
  confirmed?: ActivityTransferStats;
  unconfirmed?: ActivityTransferStats;
}

export interface ActivityTransferViewEntityViewModel {
  view?: ActivityTransferView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ActivityTransferViewPagedActivityTransferQueryCollectionViewModel {
  items?: ActivityTransferView[] | null;
  query?: PagedActivityTransferQuery;
  /** @format int64 */
  count?: number;
}

export enum ActivityType {
  Project = "Project",
  Customer = "Customer",
  Internal = "Internal",
}

export interface ActivityView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  type?: ActivityType;
  subType?: ActivitySubType;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string | null;
  description?: string | null;
  confirmed?: boolean;
  locked?: boolean;
  /** @format int64 */
  duration?: number;
  actor?: ActorLinkView;
  project?: ProjectLinkView;
  task?: TaskLinkViewWithParents;
  category?: HourCategoryLinkView;
  product?: ProductLinkView;
  ticket?: TicketLinkView;
  relation?: ActorLinkView;
  tags?: TagLinkView[] | null;
  productSet?: boolean;
  meetingId?: string | null;
  groupId?: string | null;
  organizer?: ParticipantInfoView;
  participants?: ParticipantInfoView[] | null;
}

export interface ActivityViewEntityViewModel {
  view?: ActivityView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ActivityViewPagedActivityQueryCollectionViewModel {
  items?: ActivityView[] | null;
  query?: PagedActivityQuery;
  /** @format int64 */
  count?: number;
}

export enum ActivityWarningType {
  CustomerDeleted = "Customer_Deleted",
  CustomerNotFound = "Customer_NotFound",
  CustomerNotInvoiceable = "Customer_NotInvoiceable",
  InvoiceReceiverDeleted = "InvoiceReceiver_Deleted",
  InvoiceReceiverNotFound = "InvoiceReceiver_NotFound",
  InvoiceReceiverNotInvoiceable = "InvoiceReceiver_NotInvoiceable",
  TaskDeleted = "Task_Deleted",
  TaskNotFound = "Task_NotFound",
  ProjectDeleted = "Project_Deleted",
  ProjectNotFound = "Project_NotFound",
  ProjectMissingCustomer = "Project_MissingCustomer",
  CategoryDeleted = "Category_Deleted",
  CategoryNotFound = "Category_NotFound",
  CategoryMissingProduct = "Category_MissingProduct",
  ProductDeleted = "Product_Deleted",
  ProductNotFound = "Product_NotFound",
  ProductMissingPrice = "Product_MissingPrice",
}

export interface ActivityWarningView {
  type?: ActivityWarningType;
  project?: ProjectLinkView;
  task?: TaskLinkView;
  category?: CategoryLinkView;
  product?: ProductLinkView;
  invoiceReceiver?: ActorLinkView;
  customer?: ActorLinkView;
  /** @format int64 */
  count?: number;
}

export interface ActivityWarningViewPagedActivityTransferWarningQueryCollectionViewModel {
  items?: ActivityWarningView[] | null;
  query?: PagedActivityTransferWarningQuery;
  /** @format int64 */
  count?: number;
}

export interface ActorExtendedLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
  contactPoints?: ContactPointLinkView[] | null;
}

export interface ActorLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
}

export interface ActorMetadataView {
  /** @format date-time */
  lastCall?: string | null;
  /** @format date-time */
  lastOrder?: string | null;
  /** @format date-time */
  subscriptionDuration?: string | null;
  /** @format date-time */
  lastHourRegistering?: string | null;
}

export interface ActorRelatedNotesView {
  id?: string | null;
  byActorId?: string | null;
  byActorName?: string | null;
  callFromActorId?: string | null;
  callFromOrganizationIds?: string[] | null;
  callToOrganizationIds?: string[] | null;
  callFromActorName?: string | null;
  callToActorId?: string | null;
  callToActorName?: string | null;
  relatedActorIds?: string[] | null;
  text?: string | null;
  /** @format date-time */
  timestamp?: string | null;
  entityType?: string | null;
}

export interface ActorReportsView {
  products?: ProductReportsView;
  resources?: ResourceReportView;
}

export enum ActorSortOption {
  Name = "Name",
  LastModified = "LastModified",
  Type = "Type",
}

export enum ActorType {
  Organization = "Organization",
  Person = "Person",
}

export interface ActorView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  actorType?: ActorType;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
}

export interface ActorViewEntityViewModel {
  view?: ActorView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ActorViewPagedActorQueryCollectionViewModel {
  items?: ActorView[] | null;
  query?: PagedActorQuery;
  /** @format int64 */
  count?: number;
}

export interface AddOrUpdateAddressCollectionItem {
  name?: string | null;
  line1?: string | null;
  line2?: string | null;
  zipCode?: string | null;
  area?: string | null;
  country?: string | null;
  coordinates?: Coordinates;
  note?: string | null;
  types?: AddressType[] | null;
}

export interface AddOrUpdateClosingCommentForIncident {
  closingComment?: string | null;
}

export interface AddOrUpdateCorrectiveActionForIncident {
  correctiveAction?: string | null;
}

export interface AddOrUpdateCustomerInfo {
  email?: string | null;
  /** @format double */
  creditLimit?: number | null;
  categoryId?: string | null;
}

export interface AddOrUpdateFinancialConsequences {
  /** @format double */
  financialCost?: number | null;
  costComment?: string | null;
}

export interface AddOrUpdateNoteForCall {
  /** @minLength 1 */
  note: string;
}

export interface AddOrUpdateNumberSeries {
  /** @format int32 */
  from: number;
  /** @format int32 */
  to: number;
  /** @minLength 1 */
  applicationId: string;
}

export interface AddOrUpdatePrice {
  /** @format double */
  sales?: number | null;
  /** @format double */
  cost?: number | null;
}

export interface AddOrUpdateProductPart {
  subProductId?: string | null;
  /** @format double */
  quantity?: number;
}

export interface AddOrUpdateProjectRole {
  type?: ProjectRoleType;
  description?: string | null;
  actorIds: string[];
}

export interface AddOrUpdateSensorAlertResolution {
  notify?: boolean;
  note?: string | null;
}

export interface AddOrUpdateSupplierInfo {
  categoryId?: string | null;
}

export interface AddOrUpdateSupplierOptions {
  supplierId?: string | null;
}

export interface Address {
  name?: string | null;
  line1?: string | null;
  line2?: string | null;
  zipCode?: string | null;
  area?: string | null;
  country?: string | null;
  coordinates?: Coordinates;
  note?: string | null;
}

export interface AddressCollectionItem {
  name?: string | null;
  line1?: string | null;
  line2?: string | null;
  zipCode?: string | null;
  area?: string | null;
  country?: string | null;
  coordinates?: Coordinates;
  note?: string | null;
  addressId?: string | null;
  types?: AddressType[] | null;
  attributes?: Record<string, string | null>;
}

export enum AddressType {
  Home = "Home",
  Office = "Office",
  Invoice = "Invoice",
  Delivery = "Delivery",
}

export interface Aggregate {
  label?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format double */
  sum?: number | null;
  /** @format double */
  average?: number | null;
}

export interface AggregateSingleResult {
  label?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format double */
  sum?: number | null;
  /** @format double */
  average?: number | null;
}

export enum AlertType {
  Data = "Data",
  Battery = "Battery",
  Signal = "Signal",
}

export interface AlternativeView {
  alternativeId?: string | null;
  title?: string | null;
  voteActors?: PersonLinkView[] | null;
}

export interface ApiCapability {
  resource?: string | null;
  subResource?: string | null;
  action?: string | null;
  limitation?: string | null;
  id?: string | null;
}

export interface AppCapability {
  id?: string | null;
  name?: string | null;
  group?: string | null;
  subGroup?: string | null;
  description?: string | null;
  includes?: string[] | null;
  requires?: string[] | null;
  apiCapabilies?: Record<string, string[] | null>;
}

export interface ApplicationInfo {
  id?: string | null;
  name?: string | null;
  description?: string | null;
}

export interface ApplicationLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export interface ApplicationSettingDefinition {
  key?: string | null;
  label?: string | null;
  required?: boolean;
  defaultValue?: any;
  type?: ApplicationSettingType;
}

export interface ApplicationSettingFieldView {
  key?: string | null;
  label?: string | null;
  required?: boolean;
  defaultValue?: any;
  currentValue?: any;
  type?: ApplicationSettingType;
}

export interface ApplicationSettingFieldsView {
  publicKey?: RSAKey;
  fields?: ApplicationSettingFieldView[] | null;
}

export enum ApplicationSettingType {
  String = "String",
  EncryptedString = "EncryptedString",
  CommaSeparatedList = "CommaSeparatedList",
}

export interface ApplicationSettingsSchema {
  encryptionKey?: RSAKey;
  settings?: ApplicationSettingDefinition[] | null;
}

export enum ApplicationSortOrder {
  Name = "Name",
  LastModified = "LastModified",
  ApplicationUrl = "ApplicationUrl",
}

export interface ApplicationView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  clientId?: string | null;
  hasSecret?: boolean;
  description?: string | null;
  capabilities?: ClientCapabilities;
  assignedApiCapabilities?: Record<string, string[] | null>;
  applicationUrl?: string | null;
  redirectUrls?: RedirectUrl[] | null;
  loginFlows?: LoginFlow[] | null;
  tenant?: TenantLinkView;
  settingsSchema?: ApplicationSettingsSchema;
}

export interface ApplicationViewEntityViewModel {
  view?: ApplicationView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ApplicationViewPagedApplicationQueryCollectionViewModel {
  items?: ApplicationView[] | null;
  query?: PagedApplicationQuery;
  /** @format int64 */
  count?: number;
}

export interface AttachmentView {
  name?: string | null;
  contentType?: string | null;
  /** @format int64 */
  size?: number;
}

export interface BusinessResponse {
  /** @format int32 */
  id?: number;
  name?: string | null;
  /** @format int32 */
  org_number?: number;
  umbrella_business_id?: any;
  /** @format int32 */
  employee_count?: number;
  business_type?: string | null;
  business_code?: string | null;
  street_name?: string | null;
  street_number?: any;
  street_letter?: string | null;
  post_code?: string | null;
  post_place?: any;
  /** @format int32 */
  address_id?: number;
  /** @format date-time */
  established_date?: string;
  /** @format date-time */
  registered_date?: string;
  business_sector_code?: string | null;
  postal_post_code?: string | null;
  chief_executive_officer?: string | null;
  chairman?: string | null;
  /** @format int32 */
  phone_number?: number;
  /** @format int32 */
  mobile_number?: number;
  url?: string | null;
  postal_address?: string | null;
  regimva?: string | null;
  regifr?: string | null;
  regiaa?: string | null;
  bankrupt?: boolean;
  softScore?: string | null;
  liquidation?: boolean;
  compulsaryLiquidation?: boolean;
  bankruptDate?: any;
  businessPersons?: any;
  businessRolesBusinesses?: any;
  shareOwners?: any;
  vehicles?: any;
  accounting?: any;
  comments?: any;
  /** @format date-time */
  sysStartTime?: string;
  /** @format date-time */
  sysEndTime?: string;
}

export interface CallAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  contactId?: string | null;
  customerId?: string | null;
  trackingTypes?: CallTrackingType[] | null;
  contactPointId?: string | null;
  internal?: boolean | null;
  /** @format date */
  fromDate?: string | null;
  /** @format date */
  toDate?: string | null;
  statuses?: CallStatus[] | null;
  missedReasons?: CallMissedReason[] | null;
  directions?: CallDirection[] | null;
  aggs?: CallAggregationTypes[] | null;
}

export interface CallAggregationResult {
  activeCalls?: AggregateSingleResult;
  missedReason?: Aggregate[] | null;
  callsPerDayOfWeek?: Aggregate[] | null;
  totalCallDuration?: AggregateSingleResult;
  callsPerWeek?: Record<string, SingleDrilldown>;
  directionDuration?: Aggregate[] | null;
  employeePerCategory?: Record<string, SingleEntityDrilldown>;
  knownDuration?: Record<string, Aggregate>;
  organizationDuration?: Record<string, Aggregate>;
  personDuration?: Record<string, Aggregate>;
  perTrackedCategory?: Record<string, Aggregate>;
  callsHourOfDay?: Aggregate[] | null;
  callsPerDay?: Aggregate[] | null;
  callsDurationPerMonth?: Record<string, SingleDrilldown>;
  callsDurationPerWeek?: Record<string, SingleDrilldown>;
  callsPerMonthOfYear?: Aggregate[] | null;
}

export interface CallAggregationResultCallAggregateQueryAggregationResults {
  results?: CallAggregationResult;
  query?: CallAggregateQuery;
}

export enum CallAggregationTypes {
  ActiveCalls = "ActiveCalls",
  MissedReason = "MissedReason",
  CallsPerDayOfWeek = "CallsPerDayOfWeek",
  TotalCallDuration = "TotalCallDuration",
  CallsPerWeek = "CallsPerWeek",
  DirectionDuration = "DirectionDuration",
  EmployeePerCategory = "EmployeePerCategory",
  KnownDuration = "KnownDuration",
  OrganizationDuration = "OrganizationDuration",
  PersonDuration = "PersonDuration",
  PerTrackedCategory = "PerTrackedCategory",
  CallsHourOfDay = "CallsHourOfDay",
  CallsPerDay = "CallsPerDay",
  CallsDurationPerMonth = "CallsDurationPerMonth",
  CallsDurationPerWeek = "CallsDurationPerWeek",
  CallsPerMonthOfYear = "CallsPerMonthOfYear",
}

export enum CallDirection {
  Unknown = "Unknown",
  Internal = "Internal",
  Incoming = "Incoming",
  Outgoing = "Outgoing",
}

export enum CallMissedReason {
  Unknown = "Unknown",
  Redirected = "Redirected",
  Cancelled = "Cancelled",
  Busy = "Busy",
  Declined = "Declined",
}

export enum CallSortOption {
  Start = "Start",
  End = "End",
  Date = "Date",
  Duration = "Duration",
  Status = "Status",
  LastModified = "LastModified",
}

export enum CallStatus {
  Ringing = "Ringing",
  Connected = "Connected",
  Completed = "Completed",
  Missed = "Missed",
}

export interface CallTargetView {
  id?: string | null;
  type?: ContactPointType;
  value?: string | null;
  description?: string | null;
  primary?: boolean;
  contact?: ContactLinkView;
  organizationRoles?: OrganizationRoleView[] | null;
}

export enum CallTrackingType {
  Pending = "Pending",
  Transfered = "Transfered",
  Ignored = "Ignored",
  Completed = "Completed",
}

export interface CallTrackingView {
  ignored?: ActorLinkView[] | null;
  completed?: ActorLinkView[] | null;
}

export interface CallTransferView {
  to?: CallTargetView;
  from?: CallTargetView;
  referredBy?: CallTargetView;
}

export interface CallView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  reference?: string | null;
  from?: CallTargetView;
  to?: CallTargetView;
  referredBy?: CallTargetView;
  /** @format date-time */
  init?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  /** @format int64 */
  duration?: number | null;
  direction?: CallDirection;
  status?: CallStatus;
  missedReason?: CallMissedReason;
  transfer?: CallTransferView;
  tracking?: CallTrackingView;
  organizations?: OrganizationLinkView[] | null;
  note?: string | null;
}

export interface CallViewEntityViewModel {
  view?: CallView;
  links?: Record<string, EntityLinkValueView>;
}

export interface CallViewPagedCallQueryCollectionViewModel {
  items?: CallView[] | null;
  query?: PagedCallQuery;
  /** @format int64 */
  count?: number;
}

export interface CallsRelatedNotesView {
  id?: string | null;
  text?: string | null;
  /** @format date-time */
  timestamp?: string | null;
  entityType?: string | null;
  entityName?: string | null;
  entityId?: string | null;
  byActorId?: string | null;
  byActorName?: string | null;
  callId?: string | null;
  callFromActorId?: string | null;
  callFromActorName?: string | null;
  callToActorId?: string | null;
  callToActorName?: string | null;
}

export interface CapabilityInfo {
  application?: ApplicationInfo;
  capabilities?: ClientCapabilities;
}

export interface CategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
}

export enum CategorySortOption {
  Name = "Name",
  Type = "Type",
  LastModified = "LastModified",
}

export interface ChannelLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export enum ChannelSortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface ChannelView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  applications?: ApplicationLinkView[] | null;
}

export interface ChannelViewEntityViewModel {
  view?: ChannelView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ChannelViewPagedChannelQueryCollectionViewModel {
  items?: ChannelView[] | null;
  query?: PagedChannelQuery;
  /** @format int64 */
  count?: number;
}

export interface ClientCapabilities {
  api?: ApiCapability[] | null;
  app?: AppCapability[] | null;
}

export interface CloseIncident {
  closingComment?: string | null;
  closerId?: string | null;
  /** @format date-time */
  closedDate?: string | null;
}

export interface CommentLinkView {
  id?: string | null;
  deleted?: boolean;
  text?: string | null;
  /** @format date-time */
  created?: string;
  author?: PersonLinkView;
  reactions?: ReactionView[] | null;
}

export enum CommentSortOption {
  Created = "Created",
  LastModified = "LastModified",
}

export interface CommentView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  text?: string | null;
  entity?: EntityLinkView;
  author?: PersonLinkView;
  replyToId?: string | null;
  replies?: CommentLinkView[] | null;
  reactions?: ReactionView[] | null;
}

export interface CommentViewEntityViewModel {
  view?: CommentView;
  links?: Record<string, EntityLinkValueView>;
}

export interface CommentViewPagedCommentQueryCollectionViewModel {
  items?: CommentView[] | null;
  query?: PagedCommentQuery;
  /** @format int64 */
  count?: number;
}

export interface CompleteExecution {
  asPlanned?: boolean;
  /** @format date-time */
  timestamp?: string;
}

export interface CompletionPartialView {
  id?: string | null;
  deleted?: boolean;
  item?: ProductLinkView;
  /** @format double */
  quantity?: number;
  /** @format date-time */
  timestamp?: string;
  attributes?: Record<string, string | null>;
}

export enum CompletionSortOption {
  Timestamp = "Timestamp",
  Quantity = "Quantity",
  LastModified = "LastModified",
}

export interface CompletionView {
  id?: string | null;
  deleted?: boolean;
  item?: ProductLinkView;
  /** @format double */
  quantity?: number;
  /** @format date-time */
  timestamp?: string;
  attributes?: Record<string, string | null>;
  order?: OrderLinkView;
  orderline?: OrderlineLinkView;
}

export interface CompletionViewPagedCompletionQueryCollectionViewModel {
  items?: CompletionView[] | null;
  query?: PagedCompletionQuery;
  /** @format int64 */
  count?: number;
}

export interface ContactLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  contactType?: ContactType;
}

export interface ContactLinkViewPagedContactQueryCollectionViewModel {
  items?: ContactLinkView[] | null;
  query?: PagedContactQuery;
  /** @format int64 */
  count?: number;
}

export interface ContactLinkViewViewModel {
  view?: ContactLinkView;
}

export interface ContactPointLinkView {
  id?: string | null;
  type?: ContactPointType;
  value?: string | null;
  description?: string | null;
  primary?: boolean;
}

export interface ContactPointSearchView {
  contact?: ContactLinkView;
  contactPoints?: ContactPointLinkView[] | null;
  organizations?: OrganizationLinkView[] | null;
}

export enum ContactPointSortOption {
  Type = "Type",
  Value = "Value",
  Contact = "Contact",
}

export enum ContactPointType {
  Phone = "Phone",
  Email = "Email",
  Video = "Video",
  Other = "Other",
}

export interface ContactPointView {
  id?: string | null;
  type?: ContactPointType;
  value?: string | null;
  description?: string | null;
  primary?: boolean;
  contact?: ContactLinkView;
}

export interface ContactPointViewPagedContactPointQueryCollectionViewModel {
  items?: ContactPointView[] | null;
  query?: PagedContactPointQuery;
  /** @format int64 */
  count?: number;
}

export interface ContactPointViewViewModel {
  view?: ContactPointView;
}

export enum ContactSortOption {
  Name = "Name",
}

export enum ContactType {
  Organization = "Organization",
  Person = "Person",
  Resource = "Resource",
}

export interface Coordinates {
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
}

export interface CreateApplication {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  applicationUrl?: string | null;
  redirectUrls?: RedirectUrl[] | null;
  assignedApiCapabilities?: Record<string, string[] | null>;
  loginFlows?: LoginFlow[] | null;
  /** @minLength 1 */
  clientId: string;
  clientSecret?: string | null;
  tenantId?: string | null;
}

export interface CreateComment {
  /** @minLength 1 */
  text: string;
  /** @minLength 1 */
  actorId: string;
  replyToId?: string | null;
  /** @format date-time */
  timestamp?: string | null;
}

export interface CreateCompletion {
  /** @format double */
  quantity?: number;
  /** @format date-time */
  timestamp?: string;
  /** @minLength 1 */
  orderlineId: string;
  /** @minLength 1 */
  itemId: string;
}

export interface CreateContactPoint {
  description?: string | null;
  primary?: boolean;
  /** @minLength 1 */
  value: string;
  /** @minLength 1 */
  contactId: string;
  type: ContactPointType;
}

export interface CreateCustomerActivityWebRequest {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  /** @minLength 1 */
  actorId: string;
  groupId?: string | null;
  meetingId?: string | null;
  links: CustomerActivityTypeLinks;
  callId?: string | null;
  copyNoteToCall?: boolean | null;
}

export interface CreateDashboard {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  modules?: string | null;
  roleIds?: string[] | null;
  actorIds?: string[] | null;
  availableAll?: boolean | null;
  standard?: boolean | null;
}

export interface CreateEmployee {
  /** @format double */
  percentage?: number | null;
  /** @format int32 */
  employeeNumber?: number | null;
  /** @format date-time */
  birthday?: string | null;
  relative?: string | null;
  relativeContactInfo?: string | null;
  jobTitle?: string | null;
  salaryTypes?: EmployeeSalaryType[] | null;
  personId?: string | null;
}

export interface CreateFileOrFolder {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  applicationIds?: string[] | null;
  parent?: ParentRef;
}

export interface CreateIncident {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  /** @minLength 1 */
  categoryId: string;
  /** @format date-time */
  occuredDate?: string | null;
  /** @format date-time */
  expectedClosing?: string | null;
  ownerId?: string | null;
  routineId?: string | null;
  customers?: string[] | null;
  resources?: string[] | null;
  otherParticipants?: string[] | null;
  products?: string[] | null;
  tagIds?: string[] | null;
  actorId?: string | null;
  /** @format date-time */
  registeredDate?: string | null;
}

export interface CreateInternalActivityWebRequest {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  /** @minLength 1 */
  actorId: string;
  groupId?: string | null;
  meetingId?: string | null;
  links: InternalActivityTypeLinks;
  callId?: string | null;
  copyNoteToCall?: boolean | null;
}

export interface CreateInvoice {
  projectId?: string | null;
  /** @format date-time */
  issued?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  label?: string | null;
  customerId?: string | null;
  invoiceNo?: string | null;
  orderId?: string | null;
  lines?: CreateInvoiceline[] | null;
}

export interface CreateInvoiceline {
  unit?: ProductUnit;
  reference?: string | null;
  /** @format double */
  salesPrice?: number;
  /** @format double */
  discount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  vat?: number;
  productId?: string | null;
  description?: string | null;
  orderlineId?: string | null;
}

export interface CreateLead {
  title?: string | null;
  description?: string | null;
  ownerId?: string | null;
  customerId?: string | null;
  contactPersonId?: string | null;
  /** @format int64 */
  fixedPrice?: number | null;
  /** @format int64 */
  annualPrice?: number | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format date-time */
  expectedClose?: string | null;
  /** @format date-time */
  closingDate?: string | null;
  probability?: LeadProbability;
  status?: LeadStatus;
  tagIds?: string[] | null;
  causeIds?: string[] | null;
}

export interface CreateLogin {
  roleIds: string[];
  /** @minLength 1 */
  principalId: string;
  /** @minLength 1 */
  email: string;
}

export interface CreateMeasuringPoint {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
  /** @minLength 1 */
  typeId: string;
  /** @minLength 1 */
  resourceId: string;
  /** @minLength 1 */
  sensorId: string;
  partName?: string | null;
  /** @format date-time */
  addMeasurementsFrom?: string | null;
}

export interface CreateMeasuringPointTemplate {
  title?: string | null;
  description?: string | null;
  multipleSensors?: boolean;
}

export interface CreateNote {
  /** @minLength 1 */
  text: string;
  sticky: boolean;
  private: boolean;
  newEntityId?: string | null;
  /** @minLength 1 */
  authorId: string;
  /** @format date-time */
  timestamp?: string | null;
}

export interface CreateNotificationWebRequest {
  personIds?: string[] | null;
  message?: string | null;
  selectAllEmployees?: boolean | null;
  roleIds?: string[] | null;
}

export interface CreateOrUpdateAbsenceCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  requiresAdminApproval?: boolean;
  informationText?: string | null;
}

export interface CreateOrUpdateCall {
  /** @minLength 1 */
  from: string;
  /** @minLength 1 */
  to: string;
  referrer?: string | null;
  /** @format date-time */
  init?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  missedReason?: CallMissedReason;
  /** @minLength 1 */
  reference: string;
}

export interface CreateOrUpdateCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
}

export interface CreateOrUpdateChannel {
  /** @minLength 1 */
  name: string;
  applicationIds: string[];
}

export interface CreateOrUpdateExecution {
  allEmployees?: boolean;
  responsibleIds?: string[] | null;
  /** @format date-time */
  plannedExecutionDate?: string;
  note?: string | null;
  executionType?: RoutineExecutionType;
  execute?: ExecuteExecution;
  postpone?: PostponeExecution;
}

export interface CreateOrUpdateExternalLink {
  name?: string | null;
  group?: string | null;
  url?: string | null;
  variables?: Record<string, string | null>;
  limitToEntityIds?: string[] | null;
}

export interface CreateOrUpdateFeed {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  url: string;
}

export interface CreateOrUpdateIncidentCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
}

export interface CreateOrUpdateMeeting {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end: string;
  type: MeetingType;
  organizer?: ParticipantInfoRequest;
  allDay?: boolean;
  location?: MeetingLocation;
  participants?: ParticipantInfoRequest[] | null;
}

export interface CreateOrUpdateNews {
  /** @minLength 1 */
  title: string;
  preamble?: string | null;
  content?: string | null;
  authorId?: string | null;
  /** @format date-time */
  startPublish?: string | null;
  /** @format date-time */
  stopPublish?: string | null;
  /** @format date-time */
  stopFeatured?: string | null;
  channels?: string[] | null;
}

export interface CreateOrUpdateNotificationTemplate {
  mode?: NotificationMode;
  applicationId?: string | null;
  contentType?: NotificationContentType;
  content?: string | null;
  type?: string | null;
  subject?: string | null;
}

export interface CreateOrUpdateOrderCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
}

export interface CreateOrUpdateOrganizationWebRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  tagIds?: string[] | null;
  parentOrganizationId?: string | null;
  organizationNumber?: string | null;
  isCustomer?: boolean;
  isSupplier?: boolean;
  customerCategoryId?: string | null;
  /** @format int32 */
  creditLimit?: number | null;
  invoiceEmail?: string | null;
  supplierCategoryId?: string | null;
}

export interface CreateOrUpdatePersonWebRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  tagIds?: string[] | null;
  isCustomer?: boolean;
  isSupplier?: boolean;
  customerCategoryId?: string | null;
  /** @format int32 */
  creditLimit?: number | null;
  invoiceEmail?: string | null;
  supplierCategoryId?: string | null;
}

export interface CreateOrUpdateProductCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  excludeFromProductDiff?: boolean;
}

export interface CreateOrUpdateResource {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  type?: ResourceType;
  monitored?: boolean;
  ownerId?: string | null;
  categoryId?: string | null;
  supplierId?: string | null;
  tagIds?: string[] | null;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
  /** @format date-time */
  muteUntil?: string | null;
  notificationReceiverIds?: string[] | null;
  contactPersonIds?: string[] | null;
}

export interface CreateOrUpdateResourceCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
}

export interface CreateOrUpdateRole {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  applicationId?: string | null;
  capabilities?: string[] | null;
}

export interface CreateOrUpdateRoutineCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
}

export interface CreateOrUpdateTag {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  overrideColor?: boolean | null;
  /** @minLength 1 */
  categoryId: string;
}

export interface CreateOrUpdateTagCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  limitToTaggableTypes?: TaggableType[] | null;
}

export interface CreateOrUpdateTicket {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  requesterId?: string | null;
  assigneeId?: string | null;
  customerId?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  status?: TicketStatus;
  severity?: TicketSeverity;
  tagIds?: string[] | null;
  otherParticipants?: string[] | null;
}

export interface CreateOrUpdateWorkCategory {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  color?: string | null;
  productId?: string | null;
  limitToActivityTypes?: ActivityType[] | null;
  informationText?: string | null;
}

export interface CreateOrder {
  type?: OrderType;
  label?: string | null;
  customerId?: string | null;
  dates?: {
    /** @format date-time */
    Quotation?: string;
    /** @format date-time */
    QuotationExpiry?: string;
    /** @format date-time */
    QuotationLost?: string;
    /** @format date-time */
    Sales?: string;
    /** @format date-time */
    SalesCorrection?: string;
    /** @format date-time */
    SalesProduction?: string;
    /** @format date-time */
    SalesCompleted?: string;
    /** @format date-time */
    RequestedDelivery?: string;
    /** @format date-time */
    Delivered?: string;
  } | null;
  deliveryAddress?: Address;
  invoiceAddress?: Address;
  projectId?: string | null;
  categoryId?: string | null;
  invoiceReceiverId?: string | null;
  externalContactId?: string | null;
  internalContactId?: string | null;
  tagIds?: string[] | null;
  reference?: string | null;
  orderlines?: CreateOrderline[] | null;
}

export interface CreateOrderline {
  /** @minLength 1 */
  productId: string;
  /** @format double */
  quantity: number;
  unit?: ProductUnit;
  reference?: string | null;
  description?: string | null;
  salesPrice?: DecimalOrderlineStatus;
  costPrice?: DecimalOrderlineStatus;
  discount?: DecimalOrderlineStatus;
  parts?: ProductPart[] | null;
}

export interface CreatePdfWebRequest {
  htmlContent?: string | null;
  cssContent?: string | null;
}

export interface CreatePoll {
  question?: string | null;
  alternatives?: string[] | null;
  multipleChoice?: boolean;
  isActive?: boolean;
}

export interface CreateProduct {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  categoryId?: string | null;
  defaultUnit?: ProductUnit;
  tagIds?: string[] | null;
  /** @minLength 1 */
  code: string;
  type: ProductType;
  parts?: ProductPart[] | null;
}

export interface CreateProject {
  projectLeaderId?: string | null;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  customerId?: string | null;
  orderCategoryId?: string | null;
  invoiceRecipientId?: string | null;
  contactPersonIds?: string[] | null;
  type: ProjectType;
  /** @format int64 */
  estimate?: number | null;
  /** @format int32 */
  price?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  /** @format date-time */
  actualStart?: string | null;
  /** @format date-time */
  actualEnd?: string | null;
  tagIds?: string[] | null;
  active?: boolean;
  managerId?: string | null;
}

export interface CreateProjectActivityWebRequest {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  /** @minLength 1 */
  actorId: string;
  groupId?: string | null;
  meetingId?: string | null;
  links: ProjectActivityTypeLinks;
  callId?: string | null;
  copyNoteToCall?: boolean | null;
}

export interface CreateReport {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  reportPeriod?: ReportPeriod;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
  modules?: string | null;
}

export interface CreateRoutine {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  severity?: RoutineSeverityType;
  frequency?: RoutineFrequency;
  /** @minLength 1 */
  ownerId: string;
  responsibleIds?: string[] | null;
  allEmployees?: boolean;
  organizationId?: string | null;
  resourceId?: string | null;
  /** @minLength 1 */
  categoryId: string;
  checklist?: string[] | null;
  tagIds?: string[] | null;
}

export interface CreateSalaryRequest {
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  requireCalculation?: boolean;
}

export interface CreateSensor {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  resourceId?: string | null;
  /** @minLength 1 */
  deviceId: string;
  applicationId?: string | null;
}

export interface CreateSubscription {
  /** @minLength 1 */
  customerId: string;
  invoiceReceiverId?: string | null;
  description?: string | null;
  tagIds?: string[] | null;
  changeType?: UpdateSubscriptionType;
  type: SubscriptionType;
}

export interface CreateSubscriptionLine {
  /** @format date-time */
  application: string;
  /** @format double */
  quantity: number;
  /** @format double */
  discount?: number;
  note?: string | null;
  internalNote?: string | null;
  id?: string | null;
  /** @minLength 1 */
  productId: string;
}

export interface CreateTask {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  ownerId?: string | null;
  assigneeId?: string | null;
  requesterId?: string | null;
  categoryId?: string | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  tagIds?: string[] | null;
  type?: TaskType;
  /** @minLength 1 */
  projectId: string;
  parentId?: string | null;
}

export interface CustomerActivityTypeLinks {
  ticketId?: string | null;
  type?: ActivityType;
  /** @minLength 1 */
  customerId: string;
  /** @minLength 1 */
  categoryId: string;
  productId?: string | null;
}

export enum CustomerCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface CustomerCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  numberSeries?: NumberSeriesView[] | null;
}

export interface CustomerCategoryViewEntityViewModel {
  view?: CustomerCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface CustomerCategoryViewPagedCustomerCategoryQueryCollectionViewModel {
  items?: CustomerCategoryView[] | null;
  query?: PagedCustomerCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface CustomerInfoView {
  email?: string | null;
  /** @format double */
  creditLimit?: number | null;
  category?: CategoryLinkView;
}

export interface CustomerNumberView {
  applicationId?: string | null;
  /** @format int32 */
  customerNumber?: number;
}

export interface DashboardCollectionViewModel {
  items?: DashboardView[] | null;
  query?: PagedDashboardQuery;
  /** @format int64 */
  count?: number;
  standard?: DashboardView;
}

export enum DashboardSortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface DashboardView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  modules?: string | null;
  availableAll?: boolean;
  hasBackground?: boolean;
  roles?: RoleLinkView[] | null;
  people?: PersonLinkView[] | null;
}

export interface DashboardViewModel {
  view?: DashboardView;
  links?: Record<string, EntityLinkValueView>;
  standard?: boolean;
}

export interface DecimalOrderlineStatus {
  status?: OrderlineStatus;
  /** @format double */
  value?: number;
}

export interface DeclineAbsence {
  note?: string | null;
  updateManyRequests?: UpdateManyRequest[] | null;
}

export interface EmployeeLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
  /** @format int32 */
  employeeNumber?: number | null;
}

export enum EmployeeSalaryType {
  Hourly = "Hourly",
  Fixed = "Fixed",
}

export enum EmployeeSalaryTypeFormat {
  Hour = "Hour",
  Piece = "Piece",
}

export interface EmployeeSalaryView {
  person?: PersonLinkView;
  currentConfirmed?: PerRecieverView[] | null;
  currentUnconfirmed?: PerRecieverView[] | null;
  diff?: PerRecieverView[] | null;
  transferred?: PerRecieverView[] | null;
  /** @format double */
  percentage?: number | null;
  /** @format int32 */
  employeeNumber?: number | null;
  banksSalaryTypes?: SalaryType[] | null;
  employeeSalaryTypes?: EmployeeSalaryType[] | null;
}

export interface EmployeeSalaryViewViewModel {
  view?: EmployeeSalaryView;
}

export enum EmployeeSortOption {
  Name = "Name",
  EmployeeNumber = "EmployeeNumber",
}

export interface EmployeeView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  actorType?: ActorType;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
  /** @format int32 */
  employeeNumber?: number | null;
  /** @format date-time */
  birthday?: string | null;
  relative?: string | null;
  hasAvatar?: boolean;
  relativeContactInfo?: string | null;
  jobTitle?: string | null;
  /** @format double */
  percentage?: number | null;
  salaryTypes?: EmployeeSalaryType[] | null;
  organizationRoles?: OrganizationRoleView[] | null;
  timebank?: Record<string, number>;
  vacation?: Record<string, VacationRecord>;
  illnessChild?: Record<string, number>;
  hourBank?: Record<string, number>;
}

export interface EmployeeViewEntityViewModel {
  view?: EmployeeView;
  links?: Record<string, EntityLinkValueView>;
}

export interface EmployeeViewPagedEmployeeQueryCollectionViewModel {
  items?: EmployeeView[] | null;
  query?: PagedEmployeeQuery;
  /** @format int64 */
  count?: number;
}

export interface EntityLinkValueView {
  type?: string | null;
  name?: string | null;
  url?: string | null;
}

export interface EntityLinkView {
  id?: string | null;
  deleted?: boolean;
}

export interface ExecuteExecution {
  checklist?: RoutineExecutionChecklistItem[] | null;
  completion?: CompleteExecution;
}

export interface ExtendedOrderLinkView {
  id?: string | null;
  deleted?: boolean;
  reference?: string | null;
  orderNumbers?: OrderNumberView[] | null;
}

export interface ExternalLinkEntity {
  id?: string | null;
  name?: string | null;
  deleted?: boolean;
}

export interface ExternalLinkEntityPagedExternalLinkQueryCollectionViewModel {
  items?: ExternalLinkEntity[] | null;
  query?: PagedExternalLinkQuery;
  /** @format int64 */
  count?: number;
}

export interface ExternalLinkGroupView {
  id?: string | null;
  name?: string | null;
  isAdvanced?: boolean;
}

export enum ExternalLinkSortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface ExternalLinkView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  url?: string | null;
  group?: ExternalLinkGroupView;
  variables?: Record<string, string | null>;
  entities?: ExternalLinkEntity[] | null;
}

export interface ExternalLinkViewEntityViewModel {
  view?: ExternalLinkView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ExternalLinkViewPagedExternalLinkQueryCollectionViewModel {
  items?: ExternalLinkView[] | null;
  query?: PagedExternalLinkQuery;
  /** @format int64 */
  count?: number;
}

export interface FeedAuthorView {
  id?: string | null;
  name?: string | null;
}

export enum FeedItemSourceType {
  Internal = "Internal",
  External = "External",
}

export interface FeedItemSourceView {
  id?: string | null;
  name?: string | null;
  type?: FeedItemSourceType;
}

export interface FeedItemView {
  id?: string | null;
  title?: string | null;
  content?: string | null;
  imageUrl?: string | null;
  redirectUrl?: string | null;
  /** @format date-time */
  published?: string;
  source?: FeedItemSourceView;
  author?: FeedAuthorView;
}

export interface FeedLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  feedId?: string | null;
}

export enum FeedSortOption {
  Name = "Name",
  Url = "Url",
  LastModified = "LastModified",
}

export interface FeedView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  url?: string | null;
}

export interface FeedViewEntityViewModel {
  view?: FeedView;
  links?: Record<string, EntityLinkValueView>;
}

export interface FeedViewPagedFeedQueryCollectionViewModel {
  items?: FeedView[] | null;
  query?: PagedFeedQuery;
  /** @format int64 */
  count?: number;
}

export interface FileLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  description?: string | null;
  applications?: ApplicationLinkView[] | null;
  attachmentName?: string | null;
  extension?: string | null;
  /** @format int64 */
  size?: number;
  versions?: FileVersionView[] | null;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
}

export enum FileSortOption {
  Name = "Name",
  Extension = "Extension",
  Size = "Size",
  LastModified = "LastModified",
}

export interface FileVersionView {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  attachmentName?: string | null;
  extension?: string | null;
  /** @format int64 */
  size?: number;
  /** @format date-time */
  created?: string;
}

export interface FileView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  applications?: ApplicationLinkView[] | null;
  attachmentName?: string | null;
  extension?: string | null;
  /** @format int64 */
  size?: number;
  versions?: FileVersionView[] | null;
  path?: FolderLinkView[] | null;
}

export interface FileViewEntityViewModel {
  view?: FileView;
  links?: Record<string, EntityLinkValueView>;
}

export interface FileViewPagedFileQueryCollectionViewModel {
  items?: FileView[] | null;
  query?: PagedFileQuery;
  /** @format int64 */
  count?: number;
}

export interface FolderLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  description?: string | null;
  applications?: ApplicationLinkView[] | null;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
}

export enum FolderSortOption {
  Name = "Name",
}

export interface FolderView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  reference?: ParentRef;
  name?: string | null;
  description?: string | null;
  path?: FolderLinkView[] | null;
  subFolders?: FolderLinkView[] | null;
  files?: FileLinkView[] | null;
  inheritedApplications?: ApplicationLinkView[] | null;
  applications?: ApplicationLinkView[] | null;
}

export interface FolderViewEntityViewModel {
  view?: FolderView;
  links?: Record<string, EntityLinkValueView>;
}

export interface FolderViewPagedFolderQueryCollectionViewModel {
  items?: FolderView[] | null;
  query?: PagedFolderQuery;
  /** @format int64 */
  count?: number;
}

export interface Handler {
  type: HandlerType;
  description?: string | null;
}

export enum HandlerType {
  Primary = "Primary",
  Secondary = "Secondary",
  Consultant = "Consultant",
}

export interface HandlerView {
  employee?: PersonLinkView;
  type?: HandlerType;
  description?: string | null;
}

export interface HourBankView {
  employee?: EmployeeLinkView;
  /** @format double */
  startingBalance?: number | null;
  /** @format double */
  toNextYear?: number | null;
  employeeId?: string | null;
}

export interface HourCategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
  type?: HourCategoryType;
  product?: ProductLinkView;
  informationText?: string | null;
  requiresAdminApproval?: boolean;
}

export enum HourCategoryType {
  Work = "Work",
  Absence = "Absence",
}

export interface HourCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  type?: HourCategoryType;
  product?: ProductLinkView;
  limitedToActivityTypes?: ActivityType[] | null;
  informationText?: string | null;
  requiresAdminApproval?: boolean;
}

export interface HourCategoryViewEntityViewModel {
  view?: HourCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface HourCategoryViewPagedHourCategoryQueryCollectionViewModel {
  items?: HourCategoryView[] | null;
  query?: PagedHourCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface HourbankEntryView {
  type?: TimebankTransactionType;
  /** @format date-span */
  duration?: string;
  transactionReference?: string | null;
}

export interface IllnessChildView {
  employee?: EmployeeLinkView;
  employeeId?: string | null;
  /** @format double */
  illnessChild?: number | null;
}

export interface ImageOfTheDayWebView {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  contentType?: string | null;
  b64Content?: string | null;
  extension?: string | null;
}

export enum ImageSize {
  Small = "Small",
  Medium = "Medium",
}

export interface IncidentAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  routineId?: string | null;
  limitToCategories?: string[] | null;
  limitToCustomers?: string[] | null;
  limitToResources?: string[] | null;
  limitToParticipants?: string[] | null;
  limitToProducts?: string[] | null;
  limitToTags?: string[] | null;
  limitToCauses?: string[] | null;
  dateTypes?: IncidentDateType[] | null;
  registratorId?: string | null;
  closerId?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  /** @format date-time */
  occuredStart?: string | null;
  /** @format date-time */
  occuredEnd?: string | null;
  closed?: boolean | null;
  myIncidents?: string | null;
  incidentId?: string | null;
  aggs?: IncidentAggregationTypes[] | null;
  /** @format int32 */
  openCount?: number | null;
  /** @format int32 */
  overDueCount?: number | null;
  /** @format int32 */
  closedCount?: number | null;
}

export interface IncidentAggregationResult {
  perCategory?: Record<string, Aggregate>;
  perCause?: Record<string, Aggregate>;
  perOwner?: Record<string, Aggregate>;
  perTag?: Record<string, Aggregate>;
  openCount?: AggregateSingleResult;
  overdueCount?: AggregateSingleResult;
  closedCount?: AggregateSingleResult;
}

export interface IncidentAggregationResultIncidentAggregateQueryAggregationResults {
  results?: IncidentAggregationResult;
  query?: IncidentAggregateQuery;
}

export enum IncidentAggregationTypes {
  PerCategory = "PerCategory",
  PerCause = "PerCause",
  PerOwner = "PerOwner",
  PerTag = "PerTag",
  OpenCount = "OpenCount",
  OverdueCount = "OverdueCount",
  ClosedCount = "ClosedCount",
}

export enum IncidentCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface IncidentCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
}

export interface IncidentCategoryViewEntityViewModel {
  view?: IncidentCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface IncidentCategoryViewPagedIncidentCategoryQueryCollectionViewModel {
  items?: IncidentCategoryView[] | null;
  query?: PagedIncidentCategoryQuery;
  /** @format int64 */
  count?: number;
}

export enum IncidentDateType {
  Registered = "Registered",
  Occured = "Occured",
  ExpectedClose = "ExpectedClose",
  Closed = "Closed",
}

export enum IncidentSortOption {
  Title = "Title",
  Registrated = "Registrated",
  Occured = "Occured",
  ExpectedClose = "ExpectedClose",
  Closed = "Closed",
  FinancialCost = "FinancialCost",
  Type = "Type",
  Owner = "Owner",
  LastModified = "LastModified",
}

export interface IncidentView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  category?: CategoryLinkView;
  dates?: {
    /** @format date-time */
    Registered?: string;
    /** @format date-time */
    Occured?: string;
    /** @format date-time */
    ExpectedClose?: string;
    /** @format date-time */
    Closed?: string;
  } | null;
  correctiveActions?: string | null;
  closingComment?: string | null;
  closer?: PersonLinkView;
  registrator?: PersonLinkView;
  owner?: PersonLinkView;
  routine?: RoutineLinkView;
  /** @format double */
  financialCost?: number | null;
  costComment?: string | null;
  customers?: ActorLinkView[] | null;
  resources?: ResourceLinkView[] | null;
  otherParticipants?: PersonLinkView[] | null;
  products?: ProductLinkView[] | null;
  tags?: TagLinkView[] | null;
}

export interface IncidentViewEntityViewModel {
  view?: IncidentView;
  links?: Record<string, EntityLinkValueView>;
}

export interface IncidentViewPagedIncidentQueryCollectionViewModel {
  items?: IncidentView[] | null;
  query?: PagedIncidentQuery;
  /** @format int64 */
  count?: number;
}

export interface InternalActivityTypeLinks {
  ticketId?: string | null;
  type?: ActivityType;
  /** @minLength 1 */
  categoryId: string;
}

export enum InvoiceLineAggregationTypes {
  CompareRevenue = "CompareRevenue",
  CompareCustomers = "CompareCustomers",
  CompareProducts = "CompareProducts",
  CustomerNetAmount = "CustomerNetAmount",
  NetAmountPerHourGroupPerMonth = "NetAmountPerHourGroupPerMonth",
  InvoicelinesNetInvoicedPerMonth = "InvoicelinesNetInvoicedPerMonth",
  NetAmountPerOtherGroupPerMonth = "NetAmountPerOtherGroupPerMonth",
  NetAmountPerPeriodGroupMonth = "NetAmountPerPeriodGroupMonth",
  ProductNetAmount = "ProductNetAmount",
  ProjectNetAmount = "ProjectNetAmount",
  TotalNetAmount = "TotalNetAmount",
  HourNetAmount = "HourNetAmount",
  OtherNetAmount = "OtherNetAmount",
  PeriodNetAmount = "PeriodNetAmount",
}

export interface InvoiceLinkView {
  id?: string | null;
  deleted?: boolean;
  invoiceNo?: string | null;
}

export enum InvoiceSortOption {
  InvoiceNo = "InvoiceNo",
  Label = "Label",
  DueDate = "DueDate",
  Issued = "Issued",
  NetAmount = "NetAmount",
  GrossAmount = "GrossAmount",
  Customer = "Customer",
  Project = "Project",
  Order = "Order",
  LastModified = "LastModified",
}

export interface InvoiceView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  invoiceNo?: string | null;
  label?: string | null;
  customer?: ActorLinkView;
  invoiceReceiver?: ActorLinkView;
  order?: ExtendedOrderLinkView;
  project?: ProjectLinkView;
  /** @format date-time */
  issued?: string;
  /** @format date-time */
  dueDate?: string | null;
  /** @format double */
  netSum?: number;
  /** @format double */
  grossSum?: number;
  document?: AttachmentView;
  invoicelines?: InvoicelinePartialView[] | null;
}

export interface InvoiceViewEntityViewModel {
  view?: InvoiceView;
  links?: Record<string, EntityLinkValueView>;
}

export interface InvoiceViewPagedInvoiceQueryCollectionViewModel {
  items?: InvoiceView[] | null;
  query?: PagedInvoiceQuery;
  /** @format int64 */
  count?: number;
}

export interface InvoicelineAggregationResult {
  compareRevenue?: Record<string, Record<string, AggregateSingleResult>>;
  compareCustomers?: Record<string, Record<string, Aggregate>>;
  compareProducts?: Record<string, Record<string, Aggregate>>;
  customerNetAmount?: Record<string, Aggregate>;
  netAmountPerHourGroupPerMonth?: Record<string, SingleDrilldown>;
  invoicelinesNetInvoicedPerMonth?: Record<string, SingleDrilldown>;
  netAmountPerOtherGroupPerMonth?: Record<string, SingleDrilldown>;
  netAmountPerPeriodGroupMonth?: Record<string, SingleDrilldown>;
  productNetAmount?: Record<string, Aggregate>;
  projectNetAmount?: Record<string, Aggregate>;
  totalNetAmount?: AggregateSingleResult;
  periodNetAmount?: AggregateSingleResult;
  hourNetAmount?: AggregateSingleResult;
  otherNetAmount?: AggregateSingleResult;
}

export interface InvoicelineAggregationResultInvoicelineLineAggregateQueryAggregationResults {
  results?: InvoicelineAggregationResult;
  query?: InvoicelineLineAggregateQuery;
}

export interface InvoicelineLineAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  invoiceNo?: string | null;
  invoiceId?: string | null;
  /** @format double */
  minAmount?: number | null;
  /** @format double */
  maxAmount?: number | null;
  customerId?: string | null;
  projectId?: string | null;
  productId?: string | null;
  productCategoryIds?: string[] | null;
  revenueTypes?: RevenueType[] | null;
  /** @format date-time */
  issuedAfter?: string | null;
  /** @format date-time */
  issuedBefore?: string | null;
  deletedInvoice?: boolean | null;
  aggs?: InvoiceLineAggregationTypes[] | null;
  /** @format int32 */
  compareRevenue?: number | null;
  /** @format int32 */
  compareCustomers?: number | null;
  /** @format int32 */
  compareProducts?: number | null;
}

export interface InvoicelinePartialView {
  id?: string | null;
  deleted?: boolean;
  unit?: ProductUnit;
  reference?: string | null;
  /** @format double */
  salesPrice?: number;
  /** @format double */
  costPrice?: number | null;
  /** @format double */
  discount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  vat?: number;
  orderline?: OrderlineLinkView;
  description?: string | null;
  product?: ProductLinkView;
  attributes?: Record<string, string | null>;
}

export enum InvoicelineSortOption {
  LastModified = "LastModified",
}

export interface InvoicelineView {
  id?: string | null;
  deleted?: boolean;
  unit?: ProductUnit;
  reference?: string | null;
  /** @format double */
  salesPrice?: number;
  /** @format double */
  costPrice?: number | null;
  /** @format double */
  discount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  vat?: number;
  orderline?: OrderlineLinkView;
  description?: string | null;
  product?: ProductLinkView;
  attributes?: Record<string, string | null>;
  invoice?: InvoiceLinkView;
}

export interface InvoicelineViewEntityViewModel {
  view?: InvoicelineView;
  links?: Record<string, EntityLinkValueView>;
}

export interface InvoicelineViewPagedInvoicelineQueryCollectionViewModel {
  items?: InvoicelineView[] | null;
  query?: PagedInvoicelineQuery;
  /** @format int64 */
  count?: number;
}

export interface LeadAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  customerId?: string | null;
  contactPersonId?: string | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  /** @format date-time */
  closingStartPeriod?: string | null;
  /** @format date-time */
  closingEndPeriod?: string | null;
  probabilities?: LeadProbability[] | null;
  status?: LeadStatus[] | null;
  limitToCauses?: string[] | null;
  limitToTags?: string[] | null;
  leadId?: string | null;
  aggs?: LeadAggregationTypes[] | null;
}

export interface LeadAggregationResult {
  annualPerMonth?: Record<string, SingleDrilldown>;
  annualPerStatus?: Aggregate[] | null;
  fixedPerMonth?: Record<string, SingleDrilldown>;
  fixedPerStatus?: Aggregate[] | null;
}

export interface LeadAggregationResultLeadAggregateQueryAggregationResults {
  results?: LeadAggregationResult;
  query?: LeadAggregateQuery;
}

export enum LeadAggregationTypes {
  AnnualPerMonth = "AnnualPerMonth",
  AnnualPerStatus = "AnnualPerStatus",
  FixedPerMonth = "FixedPerMonth",
  FixedPerStatus = "FixedPerStatus",
}

export enum LeadProbability {
  VeryLow = "VeryLow",
  Low = "Low",
  Medium = "Medium",
  High = "High",
  VeryHigh = "VeryHigh",
}

export enum LeadSortOption {
  Title = "Title",
  ExpectedClose = "ExpectedClose",
  ClosingDate = "ClosingDate",
  Status = "Status",
  Probability = "Probability",
  LatestNote = "LatestNote",
  FixedPrice = "FixedPrice",
  AnnualPrice = "AnnualPrice",
  LastModified = "LastModified",
}

export enum LeadStatus {
  Lost = "Lost",
  Cancelled = "Cancelled",
  Active = "Active",
  Won = "Won",
  Postponed = "Postponed",
}

export interface LeadView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  owner?: PersonLinkView;
  customer?: ActorLinkView;
  contactPerson?: PersonLinkView;
  /** @format int64 */
  fixedPrice?: number | null;
  /** @format int64 */
  annualPrice?: number | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format date-time */
  expectedClose?: string | null;
  /** @format date-time */
  closingDate?: string | null;
  probability?: LeadProbability;
  status?: LeadStatus;
  latestNote?: NoteLinkView;
  tags?: TagLinkView[] | null;
  causes?: TagLinkView[] | null;
}

export interface LeadViewEntityViewModel {
  view?: LeadView;
  links?: Record<string, EntityLinkValueView>;
}

export interface LeadViewPagedLeadQueryCollectionViewModel {
  items?: LeadView[] | null;
  query?: PagedLeadQuery;
  /** @format int64 */
  count?: number;
}

export enum LoginFlow {
  Service = "Service",
  Secure = "Secure",
  Legacy = "Legacy",
}

export enum LoginSortOption {
  LastModified = "LastModified",
}

export interface LoginWebView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  username?: string | null;
  principalId?: string | null;
  roles?: RoleLinkView[] | null;
  twoFactor?: TwoFactor;
  person?: PersonExtendedLinkView;
}

export interface LoginWebViewEntityViewModel {
  view?: LoginWebView;
  links?: Record<string, EntityLinkValueView>;
}

export interface LoginWebViewPagedLoginQueryCollectionViewModel {
  items?: LoginWebView[] | null;
  query?: PagedLoginQuery;
  /** @format int64 */
  count?: number;
}

export interface MeasurementAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  measuringPointIds?: string[] | null;
  resourceId?: string | null;
  /** @format date-time */
  fromDate?: string | null;
  /** @format date-time */
  toDate?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: MeasurementSortOption;
  sortDirection?: SortDirection;
  typeId?: string | null;
  level?: MeasurementLevel;
  relatedUserId?: string | null;
  sensorId?: string | null;
  aggs?: MeasurementAggregationTypes[] | null;
}

export enum MeasurementAggregationTypes {
  Minute = "Minute",
  FiveMinute = "FiveMinute",
  HalfHour = "HalfHour",
  Hour = "Hour",
  SixHours = "SixHours",
  Day = "Day",
}

export enum MeasurementLevel {
  Normal = "Normal",
  Caution = "Caution",
  Critical = "Critical",
}

export interface MeasurementPointValues {
  measurementPointId?: string | null;
  data?: Aggregate[] | null;
}

export enum MeasurementSortOption {
  Date = "Date",
}

export interface MeasurementThreshold {
  /** @format date-span */
  duration?: string | null;
  /** @format double */
  lowerOrEqual?: number | null;
  /** @format double */
  higherOrEqual?: number | null;
}

export interface MeasurementType {
  id?: string | null;
  eventType?: SensorEventType;
  valueType?: SensorValueType;
}

export interface MeasurementView {
  resource?: ResourceLinkView;
  measuringPoint?: MeasuringPointLinkView;
  /** @format date-time */
  timestamp?: string;
  value?: any;
  level?: MeasurementLevel;
}

export interface MeasurementViewPagedLastMeasurementQueryCollectionViewModel {
  items?: MeasurementView[] | null;
  query?: PagedLastMeasurementQuery;
  /** @format int64 */
  count?: number;
}

export interface MeasurementViewPagedMeasurementQueryCollectionViewModel {
  items?: MeasurementView[] | null;
  query?: PagedMeasurementQuery;
  /** @format int64 */
  count?: number;
}

export interface MeasuringPointLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  type?: MeasurementType;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
}

export enum MeasuringPointSortOption {
  Name = "Name",
  Type = "Type",
  Sensor = "Sensor",
  Resource = "Resource",
  LastModified = "LastModified",
}

export interface MeasuringPointTemplateElementView {
  id?: string | null;
  name?: string | null;
  sensorPartName?: string | null;
  type?: MeasurementType;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
}

export interface MeasuringPointTemplateFields {
  id?: string | null;
  name?: string | null;
  typeId?: string | null;
  sensorPartName?: string | null;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
}

export enum MeasuringPointTemplateSortOption {
  Title = "Title",
  LastModified = "LastModified",
}

export interface MeasuringPointTemplateView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  multipleSensors?: boolean;
  measuringPoints?: MeasuringPointTemplateElementView[] | null;
}

export interface MeasuringPointTemplateViewEntityViewModel {
  view?: MeasuringPointTemplateView;
  links?: Record<string, EntityLinkValueView>;
}

export interface MeasuringPointTemplateViewPagedMeasuringPointTemplateQueryCollectionViewModel {
  items?: MeasuringPointTemplateView[] | null;
  query?: PagedMeasuringPointTemplateQuery;
  /** @format int64 */
  count?: number;
}

export interface MeasuringPointView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  type?: MeasurementType;
  resource?: ResourceLinkView;
  sensorPart?: SensorPartLinkView;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
}

export interface MeasuringPointViewEntityViewModel {
  view?: MeasuringPointView;
  links?: Record<string, EntityLinkValueView>;
}

export interface MeasuringPointViewPagedMeasuringPointQueryCollectionViewModel {
  items?: MeasuringPointView[] | null;
  query?: PagedMeasuringPointQuery;
  /** @format int64 */
  count?: number;
}

export interface MeetingAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  type?: MeetingType;
  organizerContactPointId?: string | null;
  organizerId?: string | null;
  participantIds?: string[] | null;
  displayForActorId?: string | null;
  /** @format date-time */
  startAfter?: string | null;
  /** @format date-time */
  endBefore?: string | null;
  /** @format date-time */
  activeAt?: string | null;
  isUpcoming?: boolean | null;
  organizationId?: string | null;
  meetingId?: string | null;
  aggs?: MeetingAggregationTypes[] | null;
}

export interface MeetingAggregationResult {
  meetingTotalDuration?: AggregateSingleResult;
  meetingsUpcoming?: AggregateSingleResult;
  meetingInProgress?: AggregateSingleResult;
  meetingsPast?: AggregateSingleResult;
}

export interface MeetingAggregationResultMeetingAggregateQueryAggregationResults {
  results?: MeetingAggregationResult;
  query?: MeetingAggregateQuery;
}

export enum MeetingAggregationTypes {
  MeetingTotalDuration = "MeetingTotalDuration",
  MeetingsUpcoming = "MeetingsUpcoming",
  MeetingInProgress = "MeetingInProgress",
  MeetingsPast = "MeetingsPast",
}

export interface MeetingLocation {
  type?: MeetingLocationType;
  value?: string | null;
}

export enum MeetingLocationType {
  Unknown = "Unknown",
  Online = "Online",
}

export enum MeetingSortOption {
  Name = "Name",
  Start = "Start",
  End = "End",
  LastModified = "LastModified",
}

export enum MeetingType {
  PendingActivity = "PendingActivity",
  Meeting = "Meeting",
}

export interface MeetingView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  type?: MeetingType;
  organizer?: ParticipantInfoView;
  allDay?: boolean;
  location?: MeetingLocation;
  participants?: ParticipantInfoView[] | null;
}

export interface MeetingViewEntityViewModel {
  view?: MeetingView;
  links?: Record<string, EntityLinkValueView>;
}

export interface MeetingViewPagedMeetingQueryCollectionViewModel {
  items?: MeetingView[] | null;
  query?: PagedMeetingQuery;
  /** @format int64 */
  count?: number;
}

export interface MoveEntityFileOrFolder {
  parent?: ParentRef;
  entityId?: string | null;
}

export interface MoveFileOrFolder {
  parent?: ParentRef;
}

export interface MoveTask {
  /** @minLength 1 */
  projectId: string;
  parentId?: string | null;
}

export interface NewsFeedCollectionViewModel {
  items?: FeedItemView[] | null;
  /** @format int32 */
  count?: number;
  query?: NewsFeedQuery;
  selectedFeeds?: FeedLinkView[] | null;
}

export interface NewsFeedQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  feedIds?: string[] | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface NewsInternalFeedCollectionViewModel {
  items?: FeedItemView[] | null;
  /** @format int32 */
  count?: number;
  query?: NewsFeedQuery;
}

export enum NewsSortOption {
  Title = "Title",
  Author = "Author",
  LastModified = "LastModified",
  StartPublish = "StartPublish",
  StopPublish = "StopPublish",
  StopFeatured = "StopFeatured",
}

export interface NewsView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  preamble?: string | null;
  content?: string | null;
  image?: AttachmentView;
  /** @format date-time */
  startPublish?: string | null;
  /** @format date-time */
  stopPublish?: string | null;
  /** @format date-time */
  stopFeatured?: string | null;
  author?: PersonLinkView;
  /** @format int32 */
  commentCount?: number;
  channels?: ChannelLinkView[] | null;
  reactions?: ReactionView[] | null;
  /** @format int32 */
  viewers?: number;
}

export interface NewsViewEntityViewModel {
  view?: NewsView;
  links?: Record<string, EntityLinkValueView>;
}

export interface NewsViewPagedNewsQueryCollectionViewModel {
  items?: NewsView[] | null;
  query?: PagedNewsQuery;
  /** @format int64 */
  count?: number;
}

export interface NoteLinkView {
  id?: string | null;
  deleted?: boolean;
  author?: ActorLinkView;
  /** @format date-time */
  timestamp?: string;
  text?: string | null;
}

export interface NoteView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  text?: string | null;
  entity?: EntityLinkView;
  sticky?: boolean;
  private?: boolean;
  /** @format date-time */
  timestamp?: string;
  author?: PersonLinkView;
}

export interface NoteViewEntityViewModel {
  view?: NoteView;
  links?: Record<string, EntityLinkValueView>;
}

export interface NoteViewPagedNoteQueryCollectionViewModel {
  items?: NoteView[] | null;
  query?: PagedNoteQuery;
  /** @format int64 */
  count?: number;
}

export enum NotificationContentType {
  Text = "Text",
  Html = "Html",
}

export enum NotificationDefinedType {
  InternalNews = "InternalNews",
  PeopleNotes = "PeopleNotes",
  NewsComments = "NewsComments",
  SensorAlerts = "SensorAlerts",
  OrganizationNotes = "OrganizationNotes",
  ProjectNotes = "ProjectNotes",
  TicketComments = "TicketComments",
  MeasuringPointNotes = "MeasuringPointNotes",
  RoutineComments = "RoutineComments",
  ProductNotes = "ProductNotes",
  LeadsNotes = "LeadsNotes",
  SubscriptionNotes = "SubscriptionNotes",
  OrderNotes = "OrderNotes",
  IncidentComments = "IncidentComments",
  CallNotes = "CallNotes",
  ProjectTaskComments = "ProjectTaskComments",
  ResourceNotes = "ResourceNotes",
  CommentReplies = "CommentReplies",
  AssignedToTicket = "AssignedToTicket",
  AssignedOwnerOfRoutine = "AssignedOwnerOfRoutine",
  AssignedOwnerIncident = "AssignedOwnerIncident",
  AssignedTaskOwner = "AssignedTaskOwner",
  AssignedProjectParticipant = "AssignedProjectParticipant",
  AssignedResponsibleRoutine = "AssignedResponsibleRoutine",
  AssignedTaskParticpant = "AssignedTaskParticpant",
  AssignedIncidentParticipant = "AssignedIncidentParticipant",
  AssignedLeadOwner = "AssignedLeadOwner",
  TimerTicketDueDate = "TimerTicketDueDate",
  TimerIncidentExpectedClosingDate = "TimerIncidentExpectedClosingDate",
  TimeProjectPlannedEnd = "TimeProjectPlannedEnd",
  TimeRoutineExecutionDate = "TimeRoutineExecutionDate",
  TimeProjectTaskPlannedEnd = "TimeProjectTaskPlannedEnd",
  TimeLeadNextActionDate = "TimeLeadNextActionDate",
  AdminNotification = "AdminNotification",
  DeclineAbsence = "DeclineAbsence",
}

export enum NotificationMode {
  Internal = "Internal",
  SMS = "SMS",
  Email = "Email",
}

export interface NotificationQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  userId?: string | null;
  mode?: NotificationMode;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface NotificationSettingFormatted {
  uiDescription?: string | null;
  id?: NotificationDefinedType;
  selected?: boolean;
  requiredCapability?: string | null;
}

export interface NotificationSettingsGroup {
  groupName?: string | null;
  settings?: NotificationSettingFormatted[] | null;
}

export interface NotificationSettingsView {
  id?: string | null;
  principalId?: string | null;
  applicationId?: string | null;
  type?: string | null;
  mode?: NotificationMode;
  typeId?: NotificationDefinedType;
  target?: string | null;
}

export enum NotificationStatus {
  Pending = "Pending",
  Dispatched = "Dispatched",
  Error = "Error",
  Read = "Read",
  Ignored = "Ignored",
}

export interface NotificationTemplateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  type?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface NotificationTemplateView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  subject?: string | null;
  contentType?: NotificationContentType;
  content?: string | null;
  mode?: NotificationMode;
  type?: string | null;
  availableSubstitutions?: string[] | null;
}

export interface NotificationTemplateViewNotificationTemplateQueryCollectionViewModel {
  items?: NotificationTemplateView[] | null;
  query?: NotificationTemplateQuery;
  /** @format int64 */
  count?: number;
}

export interface NotificationView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  toUserId?: string | null;
  type?: string | null;
  header?: string | null;
  content?: string | null;
  notificationType?: NotificationDefinedType;
  status?: NotificationStatus;
  entity?: EntityLinkView;
}

export interface NotificationViewNotificationQueryReadCounterCollectionViewModel {
  items?: NotificationView[] | null;
  query?: NotificationQuery;
  counter?: ReadCounter;
}

export interface NumberSeriesView {
  /** @format int32 */
  from: number;
  /** @format int32 */
  to: number;
  applicationId?: string | null;
}

export interface OrderAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: OrderType[] | null;
  actorId?: string | null;
  applicationId?: string | null;
  projectId?: string | null;
  categoryId?: string | null;
  subscriptionId?: string | null;
  subscriptionTransferId?: string | null;
  activityTransferId?: string | null;
  tagIds?: string[] | null;
  reference?: string | null;
  dateTypes?: OrderDateType[] | null;
  /** @format date-time */
  dateFrom?: string | null;
  /** @format date-time */
  dateTo?: string | null;
  completed?: boolean | null;
  orderId?: string | null;
  aggs?: OrderAggregationTypes[] | null;
}

export interface OrderAggregationResult {
  categoryPrice?: Record<string, Aggregate>;
  projectPrice?: Record<string, Aggregate>;
  salesPricePerPeriodMonth?: Record<string, SingleDrilldown>;
  quotationPerPeriodMonth?: Record<string, SingleDrilldown>;
  typePrice?: Aggregate[] | null;
}

export interface OrderAggregationResultOrderAggregateQueryAggregationResults {
  results?: OrderAggregationResult;
  query?: OrderAggregateQuery;
}

export enum OrderAggregationTypes {
  CategoryPrice = "CategoryPrice",
  ProjectPrice = "ProjectPrice",
  SalesPricePerPeriodMonth = "SalesPricePerPeriodMonth",
  QuotationPerPeriodMonth = "QuotationPerPeriodMonth",
  TypePrice = "TypePrice",
}

export interface OrderCategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
}

export enum OrderCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface OrderCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
}

export interface OrderCategoryViewEntityViewModel {
  view?: OrderCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface OrderCategoryViewPagedOrderCategoryQueryCollectionViewModel {
  items?: OrderCategoryView[] | null;
  query?: PagedOrderCategoryQuery;
  /** @format int64 */
  count?: number;
}

export enum OrderDateType {
  Quotation = "Quotation",
  QuotationExpiry = "QuotationExpiry",
  QuotationLost = "QuotationLost",
  Sales = "Sales",
  SalesCorrection = "SalesCorrection",
  SalesProduction = "SalesProduction",
  SalesCompleted = "SalesCompleted",
  RequestedDelivery = "RequestedDelivery",
  Delivered = "Delivered",
}

export interface OrderLinkView {
  id?: string | null;
  deleted?: boolean;
  reference?: string | null;
}

export interface OrderNumberView {
  applicationLink?: ApplicationLinkView;
  orderNumber?: string | null;
}

export enum OrderSortOption {
  Type = "Type",
  Reference = "Reference",
  Label = "Label",
  OrderNumber = "OrderNumber",
  Role = "Role",
  Project = "Project",
  Category = "Category",
  Customer = "Customer",
  InvoiceReceiver = "InvoiceReceiver",
  InternalContact = "InternalContact",
  ExternalContact = "ExternalContact",
  Completions = "Completions",
  Sum = "Sum",
  LastModified = "LastModified",
}

export enum OrderType {
  Quotation = "Quotation",
  Normal = "Normal",
  Direct = "Direct",
  Production = "Production",
}

export interface OrderValues {
  /** @format double */
  quantity?: number;
  /** @format double */
  completed?: number;
  /** @format double */
  completionRate?: number;
  /** @format double */
  salesPrice?: number;
  /** @format double */
  costPrice?: number;
  /** @format double */
  discountedPrice?: number;
  /** @format double */
  discountAmount?: number;
  /** @format double */
  discountPercentage?: number;
  /** @format double */
  coverageAmount?: number;
  /** @format double */
  coveragePercentage?: number;
}

export interface OrderView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  type?: OrderType;
  reference?: string | null;
  label?: string | null;
  customer?: ActorLinkView;
  invoiceReceiver?: ActorLinkView;
  externalContact?: ActorLinkView;
  internalContact?: ActorLinkView;
  dates?: {
    /** @format date-time */
    Quotation?: string;
    /** @format date-time */
    QuotationExpiry?: string;
    /** @format date-time */
    QuotationLost?: string;
    /** @format date-time */
    Sales?: string;
    /** @format date-time */
    SalesCorrection?: string;
    /** @format date-time */
    SalesProduction?: string;
    /** @format date-time */
    SalesCompleted?: string;
    /** @format date-time */
    RequestedDelivery?: string;
    /** @format date-time */
    Delivered?: string;
  } | null;
  deliveryAddress?: Address;
  invoiceAddress?: Address;
  project?: ProjectLinkView;
  category?: OrderCategoryLinkView;
  tags?: TagLinkView[] | null;
  orderNumbers?: OrderNumberView[] | null;
  values?: OrderValues;
  orderlines?: OrderlinePartialView[] | null;
}

export interface OrderViewEntityViewModel {
  view?: OrderView;
  links?: Record<string, EntityLinkValueView>;
}

export interface OrderViewPagedOrderQueryCollectionViewModel {
  items?: OrderView[] | null;
  query?: PagedOrderQuery;
  /** @format int64 */
  count?: number;
}

export interface OrderlineLinkView {
  id?: string | null;
  deleted?: boolean;
}

export interface OrderlinePartialView {
  id?: string | null;
  deleted?: boolean;
  product?: ProductLinkView;
  parts?: ProductPartView[] | null;
  completions?: CompletionPartialView[] | null;
  reference?: string | null;
  description?: string | null;
  unit?: ProductUnit;
  salesPrice?: DecimalOrderlineStatus;
  costPrice?: DecimalOrderlineStatus;
  discount?: DecimalOrderlineStatus;
  /** @format double */
  quantity?: number;
  attributes?: Record<string, string | null>;
}

export enum OrderlineReportGroupBy {
  Customer = "Customer",
  Product = "Product",
}

export enum OrderlineReportSortOption {
  Code = "Code",
  ProductName = "ProductName",
  CustomerName = "CustomerName",
  Quantity = "Quantity",
  Cost = "Cost",
  Discount = "Discount",
  Sales = "Sales",
  Result = "Result",
}

export enum OrderlineReportTransferType {
  Yearly = "Yearly",
  Quarterly = "Quarterly",
  Monthly = "Monthly",
  Hours = "Hours",
}

export enum OrderlineReportTypeOfTransfer {
  Subscriptions = "Subscriptions",
  Hours = "Hours",
}

export interface OrderlineReportView {
  product?: ProductPartialView;
  customer?: ActorLinkView;
  /** @format double */
  quantity?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format double */
  cost?: number | null;
  /** @format double */
  sales?: number | null;
  /** @format double */
  revenue?: number | null;
  /** @format double */
  result?: number | null;
}

export interface OrderlineReportViewPagedOrderlineReportQueryCollectionViewModel {
  items?: OrderlineReportView[] | null;
  query?: PagedOrderlineReportQuery;
  /** @format int64 */
  count?: number;
}

export enum OrderlineSortOption {
  Reference = "Reference",
  LastModified = "LastModified",
}

export enum OrderlineStatus {
  Pending = "Pending",
  Final = "Final",
}

export interface OrderlineView {
  id?: string | null;
  deleted?: boolean;
  product?: ProductLinkView;
  parts?: ProductPartView[] | null;
  completions?: CompletionPartialView[] | null;
  reference?: string | null;
  description?: string | null;
  unit?: ProductUnit;
  salesPrice?: DecimalOrderlineStatus;
  costPrice?: DecimalOrderlineStatus;
  discount?: DecimalOrderlineStatus;
  /** @format double */
  quantity?: number;
  attributes?: Record<string, string | null>;
  order?: OrderLinkView;
}

export interface OrderlineViewEntityViewModel {
  view?: OrderlineView;
  links?: Record<string, EntityLinkValueView>;
}

export interface OrderlineViewPagedOrderlineQueryCollectionViewModel {
  items?: OrderlineView[] | null;
  query?: PagedOrderlineQuery;
  /** @format int64 */
  count?: number;
}

export enum OrganizationAccessLevel {
  None = "None",
  Limited = "Limited",
  Standard = "Standard",
}

export interface OrganizationAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  hasOrganizationNumber?: boolean | null;
  handlerId?: string | null;
  handlerRoles?: HandlerType[] | null;
  businessRelationTypes?: string[] | null;
  /** @format date-time */
  notCalledSince?: string | null;
  hasActiveSubscription?: boolean | null;
  organizationId?: string | null;
  aggs?: OrganizationAggregationTypes[] | null;
}

export interface OrganizationAggregationResult {
  activeSubscriptionCount?: AggregateSingleResult;
  withRevenueThisYear?: AggregateSingleResult;
  withoutHandlers?: AggregateSingleResult;
  withoutOrgNr?: AggregateSingleResult;
  withoutPeople?: AggregateSingleResult;
  customerCount?: AggregateSingleResult;
  withoutContactInfo?: AggregateSingleResult;
  withoutCalls?: AggregateSingleResult;
  newAndActiveThisYear?: AggregateSingleResult;
  activePerYear?: Record<string, SingleDrilldown>;
  relationTags?: Aggregate[] | null;
  typeCount?: Aggregate[] | null;
}

export interface OrganizationAggregationResultOrganizationAggregateQueryAggregationResults {
  results?: OrganizationAggregationResult;
  query?: OrganizationAggregateQuery;
}

export enum OrganizationAggregationTypes {
  ActiveSubscriptionCount = "ActiveSubscriptionCount",
  WithoutHandlers = "WithoutHandlers",
  WithoutOrgNr = "WithoutOrgNr",
  WithoutPeople = "WithoutPeople",
  WithRevenueThisYear = "WithRevenueThisYear",
  TypeCount = "TypeCount",
  CustomerCount = "CustomerCount",
  WithoutContactInfo = "WithoutContactInfo",
  WithoutCalls = "WithoutCalls",
  NewAndActiveThisYear = "NewAndActiveThisYear",
  ActivePerYear = "ActivePerYear",
  RelationTags = "RelationTags",
}

export interface OrganizationLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
}

export interface OrganizationRole {
  accessLevel?: OrganizationAccessLevel;
  jobTitle?: string | null;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export interface OrganizationRoleView {
  organization?: OrganizationLinkView;
  role?: OrganizationAccessLevel;
  jobTitle?: string | null;
  description?: string | null;
  /** @format date-time */
  endDate?: string | null;
}

export enum OrganizationSortOption {
  Name = "Name",
  LastModified = "LastModified",
  SubscriptionDuration = "SubscriptionDuration",
  LastCall = "LastCall",
  LastOrder = "LastOrder",
}

export interface OrganizationView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  actorType?: ActorType;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
  hasAvatar?: boolean;
  organizationNumber?: string | null;
  parent?: OrganizationLinkView;
  handlers?: HandlerView[] | null;
  metadata?: ActorMetadataView;
  customerInfo?: CustomerInfoView;
  customerNumbers?: CustomerNumberView[] | null;
  supplierInfo?: SupplierInfoView;
  supplierNumbers?: SupplierNumberView[] | null;
}

export interface OrganizationViewEntityViewModel {
  view?: OrganizationView;
  links?: Record<string, EntityLinkValueView>;
}

export interface OrganizationViewPagedOrganizationQueryCollectionViewModel {
  items?: OrganizationView[] | null;
  query?: PagedOrganizationQuery;
  /** @format int64 */
  count?: number;
}

export interface PagedAbsenceCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  requiresAdminApproval?: boolean | null;
  sortBy?: AbsenceCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedActivityQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: ActivityType[] | null;
  subTypes?: ActivitySubType[] | null;
  actorId?: string | null;
  relatedActorId?: string | null;
  relatedActorId_ExcludePurePersonal?: boolean | null;
  categoryIds?: string[] | null;
  taskId?: string | null;
  taskClosed?: boolean | null;
  projectId?: string | null;
  projectActive?: boolean | null;
  projectEnded?: boolean | null;
  relationId?: string | null;
  ticketId?: string | null;
  productId?: string | null;
  meetingId?: string | null;
  groupId?: string | null;
  participantIds?: string[] | null;
  actorIdOrParticipantId?: string | null;
  /** @format date-time */
  startAfter?: string | null;
  /** @format date-time */
  startBefore?: string | null;
  /** @format date-time */
  endBefore?: string | null;
  /** @format date-time */
  endAfter?: string | null;
  /** @format date-time */
  calendarStart?: string | null;
  /** @format date-time */
  calendarEnd?: string | null;
  /** @format date-time */
  activeAt?: string | null;
  confirmed?: boolean | null;
  locked?: boolean | null;
  running?: boolean | null;
  requiresAdminApproval?: boolean | null;
  tagIds?: string[] | null;
  categoryTypes?: HourCategoryType[] | null;
  sortBy?: ActivitySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedActivityTransferQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  /** @format date-time */
  lastCalculated?: string | null;
  sortBy?: ActivityTransferSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedActivityTransferWarningQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedActorQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  sortBy?: ActorSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedApplicationQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tenantId?: string | null;
  eligibleForRoles?: boolean | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: ApplicationSortOrder;
  sortDirection?: SortDirection;
}

export interface PagedCallQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  contactId?: string | null;
  customerId?: string | null;
  trackingTypes?: CallTrackingType[] | null;
  contactPointId?: string | null;
  internal?: boolean | null;
  /** @format date */
  fromDate?: string | null;
  /** @format date */
  toDate?: string | null;
  statuses?: CallStatus[] | null;
  missedReasons?: CallMissedReason[] | null;
  directions?: CallDirection[] | null;
  sortBy?: CallSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedChannelQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: ChannelSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedCommentQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: CommentSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedCompletionQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  orderlineId?: string | null;
  productId?: string | null;
  /** @format date-time */
  timestampAfter?: string | null;
  /** @format date-time */
  timestampBefore?: string | null;
  sortBy?: CompletionSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedContactPointQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  contactId?: string | null;
  missingContact?: boolean | null;
  types?: ContactPointType[] | null;
  domain?: string | null;
  sortBy?: ContactPointSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedContactQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: ContactSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedCustomerCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: CustomerCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedDashboardQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  roleIds?: string[] | null;
  actorId?: string | null;
  sortBy?: DashboardSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedEmployeeQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  sortBy?: EmployeeSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  /** @format int32 */
  birthYear?: number | null;
  /** @format int32 */
  birthMonth?: number | null;
  /** @format int32 */
  birthDay?: number | null;
}

export interface PagedExternalLinkQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  entityId?: string | null;
  /** @minLength 1 */
  group: string;
  sortBy?: ExternalLinkSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedFeedQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: FeedSortOption;
  sortDirection?: SortDirection;
}

export interface PagedFileQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  allFiles?: boolean | null;
  parentId?: string | null;
  parentCollection?: string | null;
  applicationId?: string | null;
  extentions?: string[] | null;
  sortBy?: FileSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedFolderQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  allFolders?: boolean | null;
  parentId?: string | null;
  parentCollection?: string | null;
  applicationId?: string | null;
  sortBy?: FolderSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedHourCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: HourCategoryType[] | null;
  activityType?: ActivityType;
  excludedCategories?: string[] | null;
  sortBy?: CategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedIncidentCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: IncidentCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedIncidentQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  routineId?: string | null;
  limitToCategories?: string[] | null;
  limitToCustomers?: string[] | null;
  limitToResources?: string[] | null;
  limitToParticipants?: string[] | null;
  limitToProducts?: string[] | null;
  limitToTags?: string[] | null;
  limitToCauses?: string[] | null;
  dateTypes?: IncidentDateType[] | null;
  registratorId?: string | null;
  closerId?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  /** @format date-time */
  occuredStart?: string | null;
  /** @format date-time */
  occuredEnd?: string | null;
  closed?: boolean | null;
  myIncidents?: string | null;
  sortBy?: IncidentSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedInvoiceQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  customerId?: string | null;
  invoiceNo?: string | null;
  orderId?: string | null;
  projectId?: string | null;
  /** @format double */
  minAmount?: number | null;
  /** @format double */
  maxAmount?: number | null;
  /** @format date-time */
  after?: string | null;
  /** @format date-time */
  before?: string | null;
  sortBy?: InvoiceSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedInvoicelineQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  invoiceNo?: string | null;
  invoiceId?: string | null;
  /** @format double */
  minAmount?: number | null;
  /** @format double */
  maxAmount?: number | null;
  customerId?: string | null;
  projectId?: string | null;
  productId?: string | null;
  productCategoryIds?: string[] | null;
  revenueTypes?: RevenueType[] | null;
  /** @format date-time */
  issuedAfter?: string | null;
  /** @format date-time */
  issuedBefore?: string | null;
  deletedInvoice?: boolean | null;
  sortBy?: InvoicelineSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedLastMeasurementQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  measuringPointIds?: string[] | null;
  resourceId?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedLeadQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  customerId?: string | null;
  contactPersonId?: string | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  /** @format date-time */
  closingStartPeriod?: string | null;
  /** @format date-time */
  closingEndPeriod?: string | null;
  probabilities?: LeadProbability[] | null;
  status?: LeadStatus[] | null;
  limitToCauses?: string[] | null;
  limitToTags?: string[] | null;
  sortBy?: LeadSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedLoginQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  actorId?: string | null;
  roleIds?: string[] | null;
  roleApplicationId?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: LoginSortOption;
  sortDirection?: SortDirection;
}

export interface PagedMeasurementQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  measuringPointIds?: string[] | null;
  resourceId?: string | null;
  /** @format date-time */
  fromDate?: string | null;
  /** @format date-time */
  toDate?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: MeasurementSortOption;
  sortDirection?: SortDirection;
  typeId?: string | null;
  level?: MeasurementLevel;
  relatedUserId?: string | null;
  sensorId?: string | null;
}

export interface PagedMeasuringPointQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  typeId?: string | null;
  sensorId?: string | null;
  sensorPartId?: string | null;
  resourceId?: string | null;
  measuringPointIds?: string[] | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: MeasuringPointSortOption;
  sortDirection?: SortDirection;
}

export interface PagedMeasuringPointTemplateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: MeasuringPointTemplateSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedMeetingQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  type?: MeetingType;
  organizerContactPointId?: string | null;
  organizerId?: string | null;
  participantIds?: string[] | null;
  displayForActorId?: string | null;
  /** @format date-time */
  startAfter?: string | null;
  /** @format date-time */
  endBefore?: string | null;
  /** @format date-time */
  activeAt?: string | null;
  isUpcoming?: boolean | null;
  organizationId?: string | null;
  sortBy?: MeetingSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedNewsQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  authorId?: string | null;
  published?: boolean | null;
  featured?: boolean | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: NewsSortOption;
  sortDirection?: SortDirection;
}

export interface PagedNoteQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  private?: boolean | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedOrderCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: OrderCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedOrderQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: OrderType[] | null;
  actorId?: string | null;
  applicationId?: string | null;
  projectId?: string | null;
  categoryId?: string | null;
  subscriptionId?: string | null;
  subscriptionTransferId?: string | null;
  activityTransferId?: string | null;
  tagIds?: string[] | null;
  reference?: string | null;
  dateTypes?: OrderDateType[] | null;
  /** @format date-time */
  dateFrom?: string | null;
  /** @format date-time */
  dateTo?: string | null;
  completed?: boolean | null;
  sortBy?: OrderSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedOrderlineQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  orderId?: string | null;
  productId?: string | null;
  sortBy?: OrderlineSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedOrderlineReportQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  customerIds?: string[] | null;
  categoryIds?: string[] | null;
  productIds?: string[] | null;
  subscriptionTransferIds?: string[] | null;
  activityTransferIds?: string[] | null;
  groupBy?: OrderlineReportGroupBy;
  type?: OrderlineReportTypeOfTransfer;
  transferType?: OrderlineReportTransferType;
  /** @format date-time */
  fromDate?: string | null;
  /** @format date-time */
  toDate?: string | null;
  sortBy?: OrderlineReportSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedOrganizationQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  hasOrganizationNumber?: boolean | null;
  handlerId?: string | null;
  handlerRoles?: HandlerType[] | null;
  businessRelationTypes?: string[] | null;
  /** @format date-time */
  notCalledSince?: string | null;
  hasActiveSubscription?: boolean | null;
  sortBy?: OrganizationSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedPersonQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  organizationId?: string | null;
  isEmployee?: boolean | null;
  accessLevels?: OrganizationAccessLevel[] | null;
  handlerId?: string | null;
  handlerRoles?: HandlerType[] | null;
  businessRelationTypes?: string[] | null;
  /** @format date-time */
  notCalledSince?: string | null;
  hasActiveSubscription?: boolean | null;
  sortBy?: PersonSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedPollQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  inactive?: boolean | null;
  sortBy?: PollSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedProductCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: ProductCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedProductQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  categoryId?: string | null;
  types?: ProductType[] | null;
  withParts?: boolean | null;
  productCode?: string | null;
  units?: ProductUnit[] | null;
  tagIds?: string[] | null;
  sortBy?: ProductSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedProjectQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: ProjectType[] | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  status?: ProjectStatus[] | null;
  isActive?: boolean | null;
  isEnded?: boolean | null;
  /** @format int64 */
  estimateMin?: number | null;
  /** @format int64 */
  estimateMax?: number | null;
  /** @format int64 */
  priceMin?: number | null;
  /** @format int64 */
  priceMax?: number | null;
  tagIds?: string[] | null;
  actorId?: string | null;
  customerId?: string | null;
  invoiceRecipientId?: string | null;
  roleHolderId?: string | null;
  roleTypes?: ProjectRoleType[] | null;
  sortBy?: ProjectSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedQuerySalary {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  requireCalculation?: boolean | null;
  availableForTransfer?: boolean | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  sortBy?: SalarySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedReportQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: ReportSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedResourceCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  monitored?: boolean | null;
  sortBy?: ResourceCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedResourceLogQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  measuringPointId?: string | null;
  logType?: ResourceLogType;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  unresolvedLimit?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedResourceQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  resourceInternal?: boolean | null;
  resourceMonitored?: boolean | null;
  resourceOrganizationId?: string | null;
  limitToTags?: string[] | null;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
  status?: MeasurementLevel;
  relatedUserId?: string | null;
  notificationReceiverId?: string | null;
  supplierId?: string | null;
  categoryId?: string | null;
  hasOverdueRoutineExecutions?: boolean | null;
  sortBy?: ResourceSortOptions;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedRoleQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: RoleSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedRoutineCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: RoutineCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedRoutineExecutionQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  executeableByActorId?: string | null;
  /** @format date-time */
  nextExecutionStart?: string | null;
  /** @format date-time */
  nextExecutionEnd?: string | null;
  /** @format date-time */
  changingExecutionStart?: string | null;
  /** @format date-time */
  changingExecutionEnd?: string | null;
  /** @format date-time */
  actualExecutionStart?: string | null;
  /** @format date-time */
  actualExecutionEnd?: string | null;
  routineId?: string | null;
  resourceId?: string | null;
  statuses?: RoutineExecutionStatus[] | null;
  sortBy?: RoutineExecutionSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedRoutineQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  responsibleIds?: string[] | null;
  executeableByActorId?: string | null;
  organizationId?: string | null;
  resourceId?: string | null;
  severities?: RoutineSeverityType[] | null;
  categoryIds?: string[] | null;
  /** @format date-time */
  nextExecutionStart?: string | null;
  /** @format date-time */
  nextExecutionEnd?: string | null;
  /** @format date-time */
  originalExecutionStart?: string | null;
  /** @format date-time */
  originalExecutionEnd?: string | null;
  executionTypes?: RoutineExecutionType[] | null;
  isRecurring?: boolean | null;
  isOverdue?: boolean | null;
  hasUpcomingExecutions?: boolean | null;
  tagIds?: string[] | null;
  sortBy?: RoutineSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSensorEventQuery {
  sensorId?: string | null;
  sensorPartId?: string | null;
  resourceId?: string | null;
  eventType?: SensorEventType;
  /** @format date-time */
  fromDateTime?: string | null;
  /** @format date-time */
  toDateTime?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  sortBy?: SensorEventSortOption;
  sortDirection?: SortDirection;
}

export interface PagedSensorPartQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sensorId?: string | null;
  resourceId?: string | null;
  sensorEventType?: SensorEventType;
  availableForResourceId?: string | null;
  sortBy?: SensorPartSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSensorQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  deviceId?: string | null;
  resourceId?: string | null;
  applicationId?: string | null;
  availableForResourceId?: string | null;
  lowBattery?: boolean | null;
  weakOrNoSignal?: boolean | null;
  hasMoreThanOnePart?: boolean | null;
  noSignal?: boolean | null;
  sortBy?: SensorSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSubscriptionLineQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: SubscriptionLineSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSubscriptionQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  actorId?: string | null;
  types?: SubscriptionType[] | null;
  statuses?: SubscriptionStatusType[] | null;
  hasDifference?: boolean | null;
  hasProductDifference?: boolean | null;
  hasResourceDifference?: boolean | null;
  tagIds?: string[] | null;
  sortBy?: SubscriptionSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSubscriptionReportQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  actorId?: string | null;
  categoryIds?: string[] | null;
  productIds?: string[] | null;
  groupBy?: SubscriptionReportGroupBy;
  /** @format date-time */
  date?: string | null;
  sortBy?: SubscriptionReportSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSubscriptionTransferQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: SubscriptionType[] | null;
  subscriptionId?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  sortBy?: SubscriptionTransferSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSuggestionQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  groupId?: string | null;
  participantId?: string | null;
  /** @format date-time */
  startAfter?: string | null;
  /** @format date-time */
  endBefore?: string | null;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedSupplierCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  sortBy?: SupplierCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedTagCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  excludeLeadCauses?: boolean;
  sortBy?: TagCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedTagQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  categoryId?: string | null;
  taggableTypes?: TaggableType[] | null;
  excludedCategories?: string[] | null;
  sortBy?: TagSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedTaskQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  assigneeId?: string | null;
  actorId?: string | null;
  projectId?: string | null;
  customerId?: string | null;
  projectActive?: boolean | null;
  categoryId?: string | null;
  parentId?: string | null;
  closed?: boolean | null;
  /** @format date-time */
  closedAfter?: string | null;
  /** @format date-time */
  closedBefore?: string | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  /** @format int64 */
  estimateMin?: number | null;
  /** @format int64 */
  estimateMax?: number | null;
  /** @format date-time */
  invoicedAfter?: string | null;
  /** @format date-time */
  invoicedBefore?: string | null;
  tagIds?: string[] | null;
  showAll?: boolean | null;
  hasChildren?: boolean | null;
  isContainer?: boolean | null;
  sortBy?: TaskSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedTicketQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  requesterId?: string | null;
  assigneeId?: string | null;
  customerId?: string | null;
  customerIdOrAssigneeId?: string | null;
  assigneeIdOrOtherParticipantId?: string | null;
  relatedToId?: string | null;
  /** @format date-time */
  dueAfter?: string | null;
  /** @format date-time */
  dueBefore?: string | null;
  closed?: boolean | null;
  onHold?: boolean | null;
  status?: TicketStatus[] | null;
  severity?: TicketSeverity[] | null;
  /** @format date-time */
  closedAfter?: string | null;
  /** @format date-time */
  closedBefore?: string | null;
  tagIds?: string[] | null;
  otherParticipantIds?: string[] | null;
  sortBy?: TicketSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface PagedWorkCategoryQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  activityTypes?: ActivityType[] | null;
  sortBy?: WorkCategorySortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
}

export interface ParentRef {
  id?: string | null;
  collection?: string | null;
}

export interface ParticipantInfoRequest {
  contactReference?: string | null;
  optional?: boolean;
  declined?: boolean;
}

export interface ParticipantInfoView {
  contactPoint?: ContactPointLinkView;
  contact?: ContactLinkView;
  ignored?: boolean;
  declined?: boolean;
  optional?: boolean;
}

export interface PerRecieverView {
  /** @format date-time */
  transferPeriodFrom?: string;
  /** @format date-time */
  transferPeriodTo?: string;
  requiresCalculation?: boolean;
  requireTransfer?: boolean;
  /** @format date-time */
  lastCalculated?: string | null;
  salaryTransfer?: SalaryView;
  entries?: SalEntryView[] | null;
  hours?: HourbankEntryView[] | null;
}

export interface Period {
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
}

export interface PersonAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  tagIds?: string[] | null;
  isSupplier?: boolean | null;
  isCustomer?: boolean | null;
  organizationId?: string | null;
  isEmployee?: boolean | null;
  accessLevels?: OrganizationAccessLevel[] | null;
  handlerId?: string | null;
  handlerRoles?: HandlerType[] | null;
  businessRelationTypes?: string[] | null;
  /** @format date-time */
  notCalledSince?: string | null;
  hasActiveSubscription?: boolean | null;
  personId?: string | null;
  aggs?: PersonAggregationTypes[] | null;
}

export interface PersonAggregationResult {
  peopleInOrganization?: Record<string, Aggregate>;
  withoutOrgRolesCount?: AggregateSingleResult;
  customerCount?: AggregateSingleResult;
  withoutContactInfo?: AggregateSingleResult;
  withoutCalls?: AggregateSingleResult;
  newAndActiveThisYear?: AggregateSingleResult;
  activePerYear?: Record<string, SingleDrilldown>;
  relationTags?: Aggregate[] | null;
}

export interface PersonAggregationResultPersonAggregateQueryAggregationResults {
  results?: PersonAggregationResult;
  query?: PersonAggregateQuery;
}

export enum PersonAggregationTypes {
  PeopleInOrganization = "PeopleInOrganization",
  WithoutOrgRolesCount = "WithoutOrgRolesCount",
  CustomerCount = "CustomerCount",
  WithoutContactInfo = "WithoutContactInfo",
  WithoutCalls = "WithoutCalls",
  NewAndActiveThisYear = "NewAndActiveThisYear",
  ActivePerYear = "ActivePerYear",
  RelationTags = "RelationTags",
}

export interface PersonExtendedLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
  organizationRoles?: OrganizationRoleView[] | null;
}

export interface PersonLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  actorType?: ActorType;
}

export enum PersonSortOption {
  Name = "Name",
  LastModified = "LastModified",
  SubscriptionDuration = "SubscriptionDuration",
  LastCall = "LastCall",
  LastOrder = "LastOrder",
}

export interface PersonView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  actorType?: ActorType;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
  hasAvatar?: boolean;
  handlers?: HandlerView[] | null;
  metadata?: ActorMetadataView;
  isEmployee?: boolean;
  organizationRoles?: OrganizationRoleView[] | null;
  customerInfo?: CustomerInfoView;
  customerNumbers?: CustomerNumberView[] | null;
  supplierInfo?: SupplierInfoView;
  supplierNumbers?: SupplierNumberView[] | null;
}

export interface PersonViewEntityViewModel {
  view?: PersonView;
  links?: Record<string, EntityLinkValueView>;
}

export interface PersonViewPagedPersonQueryCollectionViewModel {
  items?: PersonView[] | null;
  query?: PagedPersonQuery;
  /** @format int64 */
  count?: number;
}

export enum PollSortOption {
  Question = "Question",
  LastModified = "LastModified",
  Created = "Created",
}

export interface PollView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  question?: string | null;
  alternatives?: AlternativeView[] | null;
  multipleChoice?: boolean;
  isActive?: boolean;
}

export interface PollViewEntityViewModel {
  view?: PollView;
  links?: Record<string, EntityLinkValueView>;
}

export interface PollViewPagedPollQueryCollectionViewModel {
  items?: PollView[] | null;
  query?: PagedPollQuery;
  /** @format int64 */
  count?: number;
}

export interface PostponeExecution {
  /** @format date-time */
  newExecutionDate?: string;
}

export enum PreviewType {
  PDF = "PDF",
  Image = "Image",
}

export enum PrincipalType {
  System = "System",
  Application = "Application",
  User = "User",
}

export interface PrincipalView {
  id?: string | null;
  type?: PrincipalType;
  name?: string | null;
}

export interface ProductAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  categoryId?: string | null;
  types?: ProductType[] | null;
  withParts?: boolean | null;
  productCode?: string | null;
  units?: ProductUnit[] | null;
  tagIds?: string[] | null;
  productId?: string | null;
  aggs?: ProductAggregationTypes[] | null;
}

export interface ProductAggregationResult {
  netInvoicedPerCategory?: Record<string, Aggregate>;
  netInvoicedPerProduct?: Record<string, Aggregate>;
  netInvoicedPerType?: Aggregate[] | null;
  netInvoicedPerUnit?: Aggregate[] | null;
  quantitySoldPerProduct?: Record<string, Aggregate>;
  totalNetInvoiced?: AggregateSingleResult;
  totalQuantitySold?: AggregateSingleResult;
  hourlyProductsCount?: AggregateSingleResult;
  subscriptionProductsCount?: AggregateSingleResult;
  otherProductsCount?: AggregateSingleResult;
  packagesCount?: AggregateSingleResult;
}

export interface ProductAggregationResultProductAggregateQueryAggregationResults {
  results?: ProductAggregationResult;
  query?: ProductAggregateQuery;
}

export enum ProductAggregationTypes {
  NetInvoicedPerCategory = "NetInvoicedPerCategory",
  NetInvoicedPerProduct = "NetInvoicedPerProduct",
  NetInvoicedPerType = "NetInvoicedPerType",
  NetInvoicedPerUnit = "NetInvoicedPerUnit",
  QuantitySoldPerProduct = "QuantitySoldPerProduct",
  TotalNetInvoiced = "TotalNetInvoiced",
  TotalQuantitySold = "TotalQuantitySold",
  HourlyProductsCount = "HourlyProductsCount",
  SubscriptionProductsCount = "SubscriptionProductsCount",
  OtherProductsCount = "OtherProductsCount",
  PackagesCount = "PackagesCount",
}

export interface ProductCategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
}

export enum ProductCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface ProductCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  excludeFromProductDiff?: boolean;
}

export interface ProductCategoryViewEntityViewModel {
  view?: ProductCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ProductCategoryViewPagedProductCategoryQueryCollectionViewModel {
  items?: ProductCategoryView[] | null;
  query?: PagedProductCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface ProductLinkView {
  id?: string | null;
  deleted?: boolean;
  type?: ProductType;
  code?: string | null;
  name?: string | null;
}

export interface ProductPart {
  productId?: string | null;
  /** @format double */
  quantity?: number;
}

export interface ProductPartView {
  partId?: string | null;
  product?: ProductPartialView;
  /** @format double */
  quantity?: number;
}

export interface ProductPartialView {
  id?: string | null;
  deleted?: boolean;
  type?: ProductType;
  code?: string | null;
  name?: string | null;
  defaultUnit?: ProductUnit;
  prices?: UnitPrice[] | null;
}

export interface ProductReportItemView {
  product?: ProductLinkView;
  subscribed?: {
    /** @format double */
    Yearly?: number;
    /** @format double */
    Quarterly?: number;
    /** @format double */
    Monthly?: number;
  } | null;
  valuesBySource?: Record<string, ProductReportValues>;
}

export interface ProductReportResultView {
  product?: ProductPartialView;
  customer?: ActorLinkView;
  /** @format double */
  quantity?: number | null;
  /** @format double */
  discountMonth?: number | null;
  /** @format double */
  costMonth?: number | null;
  /** @format double */
  sumMonth?: number | null;
  /** @format double */
  revenue?: number | null;
  /** @format double */
  result?: number | null;
}

export interface ProductReportResultViewPagedSubscriptionReportQueryCollectionViewModel {
  items?: ProductReportResultView[] | null;
  query?: PagedSubscriptionReportQuery;
  /** @format int64 */
  count?: number;
}

export interface ProductReportSourceView {
  sourceName?: string | null;
  /** @format date-time */
  timestamp?: string;
  link?: ReportLink;
}

export interface ProductReportValue {
  /** @format double */
  count?: number | null;
  type?: SubscriptionType;
}

export interface ProductReportValues {
  reported?: ProductReportValue[] | null;
  difference?: ProductReportValue[] | null;
}

export interface ProductReportsView {
  /** @format date-time */
  timestamp?: string;
  sources?: Record<string, ProductReportSourceView>;
  items?: ProductReportItemView[] | null;
}

export enum ProductSortOption {
  Name = "Name",
  Code = "Code",
  Type = "Type",
  DefaultUnit = "DefaultUnit",
  Category = "Category",
  LastModified = "LastModified",
}

export interface ProductSupplierView {
  optionId?: string | null;
  supplier?: ActorLinkView;
}

export enum ProductType {
  Standard = "Standard",
  Custom = "Custom",
}

export enum ProductUnit {
  Piece = "Piece",
  Meter = "Meter",
  Square = "Square",
  Cubic = "Cubic",
  Litre = "Litre",
  Kilo = "Kilo",
  Hour = "Hour",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Yearly = "Yearly",
  Other = "Other",
}

export interface ProductView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  type?: ProductType;
  image?: AttachmentView;
  category?: ProductCategoryLinkView;
  defaultUnit?: ProductUnit;
  prices?: UnitPrice[] | null;
  parts?: ProductPartView[] | null;
  suppliers?: ProductSupplierView[] | null;
  tags?: TagLinkView[] | null;
}

export interface ProductViewEntityViewModel {
  view?: ProductView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ProductViewPagedProductQueryCollectionViewModel {
  items?: ProductView[] | null;
  query?: PagedProductQuery;
  /** @format int64 */
  count?: number;
}

export interface ProjectActivityTypeLinks {
  ticketId?: string | null;
  type?: ActivityType;
  /** @minLength 1 */
  taskId: string;
  productId?: string | null;
}

export interface ProjectAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: ProjectType[] | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  status?: ProjectStatus[] | null;
  isActive?: boolean | null;
  isEnded?: boolean | null;
  /** @format int64 */
  estimateMin?: number | null;
  /** @format int64 */
  estimateMax?: number | null;
  /** @format int64 */
  priceMin?: number | null;
  /** @format int64 */
  priceMax?: number | null;
  tagIds?: string[] | null;
  actorId?: string | null;
  customerId?: string | null;
  invoiceRecipientId?: string | null;
  roleHolderId?: string | null;
  roleTypes?: ProjectRoleType[] | null;
  projectId?: string | null;
  aggs?: ProjectAggregationTypes[] | null;
}

export interface ProjectAggregationResult {
  estimateCount?: AggregateSingleResult;
  customerEstimate?: Record<string, Aggregate>;
  estimateRange?: Aggregate[] | null;
  projectTypes?: Aggregate[] | null;
  overEstimateCount?: Record<string, Aggregate>;
  overrunDays?: Aggregate[] | null;
  overrunHours?: Aggregate[] | null;
  remainingInvoiced?: AggregateSingleResult;
  notStartedCount?: AggregateSingleResult;
  inProgressCount?: AggregateSingleResult;
  endedCount?: AggregateSingleResult;
}

export interface ProjectAggregationResultProjectAggregateQueryAggregationResults {
  results?: ProjectAggregationResult;
  query?: ProjectAggregateQuery;
}

export enum ProjectAggregationTypes {
  EstimateCount = "EstimateCount",
  CustomerEstimate = "CustomerEstimate",
  EstimateRange = "EstimateRange",
  ProjectTypes = "ProjectTypes",
  OverEstimateCount = "OverEstimateCount",
  OverrunDays = "OverrunDays",
  OverrunHours = "OverrunHours",
  RemainingInvoiced = "RemainingInvoiced",
  NotStartedCount = "NotStartedCount",
  InProgressCount = "InProgressCount",
  EndedCount = "EndedCount",
}

export interface ProjectLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  type?: ProjectType;
}

export enum ProjectRoleType {
  Leader = "Leader",
  Manager = "Manager",
  Participant = "Participant",
  Viewer = "Viewer",
}

export interface ProjectRoleView {
  actor?: ActorLinkView;
  role?: ProjectRoleType;
  description?: string | null;
}

export enum ProjectSortOption {
  Name = "Name",
  Type = "Type",
  Customer = "Customer",
  InvoiceRecipient = "InvoiceRecipient",
  Start = "Start",
  LatestStart = "LatestStart",
  End = "End",
  Estimate = "Estimate",
  Duration = "Duration",
  Price = "Price",
  Discount = "Discount",
  LastModified = "LastModified",
}

export enum ProjectStatus {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Ended = "Ended",
}

export enum ProjectType {
  Internal = "Internal",
  Hourly = "Hourly",
  Fixed = "Fixed",
}

export interface ProjectView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  type?: ProjectType;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  /** @format date-time */
  firstActivity?: string | null;
  /** @format date-time */
  lastActivity?: string | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format int64 */
  aggregatedEstimate?: number;
  /** @format int64 */
  duration?: number;
  /** @format int64 */
  durationOnEstimatedTasks?: number;
  /** @format int32 */
  price?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format double */
  runningSalesPrice?: number;
  /** @format double */
  netInvoiced?: number | null;
  /** @format double */
  grossInvoiced?: number | null;
  customer?: ActorLinkView;
  invoice?: ActorLinkView;
  orderCategory?: CategoryLinkView;
  roles?: ProjectRoleView[] | null;
  contacts?: ActorLinkView[] | null;
  tags?: TagLinkView[] | null;
  endedBy?: ActorLinkView;
  /** @format date-time */
  activated?: string | null;
  /** @format date-time */
  ended?: string | null;
}

export interface ProjectViewEntityViewModel {
  view?: ProjectView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ProjectViewPagedProjectQueryCollectionViewModel {
  items?: ProjectView[] | null;
  query?: PagedProjectQuery;
  /** @format int64 */
  count?: number;
}

export interface QueryModel {
  terms?: string;
  deleted?: boolean;
  attributes?: Record<string, string>;
  /** @format date-time */
  modifiedAfter?: string;
}

export interface RSAKey {
  b64Key?: string | null;
  format?: string | null;
  usage?: string[] | null;
  algorithm?: RsaAlgorithm;
}

export interface ReactionView {
  person?: PersonLinkView;
  types?: string[] | null;
}

export interface ReadCounter {
  /** @format int64 */
  count?: number;
  /** @format int64 */
  unread?: number;
}

export interface ReadyToTransferStats {
  transferId?: string | null;
  subscription?: SubscriptionLinkView;
  /** @format double */
  sum?: number | null;
  products?: SubscriptionTransferLineView[] | null;
}

export interface RecentCustomerView {
  customer?: ActorLinkView;
  /** @format date-time */
  timestamp?: string;
}

export interface RedirectUrl {
  type: RedirectUrlType;
  /** @minLength 1 */
  url: string;
}

export enum RedirectUrlType {
  Login = "Login",
  Logout = "Logout",
}

export interface ReplaceSensor {
  /** @minLength 1 */
  newSensorId: string;
}

export interface ReportLink {
  name?: string | null;
  url?: string | null;
}

export enum ReportPeriod {
  LastWeek = "LastWeek",
  ThisWeek = "ThisWeek",
  LastMonth = "LastMonth",
  ThisMonth = "ThisMonth",
  LastQuarter = "LastQuarter",
  ThisQuarter = "ThisQuarter",
  LastYear = "LastYear",
  ThisYear = "ThisYear",
}

export enum ReportSortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface ReportView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  reportPeriod?: ReportPeriod;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
  modules?: string | null;
}

export interface ReportViewEntityViewModel {
  view?: ReportView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ReportViewPagedReportQueryCollectionViewModel {
  items?: ReportView[] | null;
  query?: PagedReportQuery;
  /** @format int64 */
  count?: number;
}

export interface ResourceAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  resourceInternal?: boolean | null;
  resourceMonitored?: boolean | null;
  resourceOrganizationId?: string | null;
  limitToTags?: string[] | null;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
  status?: MeasurementLevel;
  relatedUserId?: string | null;
  notificationReceiverId?: string | null;
  supplierId?: string | null;
  categoryId?: string | null;
  hasOverdueRoutineExecutions?: boolean | null;
  aggs?: ResourceAggregationTypes[] | null;
}

export interface ResourceAggregationResult {
  internalCount?: AggregateSingleResult;
  externalCount?: AggregateSingleResult;
  monitoredCount?: AggregateSingleResult;
  criticalCount?: AggregateSingleResult;
  cautionCount?: AggregateSingleResult;
  statusOkCount?: AggregateSingleResult;
  routineOverdueCount?: AggregateSingleResult;
}

export interface ResourceAggregationResultResourceAggregateQueryAggregationResults {
  results?: ResourceAggregationResult;
  query?: ResourceAggregateQuery;
}

export enum ResourceAggregationTypes {
  InternalCount = "InternalCount",
  ExternalCount = "ExternalCount",
  MonitoredCount = "MonitoredCount",
  CriticalCount = "CriticalCount",
  CautionCount = "CautionCount",
  StatusOkCount = "StatusOkCount",
  RoutineOverdueCount = "RoutineOverdueCount",
}

export interface ResourceCategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
  hasPlaceholderImage?: boolean;
}

export enum ResourceCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface ResourceCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  placeholderImage?: AttachmentView;
  enableAddress?: boolean | null;
  enableContactPoints?: boolean | null;
  enableMeasuringPoints?: boolean | null;
}

export interface ResourceCategoryViewEntityViewModel {
  view?: ResourceCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ResourceCategoryViewPagedResourceCategoryQueryCollectionViewModel {
  items?: ResourceCategoryView[] | null;
  query?: PagedResourceCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface ResourceGraphsWebQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  measuringPointIds?: string[] | null;
  /** @format date-time */
  fromDate: string;
  /** @format date-time */
  toDate: string;
}

export interface ResourceGraphsWebView {
  id?: string | null;
  name?: string | null;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
  type?: MeasurementType;
  measurements?: MeasurementView[] | null;
}

export interface ResourceGraphsWebViewResourceGraphsWebQueryCollectionViewModel {
  items?: ResourceGraphsWebView[] | null;
  query?: ResourceGraphsWebQuery;
  /** @format int64 */
  count?: number;
}

export interface ResourceLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export enum ResourceLogType {
  Alerts = "Alerts",
  Routines = "Routines",
  Notes = "Notes",
  Incidents = "Incidents",
}

export interface ResourceLogView {
  id?: string | null;
  resourceIds?: string[] | null;
  title?: string | null;
  text?: string | null;
  /** @format date-time */
  timestamp?: string | null;
  actorId?: string | null;
  actorName?: string | null;
  collection?: string | null;
  measuringPointId?: string | null;
  measuringPointName?: string | null;
  dataType?: string | null;
  alertLevel?: MeasurementLevel;
  /** @format double */
  alertValue?: number | null;
  alertReasonType?: AlertType;
  /** @format date-time */
  alertStarted?: string | null;
  dataTypeFormatted?: MeasurementType;
  unresolved?: boolean | null;
  checklist?: RoutineExecutionChecklistItem[] | null;
  asPlanned?: boolean | null;
  routineStatus?: RoutineExecutionStatus;
  routineId?: string | null;
  /** @format date-time */
  executionDate?: string | null;
}

export interface ResourceLogViewPagedResourceLogQueryCollectionViewModel {
  items?: ResourceLogView[] | null;
  query?: PagedResourceLogQuery;
  /** @format int64 */
  count?: number;
}

export interface ResourceReportType {
  name?: string | null;
  /** @format int32 */
  warningFactor?: number;
}

export interface ResourceReportView {
  /** @format date-time */
  timestamp?: string;
  link?: ReportLink;
  values?: ResourceValues[] | null;
}

export enum ResourceSortOptions {
  Name = "Name",
  LastModified = "LastModified",
  Owner = "Owner",
  Status = "Status",
  Supplier = "Supplier",
}

export enum ResourceType {
  Internal = "Internal",
  External = "External",
}

export interface ResourceValues {
  resource?: string | null;
  /** @format double */
  subscriptionTotal?: number | null;
  /** @format double */
  reportTotal?: number | null;
  /** @format double */
  difference?: number | null;
}

export interface ResourceView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  resourceType?: ResourceType;
  owner?: ActorExtendedLinkView;
  supplier?: ActorExtendedLinkView;
  hasAvatar?: boolean;
  category?: ResourceCategoryLinkView;
  enableAddress?: boolean;
  enableContactPoints?: boolean;
  enableMeasuringPoints?: boolean;
  allNosignalsResolved?: boolean | null;
  notificationReceivers?: ActorExtendedLinkView[] | null;
  contactPeople?: ActorExtendedLinkView[] | null;
  status?: MeasurementLevel;
  alertResolved?: boolean | null;
  /** @format date-time */
  muteUntil?: string | null;
  /** @format date-time */
  nextRoutineExecutionDate?: string | null;
  /** @format date-time */
  previousRoutineExecutionDate?: string | null;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
  noSignal?: boolean;
}

export interface ResourceViewEntityViewModel {
  view?: ResourceView;
  links?: Record<string, EntityLinkValueView>;
}

export interface ResourceViewPagedResourceQueryCollectionViewModel {
  items?: ResourceView[] | null;
  query?: PagedResourceQuery;
  /** @format int64 */
  count?: number;
}

export interface ResumeOrUpdateSubscriptionLine {
  /** @format date-time */
  application: string;
  /** @format double */
  quantity: number;
  /** @format double */
  discount?: number;
  note?: string | null;
  internalNote?: string | null;
}

export enum RevenueType {
  Subscription = "Subscription",
  Hour = "Hour",
  Other = "Other",
}

export interface RevertFileToVersion {
  versionId?: string | null;
}

export interface RoleLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export enum RoleSortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface RoleView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  application?: ApplicationLinkView;
  capabilities?: string[] | null;
}

export interface RoleViewEntityViewModel {
  view?: RoleView;
  links?: Record<string, EntityLinkValueView>;
}

export interface RoleViewPagedRoleQueryCollectionViewModel {
  items?: RoleView[] | null;
  query?: PagedRoleQuery;
  /** @format int64 */
  count?: number;
}

export interface RootFolderView {
  reference?: ParentRef;
  subFolders?: FolderLinkView[] | null;
  files?: FileLinkView[] | null;
  inheritedApplications?: ApplicationLinkView[] | null;
  applications?: ApplicationLinkView[] | null;
}

export interface RoutineAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  responsibleIds?: string[] | null;
  executeableByActorId?: string | null;
  organizationId?: string | null;
  resourceId?: string | null;
  severities?: RoutineSeverityType[] | null;
  categoryIds?: string[] | null;
  /** @format date-time */
  nextExecutionStart?: string | null;
  /** @format date-time */
  nextExecutionEnd?: string | null;
  /** @format date-time */
  originalExecutionStart?: string | null;
  /** @format date-time */
  originalExecutionEnd?: string | null;
  executionTypes?: RoutineExecutionType[] | null;
  isRecurring?: boolean | null;
  isOverdue?: boolean | null;
  hasUpcomingExecutions?: boolean | null;
  tagIds?: string[] | null;
  routineId?: string | null;
  aggs?: RoutineAggregationTypes[] | null;
}

export interface RoutineAggregationResult {
  byCategory?: Record<string, Aggregate>;
  byOrganization?: Record<string, Aggregate>;
  byOwner?: Record<string, Aggregate>;
  byResource?: Record<string, Aggregate>;
  severity?: Aggregate[] | null;
  isRecurringCount?: AggregateSingleResult;
  notRecurringCount?: AggregateSingleResult;
  postponedCount?: AggregateSingleResult;
  overdueCount?: AggregateSingleResult;
}

export interface RoutineAggregationResultRoutineAggregateQueryAggregationResults {
  results?: RoutineAggregationResult;
  query?: RoutineAggregateQuery;
}

export enum RoutineAggregationTypes {
  ByCategory = "ByCategory",
  ByOrganization = "ByOrganization",
  ByOwner = "ByOwner",
  ByResource = "ByResource",
  Severity = "Severity",
  IsRecurringCount = "IsRecurringCount",
  NotRecurringCount = "NotRecurringCount",
  PostponedCount = "PostponedCount",
  OverdueCount = "OverdueCount",
}

export enum RoutineCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface RoutineCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
}

export interface RoutineCategoryViewEntityViewModel {
  view?: RoutineCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface RoutineCategoryViewPagedRoutineCategoryQueryCollectionViewModel {
  items?: RoutineCategoryView[] | null;
  query?: PagedRoutineCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface RoutineChecklistItem {
  id?: string | null;
  description?: string | null;
}

export interface RoutineExecutionAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  executeableByActorId?: string | null;
  /** @format date-time */
  nextExecutionStart?: string | null;
  /** @format date-time */
  nextExecutionEnd?: string | null;
  /** @format date-time */
  changingExecutionStart?: string | null;
  /** @format date-time */
  changingExecutionEnd?: string | null;
  /** @format date-time */
  actualExecutionStart?: string | null;
  /** @format date-time */
  actualExecutionEnd?: string | null;
  routineId?: string | null;
  resourceId?: string | null;
  statuses?: RoutineExecutionStatus[] | null;
  aggs?: RoutineExecutionAggregationTypes[] | null;
}

export interface RoutineExecutionAggregationResult {
  executionTypeCount?: Aggregate[] | null;
}

export interface RoutineExecutionAggregationResultRoutineExecutionAggregateQueryAggregationResults {
  results?: RoutineExecutionAggregationResult;
  query?: RoutineExecutionAggregateQuery;
}

export enum RoutineExecutionAggregationTypes {
  ExecutionTypeCount = "ExecutionTypeCount",
}

export interface RoutineExecutionChecklistItem {
  id?: string | null;
  description?: string | null;
  checked?: boolean | null;
  note?: string | null;
}

export enum RoutineExecutionSortOption {
  ExecutionDate = "ExecutionDate",
  OriginalExecutionDate = "OriginalExecutionDate",
}

export enum RoutineExecutionStatus {
  Planned = "Planned",
  Started = "Started",
  Executed = "Executed",
  Postponed = "Postponed",
  Skipped = "Skipped",
  Deleted = "Deleted",
}

export enum RoutineExecutionTiming {
  AfterSchedule = "AfterSchedule",
  OnSchedule = "OnSchedule",
  BeforeSchedule = "BeforeSchedule",
  Skipped = "Skipped",
  Postponed = "Postponed",
  Planned = "Planned",
}

export enum RoutineExecutionType {
  Execute = "Execute",
  Postpone = "Postpone",
  Skip = "Skip",
}

export interface RoutineExecutionView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  note?: string | null;
  status?: RoutineExecutionStatus;
  /** @format date-time */
  originalExecutionDate?: string;
  /** @format date-time */
  changingExecutionDate?: string;
  /** @format date-time */
  actualExecutionDate?: string | null;
  /** @format date-time */
  postponedDate?: string | null;
  /** @format date-time */
  deletedDate?: string | null;
  /** @format date-time */
  updateCompleted?: string | null;
  executedBy?: ActorLinkView;
  createdByActor?: ActorLinkView;
  updateCompletedBy?: ActorLinkView;
  checklist?: RoutineExecutionChecklistItem[] | null;
  uploadedAttachments?: AttachmentView[] | null;
  routine?: RoutineExtendedLinkView;
  allEmployees?: boolean;
  asPlanned?: boolean | null;
  responsible?: ActorLinkView[] | null;
  timing?: RoutineExecutionTiming;
}

export interface RoutineExecutionViewPagedRoutineExecutionQueryCollectionViewModel {
  items?: RoutineExecutionView[] | null;
  query?: PagedRoutineExecutionQuery;
  /** @format int64 */
  count?: number;
}

export interface RoutineExtendedLinkView {
  id?: string | null;
  deleted?: boolean;
  title?: string | null;
  owner?: PersonLinkView;
  severity?: RoutineSeverityType;
  checklist?: RoutineChecklistItem[] | null;
  frequency?: RoutineFrequency;
}

export interface RoutineFrequency {
  /** @format int32 */
  interval?: number;
  type?: RoutineFrequencyType;
  /** @format date-time */
  startDate?: string;
  basedOnExecution?: boolean;
}

export enum RoutineFrequencyType {
  Year = "Year",
  Quarter = "Quarter",
  Month = "Month",
  Week = "Week",
  Day = "Day",
}

export interface RoutineLinkView {
  id?: string | null;
  deleted?: boolean;
  title?: string | null;
  owner?: PersonLinkView;
}

export enum RoutineSeverityType {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Critical = "Critical",
}

export enum RoutineSortOption {
  Title = "Title",
  NextExecution = "NextExecution",
  Type = "Type",
  LastModified = "LastModified",
}

export interface RoutineView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  title?: string | null;
  description?: string | null;
  severity?: RoutineSeverityType;
  frequency?: RoutineFrequency;
  owner?: PersonLinkView;
  responsible?: PersonLinkView[] | null;
  allEmployees?: boolean;
  organization?: OrganizationLinkView;
  resource?: ResourceLinkView;
  category?: CategoryLinkView;
  checklist?: RoutineChecklistItem[] | null;
  /** @format date-time */
  nextExecution?: string | null;
  /** @format date-time */
  originalNextExecution?: string | null;
  latestAction?: RoutineExecutionView;
  tags?: TagLinkView[] | null;
}

export interface RoutineViewEntityViewModel {
  view?: RoutineView;
  links?: Record<string, EntityLinkValueView>;
}

export interface RoutineViewPagedRoutineQueryCollectionViewModel {
  items?: RoutineView[] | null;
  query?: PagedRoutineQuery;
  /** @format int64 */
  count?: number;
}

export interface RsaAlgorithm {
  name?: string | null;
  hash?: string | null;
}

export interface SalEntryView {
  salaryTypeId?: string | null;
  /** @format date-span */
  duration?: string;
}

export interface SalaryCalculationRequest {
  salary?: SalaryTransactionView[] | null;
  timebank?: TimebankTransactionView[] | null;
  salaryTypes?: SalaryType[] | null;
  warnings?: SalaryWarning[] | null;
}

export interface SalaryCollectionView {
  salary?: SalaryTransactionView[] | null;
  timebank?: TimebankTransactionView[] | null;
}

export interface SalaryConfirmTransferRequest {
  successful?: SalaryCollectionView;
  failed?: SalaryCollectionView;
  /** @format date-time */
  date?: string;
}

export enum SalarySortOption {
  DatePeriod = "DatePeriod",
}

export interface SalaryTransactionView {
  /** @format date-time */
  dateTransferred?: string | null;
  /** @format date-time */
  date?: string;
  /** @format date-span */
  duration?: string;
  isConfirmed?: boolean;
  employee?: EmployeeLinkView;
  employeeNumber?: string | null;
  transactionReference?: string | null;
  attributes?: Record<string, string | null>;
  salaryTypeId?: string | null;
  project?: ProjectLinkView;
}

export interface SalaryTransferRequest {
  salaryId?: string | null;
  employeeIds?: string[] | null;
}

export interface SalaryType {
  salaryTypeId?: string | null;
  name?: string | null;
  description?: string | null;
  format?: EmployeeSalaryTypeFormat;
  excludeFromView?: boolean;
  excludeFromSum?: boolean;
}

export interface SalaryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  requiresCalculation?: boolean;
  /** @format date-time */
  lastCalculated?: string | null;
  warnings?: SalaryWarning[] | null;
  current?: SalaryCollectionView;
  transferred?: Record<string, SalaryCollectionView>;
  diff?: SalaryCollectionView;
  transferring?: SalaryCollectionView;
  datePeriod?: Period;
  salaryTypes?: SalaryType[] | null;
}

export interface SalaryViewEntityViewModel {
  view?: SalaryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SalaryViewPagedQuerySalaryCollectionViewModel {
  items?: SalaryView[] | null;
  query?: PagedQuerySalary;
  /** @format int64 */
  count?: number;
}

export interface SalaryWarning {
  description?: string | null;
  employeeId?: string | null;
  projectId?: string | null;
  /** @format date-span */
  duration?: string;
}

export interface SensorAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  deviceId?: string | null;
  resourceId?: string | null;
  applicationId?: string | null;
  availableForResourceId?: string | null;
  lowBattery?: boolean | null;
  weakOrNoSignal?: boolean | null;
  hasMoreThanOnePart?: boolean | null;
  noSignal?: boolean | null;
  sortBy?: SensorSortOption;
  sortDirection?: SortDirection;
  /** @format int32 */
  from?: number;
  /** @format int32 */
  limit?: number;
  aggs?: SensorAggregationTypes[] | null;
}

export interface SensorAggregationResult {
  lowBatteryCount?: AggregateSingleResult;
  lowSignalCount?: AggregateSingleResult;
  notConnectedToResourceCount?: AggregateSingleResult;
}

export interface SensorAggregationResultSensorAggregateQueryAggregationResults {
  results?: SensorAggregationResult;
  query?: SensorAggregateQuery;
}

export enum SensorAggregationTypes {
  LowBatteryCount = "LowBatteryCount",
  LowSignalCount = "LowSignalCount",
  NotConnectedToResourceCount = "NotConnectedToResourceCount",
}

export interface SensorAlertAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  level?: MeasurementLevel;
  resourceId?: string | null;
  sensorId?: string | null;
  sensorPartId?: string | null;
  measuringPointIds?: string[] | null;
  /** @format date-time */
  fromDateTime?: string | null;
  /** @format date-time */
  toDateTime?: string | null;
  aggs?: SensorAlertAggregationTypes[] | null;
}

export interface SensorAlertAggregationResult {
  alertsByTypeAndDate?: Record<string, Record<string, Aggregate>>;
  count?: Record<string, Aggregate[] | null>;
  eventsByMeasurement?: MeasurementViewPagedMeasurementQueryCollectionViewModel;
}

export interface SensorAlertAggregationResultSensorAlertAggregateQueryAggregationResults {
  results?: SensorAlertAggregationResult;
  query?: SensorAlertAggregateQuery;
}

export enum SensorAlertAggregationTypes {
  AlertsByTypeAndDate = "AlertsByTypeAndDate",
  EventsByMeasurement = "EventsByMeasurement",
  Count = "Count",
}

export interface SensorAlertQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  level?: MeasurementLevel;
  resourceId?: string | null;
  sensorId?: string | null;
  sensorPartId?: string | null;
  measuringPointIds?: string[] | null;
  /** @format date-time */
  fromDateTime?: string | null;
  /** @format date-time */
  toDateTime?: string | null;
}

export interface SensorAlertResolutionView {
  id?: string | null;
  actor?: ActorLinkView;
  /** @format date-time */
  timestamp?: string;
  level?: MeasurementLevel;
  note?: string | null;
}

export enum SensorAlertSortOption {
  Resource = "Resource",
  LastModified = "LastModified",
}

export interface SensorAlertView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  resource?: ResourceLinkView;
  measuringPoint?: MeasuringPointLinkView;
  /** @format double */
  value?: number;
  type?: AlertType;
  sensorPart?: SensorPartLinkView;
  /** @format date-time */
  ended?: string | null;
  /** @format date-time */
  started?: string;
  alertLevel?: MeasurementLevel;
  resolvedLevel?: MeasurementLevel;
  resolutions?: SensorAlertResolutionView[] | null;
}

export interface SensorAlertViewEntityViewModel {
  view?: SensorAlertView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SensorAlertViewSensorAlertQueryCollectionViewModel {
  items?: SensorAlertView[] | null;
  query?: SensorAlertQuery;
  /** @format int64 */
  count?: number;
}

export enum SensorEventSortOption {
  Timestamp = "Timestamp",
}

export enum SensorEventType {
  Temperature = "Temperature",
  Humidity = "Humidity",
  Touch = "Touch",
  TouchCount = "TouchCount",
  ObjectPresent = "ObjectPresent",
  ObjectPresentCount = "ObjectPresentCount",
  WaterPresent = "WaterPresent",
  DeskOccupancy = "DeskOccupancy",
  Motion = "Motion",
  Pressure = "Pressure",
  Co2 = "Co2",
  Toggle = "Toggle",
  GenericNumeric = "GenericNumeric",
  Voltage = "Voltage",
  Amperage = "Amperage",
  Watt = "Watt",
  Frequency = "Frequency",
  CosPhi = "CosPhi",
  VAR = "VAR",
  VA = "VA",
  NetworkStatus = "NetworkStatus",
  BatteryStatus = "BatteryStatus",
  PreviousEventDuplicator = "PreviousEventDuplicator",
}

export interface SensorEventView {
  sensorPart?: SensorPartLinkView;
  eventType?: SensorEventType;
  /** @format date-time */
  timestamp?: string;
  values?: any;
  tag?: string | null;
}

export interface SensorEventViewPagedSensorEventQueryCollectionViewModel {
  items?: SensorEventView[] | null;
  query?: PagedSensorEventQuery;
  /** @format int64 */
  count?: number;
}

export interface SensorLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export interface SensorMeasurementAggregationResult {
  resource?: ResourceView;
  measurementsByPoint?: MeasurementPointValues[] | null;
  measurementPoints?: MeasuringPointView[] | null;
}

export interface SensorMeasurementAggregationResultMeasurementAggregateQueryAggregationResults {
  results?: SensorMeasurementAggregationResult;
  query?: MeasurementAggregateQuery;
}

export interface SensorPartLinkView {
  id?: string | null;
  deleted?: boolean;
  displayName?: string | null;
  partName?: string | null;
}

export enum SensorPartSortOption {
  DisplayName = "DisplayName",
  Resource = "Resource",
  LastModified = "LastModified",
}

export interface SensorPartView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  resource?: ResourceLinkView;
  sensor?: SensorLinkView;
  displayName?: string | null;
  partName?: string | null;
}

export interface SensorPartViewEntityViewModel {
  view?: SensorPartView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SensorPartViewPagedSensorPartQueryCollectionViewModel {
  items?: SensorPartView[] | null;
  query?: PagedSensorPartQuery;
  /** @format int64 */
  count?: number;
}

export enum SensorSortOption {
  Name = "Name",
  Resource = "Resource",
  LastModified = "LastModified",
}

export enum SensorValueType {
  None = "None",
  Integer = "Integer",
  Boolean = "Boolean",
  Decimal = "Decimal",
}

export interface SensorView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  deviceId?: string | null;
  isBatteryLow?: boolean | null;
  isNetworkLow?: boolean | null;
  application?: ApplicationLinkView;
  resource?: ResourceLinkView;
  parts?: SensorPartLinkView[] | null;
  eventTypes?: SensorEventType[] | null;
  noSignal?: boolean;
}

export interface SensorViewEntityViewModel {
  view?: SensorView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SensorViewPagedSensorQueryCollectionViewModel {
  items?: SensorView[] | null;
  query?: PagedSensorQuery;
  /** @format int64 */
  count?: number;
}

export interface SingleDrilldown {
  label?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format double */
  sum?: number | null;
  /** @format double */
  average?: number | null;
  drilldown?: Aggregate[] | null;
}

export interface SingleEntityDrilldown {
  label?: string | null;
  /** @format int64 */
  count?: number | null;
  /** @format double */
  sum?: number | null;
  /** @format double */
  average?: number | null;
  drilldown?: Record<string, Aggregate>;
}

export enum SortDirection {
  Asc = "Asc",
  Desc = "Desc",
}

export interface StopSubscriptionLine {
  /** @format date-time */
  application: string;
}

export interface SubscriptionAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  actorId?: string | null;
  types?: SubscriptionType[] | null;
  statuses?: SubscriptionStatusType[] | null;
  hasDifference?: boolean | null;
  hasProductDifference?: boolean | null;
  hasResourceDifference?: boolean | null;
  tagIds?: string[] | null;
  subscriptionId?: string | null;
  aggs?: SubscriptionAggregationTypes[] | null;
}

export interface SubscriptionAggregationResult {
  typeCount?: Record<string, Aggregate>;
  totalValue?: AggregateSingleResult;
  newThisYear?: AggregateSingleResult;
  resourceTypes?: Record<string, Aggregate>;
  totalCurrent?: AggregateSingleResult;
  totalSumHistory?: Record<string, number>;
  totalSumHistoryByHistoricPrices?: Record<string, number>;
}

export interface SubscriptionAggregationResultSubscriptionAggregateQueryAggregationResults {
  results?: SubscriptionAggregationResult;
  query?: SubscriptionAggregateQuery;
}

export enum SubscriptionAggregationTypes {
  TypeCount = "TypeCount",
  TotalValue = "TotalValue",
  NewThisYear = "NewThisYear",
  TotalCurrent = "TotalCurrent",
  TotalSumHistory = "TotalSumHistory",
  TotalSumHistoryByHistoricPrices = "TotalSumHistoryByHistoricPrices",
}

export interface SubscriptionLineBindingPeriodView {
  /** @format date-time */
  periodStart?: string;
  /** @format date-time */
  periodEnd?: string;
  /** @format double */
  difference?: number;
  /** @format date-time */
  invoiceDate?: string;
}

export interface SubscriptionLineHistoryView {
  lineId?: string | null;
  /** @format double */
  quantity?: number;
  /** @format double */
  discount?: number;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string | null;
  note?: string | null;
  internalNote?: string | null;
  /** @format double */
  monthlyValue?: number;
  /** @format double */
  unitPrice?: number;
}

export enum SubscriptionLineSortOption {
  ProductCode = "ProductCode",
  ProductName = "ProductName",
  Quantity = "Quantity",
  Discount = "Discount",
  MonthlyValue = "MonthlyValue",
  UnitPrice = "UnitPrice",
}

export interface SubscriptionLineView {
  lineId?: string | null;
  /** @format double */
  quantity?: number;
  /** @format double */
  discount?: number;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string | null;
  note?: string | null;
  internalNote?: string | null;
  /** @format double */
  monthlyValue?: number;
  /** @format double */
  unitPrice?: number;
  id?: string | null;
  product?: ProductPartialView;
  history?: SubscriptionLineHistoryView[] | null;
  latestBindingPeriod?: SubscriptionLineBindingPeriodView;
}

export interface SubscriptionLineViewPagedSubscriptionLineQueryCollectionViewModel {
  items?: SubscriptionLineView[] | null;
  query?: PagedSubscriptionLineQuery;
  /** @format int64 */
  count?: number;
}

export interface SubscriptionLinkView {
  id?: string | null;
  deleted?: boolean;
  customer?: ActorLinkView;
  description?: string | null;
}

export interface SubscriptionPeriod {
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
}

export enum SubscriptionReportGroupBy {
  Product = "Product",
  Customer = "Customer",
}

export enum SubscriptionReportSortOption {
  Code = "Code",
  ProductName = "ProductName",
  CustomerName = "CustomerName",
  Quantity = "Quantity",
  Cost = "Cost",
  Discount = "Discount",
  Revenue = "Revenue",
  Result = "Result",
}

export enum SubscriptionSortOption {
  Customer = "Customer",
  InvoiceReceiver = "InvoiceReceiver",
  Value = "Value",
  LastModified = "LastModified",
}

export enum SubscriptionStatusType {
  Inactive = "Inactive",
  Active = "Active",
  Cancelled = "Cancelled",
}

export interface SubscriptionTransferAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  types?: SubscriptionType[] | null;
  subscriptionId?: string | null;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
  aggs?: SubscriptionTransferAggregationTypes[] | null;
}

export interface SubscriptionTransferAggregationResult {
  sumPerYear?: SubscriptionTransferStatistics;
  ordersPerPeriod?: SubscriptionTransferOrderStatistics;
}

export interface SubscriptionTransferAggregationResultSubscriptionTransferAggregateQueryAggregationResults {
  results?: SubscriptionTransferAggregationResult;
  query?: SubscriptionTransferAggregateQuery;
}

export enum SubscriptionTransferAggregationTypes {
  SumPerYear = "SumPerYear",
  OrdersPerPeriod = "OrdersPerPeriod",
}

export interface SubscriptionTransferLineView {
  lineId?: string | null;
  product?: ProductPartialView;
  /** @format double */
  quantity?: number;
  /** @format double */
  discount?: number;
  note?: string | null;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
}

export interface SubscriptionTransferOrderPerMonth {
  /** @format double */
  orderSum?: number;
  month?: string | null;
}

export interface SubscriptionTransferOrderPerQuarter {
  /** @format double */
  orderSum?: number;
  quarter?: string | null;
}

export interface SubscriptionTransferOrderPerYear {
  /** @format double */
  orderSum?: number;
  year?: string | null;
  sumPerMonth?: SubscriptionTransferOrderPerMonth[] | null;
  sumPerQuarter?: SubscriptionTransferOrderPerQuarter[] | null;
}

export interface SubscriptionTransferOrderStatistics {
  /** @format double */
  orderSum?: number;
  transferPerYear?: SubscriptionTransferOrderPerYear[] | null;
}

export interface SubscriptionTransferPerMonth {
  /** @format double */
  current?: number;
  /** @format double */
  transferred?: number;
  month?: string | null;
}

export interface SubscriptionTransferPerQuarter {
  /** @format double */
  current?: number;
  /** @format double */
  transferred?: number;
  quarter?: string | null;
}

export interface SubscriptionTransferPerYear {
  /** @format double */
  current?: number;
  /** @format double */
  transferred?: number;
  year?: string | null;
  sumPerMonth?: SubscriptionTransferPerMonth[] | null;
  sumPerQuarter?: SubscriptionTransferPerQuarter[] | null;
}

export enum SubscriptionTransferSortOption {
  LastModified = "LastModified",
}

export interface SubscriptionTransferStatistics {
  /** @format double */
  current?: number;
  /** @format double */
  transferred?: number;
  transferPerYear?: SubscriptionTransferPerYear[] | null;
}

export interface SubscriptionTransferStats {
  /** @format int64 */
  count?: number;
  /** @format int64 */
  subscriptionCount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  sum?: number;
}

export interface SubscriptionTransferView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  type?: SubscriptionType;
  period?: SubscriptionPeriod;
  requireTransfer?: boolean;
  isCalculating?: boolean;
  /** @format date-time */
  lastCalculated?: string | null;
  warnings?: SubscriptionWarningView[] | null;
  transferred?: SubscriptionTransferStats;
  current?: SubscriptionTransferStats;
  diff?: SubscriptionTransferStats;
}

export interface SubscriptionTransferViewEntityViewModel {
  view?: SubscriptionTransferView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SubscriptionTransferViewPagedSubscriptionTransferQueryCollectionViewModel {
  items?: SubscriptionTransferView[] | null;
  query?: PagedSubscriptionTransferQuery;
  /** @format int64 */
  count?: number;
}

export enum SubscriptionType {
  Yearly = "Yearly",
  Quarterly = "Quarterly",
  Monthly = "Monthly",
}

export interface SubscriptionView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  customer?: ActorLinkView;
  invoiceReceiver?: ActorLinkView;
  description?: string | null;
  typeHistory?: Record<string, SubscriptionType>;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  cancellationDate?: string | null;
  status?: SubscriptionStatusType;
  /** @format double */
  value?: number | null;
  /** @format double */
  currentValue?: number | null;
  tags?: TagLinkView[] | null;
  productReportTypes?: Record<string, string | null>;
  resourceTypes?: Record<string, ResourceReportType>;
  latestNote?: NoteLinkView;
  relatedReports?: ActorReportsView;
}

export interface SubscriptionViewEntityViewModel {
  view?: SubscriptionView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SubscriptionViewPagedSubscriptionQueryCollectionViewModel {
  items?: SubscriptionView[] | null;
  query?: PagedSubscriptionQuery;
  /** @format int64 */
  count?: number;
}

export enum SubscriptionWarningStatus {
  Info = "Info",
  Warning = "Warning",
  Critical = "Critical",
}

export interface SubscriptionWarningView {
  status?: SubscriptionWarningStatus;
  product?: ProductLinkView;
  actor?: ActorLinkView;
  subscription?: SubscriptionLinkView;
  message?: string | null;
}

export enum SuggestionType {
  ActivityGroup = "ActivityGroup",
  AppointmentEvent = "AppointmentEvent",
  MeetingEvent = "MeetingEvent",
}

export interface SuggestionView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  type?: ActivityType;
  subType?: ActivitySubType;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string | null;
  description?: string | null;
  /** @format int64 */
  duration?: number;
  actor?: ActorLinkView;
  project?: ProjectLinkView;
  task?: TaskLinkViewWithParents;
  category?: HourCategoryLinkView;
  product?: ProductLinkView;
  ticket?: TicketLinkView;
  relation?: ActorLinkView;
  tags?: TagLinkView[] | null;
  suggestionType?: SuggestionType;
  meetingId?: string | null;
  groupId?: string | null;
  organizer?: ParticipantInfoView;
  participants?: ParticipantInfoView[] | null;
}

export interface SuggestionViewPagedSuggestionQueryCollectionViewModel {
  items?: SuggestionView[] | null;
  query?: PagedSuggestionQuery;
  /** @format int64 */
  count?: number;
}

export enum SupplierCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface SupplierCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  numberSeries?: NumberSeriesView[] | null;
}

export interface SupplierCategoryViewEntityViewModel {
  view?: SupplierCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface SupplierCategoryViewPagedSupplierCategoryQueryCollectionViewModel {
  items?: SupplierCategoryView[] | null;
  query?: PagedSupplierCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface SupplierInfoView {
  category?: CategoryLinkView;
}

export interface SupplierNumberView {
  applicationId?: string | null;
  /** @format int32 */
  supplierNumber?: number;
}

export interface TagCategoryLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
  limitedToTaggableTypes?: TaggableType[] | null;
}

export enum TagCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface TagCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  limitedToTaggableTypes?: TaggableType[] | null;
  tags?: TagLinkView[] | null;
}

export interface TagCategoryViewEntityViewModel {
  view?: TagCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface TagCategoryViewPagedTagCategoryQueryCollectionViewModel {
  items?: TagCategoryView[] | null;
  query?: PagedTagCategoryQuery;
  /** @format int64 */
  count?: number;
}

export interface TagLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  color?: string | null;
  overrideColor?: boolean | null;
  category?: TagCategoryLinkView;
}

export enum TagSortOption {
  Name = "Name",
  Category = "Category",
  LastModified = "LastModified",
}

export interface TagView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  color?: string | null;
  overrideColor?: boolean | null;
  category?: TagCategoryLinkView;
}

export interface TagViewEntityViewModel {
  view?: TagView;
  links?: Record<string, EntityLinkValueView>;
}

export interface TagViewPagedTagQueryCollectionViewModel {
  items?: TagView[] | null;
  query?: PagedTagQuery;
  /** @format int64 */
  count?: number;
}

export enum TaggableType {
  Order = "Order",
  Organization = "Organization",
  Person = "Person",
  Product = "Product",
  Project = "Project",
  Resource = "Resource",
  Sensor = "Sensor",
  Subscription = "Subscription",
  Task = "Task",
  Activity = "Activity",
  Ticket = "Ticket",
  Lead = "Lead",
  Incident = "Incident",
  Routine = "Routine",
  Files = "Files",
}

export interface TaskAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  ownerId?: string | null;
  assigneeId?: string | null;
  actorId?: string | null;
  projectId?: string | null;
  customerId?: string | null;
  projectActive?: boolean | null;
  categoryId?: string | null;
  parentId?: string | null;
  closed?: boolean | null;
  /** @format date-time */
  closedAfter?: string | null;
  /** @format date-time */
  closedBefore?: string | null;
  /** @format date-time */
  startPeriod?: string | null;
  /** @format date-time */
  endPeriod?: string | null;
  /** @format int64 */
  estimateMin?: number | null;
  /** @format int64 */
  estimateMax?: number | null;
  /** @format date-time */
  invoicedAfter?: string | null;
  /** @format date-time */
  invoicedBefore?: string | null;
  tagIds?: string[] | null;
  showAll?: boolean | null;
  hasChildren?: boolean | null;
  isContainer?: boolean | null;
  taskId?: string | null;
  aggs?: TaskAggregationTypes[] | null;
}

export interface TaskAggregationResult {
  totalAggregatedEstimate?: AggregateSingleResult;
  totalDuration?: AggregateSingleResult;
  projectDuration?: Record<string, Aggregate>;
  totalEstimate?: AggregateSingleResult;
  topTaskCategories?: Record<string, Aggregate>;
  estimateRange?: Aggregate[] | null;
  estimationResult?: Record<string, Aggregate>;
  taskInvoicedPerMonth?: Record<string, SingleDrilldown>;
  tasksOverrunHours?: Aggregate[] | null;
  remainingDuration?: AggregateSingleResult;
  assignedRemainingDuration?: Record<string, Aggregate>;
  topTaskOwners?: Record<string, Aggregate>;
  closedCount?: AggregateSingleResult;
  openCount?: AggregateSingleResult;
  totalCount?: AggregateSingleResult;
}

export interface TaskAggregationResultTaskAggregateQueryAggregationResults {
  results?: TaskAggregationResult;
  query?: TaskAggregateQuery;
}

export enum TaskAggregationTypes {
  TotalAggregatedEstimate = "TotalAggregatedEstimate",
  TotalDuration = "TotalDuration",
  ProjectDuration = "ProjectDuration",
  TotalEstimate = "TotalEstimate",
  TopTaskCategories = "TopTaskCategories",
  EstimateRange = "EstimateRange",
  EstimationResult = "EstimationResult",
  TaskInvoicedPerMonth = "TaskInvoicedPerMonth",
  TasksOverrunHours = "TasksOverrunHours",
  RemainingDuration = "RemainingDuration",
  AssignedRemainingDuration = "AssignedRemainingDuration",
  TopTaskOwners = "TopTaskOwners",
  ClosedCount = "ClosedCount",
  OpenCount = "OpenCount",
  TotalCount = "TotalCount",
}

export interface TaskLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export interface TaskLinkViewWithParents {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  parents?: TaskLinkView[] | null;
}

export enum TaskSortOption {
  Name = "Name",
  Owner = "Owner",
  Assignee = "Assignee",
  Requester = "Requester",
  Project = "Project",
  Customer = "Customer",
  Category = "Category",
  Estimate = "Estimate",
  Duration = "Duration",
  Start = "Start",
  End = "End",
  Priority = "Priority",
  LastModified = "LastModified",
}

export enum TaskType {
  Normal = "Normal",
  Container = "Container",
}

export interface TaskView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  type?: TaskType;
  /** @format float */
  priority?: number;
  /** @format int64 */
  estimate?: number | null;
  /** @format int64 */
  aggregatedEstimate?: number | null;
  /** @format int64 */
  duration?: number;
  /** @format int64 */
  aggregatedDuration?: number;
  /** @format double */
  discount?: number | null;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  /** @format date-time */
  actualStart?: string | null;
  /** @format date-time */
  actualEnd?: string | null;
  hasChildren?: boolean;
  parents?: TaskLinkView[] | null;
  owner?: PersonLinkView;
  assignee?: PersonLinkView;
  requester?: PersonLinkView;
  project?: ProjectLinkView;
  projectOwner?: ActorLinkView;
  category?: HourCategoryLinkView;
  tags?: TagLinkView[] | null;
}

export interface TaskViewEntityViewModel {
  view?: TaskView;
  links?: Record<string, EntityLinkValueView>;
}

export interface TaskViewPagedTaskQueryCollectionViewModel {
  items?: TaskView[] | null;
  query?: PagedTaskQuery;
  /** @format int64 */
  count?: number;
}

export interface TenantLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
}

export interface TicketAggregateQuery {
  terms?: string | null;
  deleted?: boolean | null;
  attributes?: Record<string, string | null>;
  /** @format date-time */
  modifiedAfter?: string | null;
  requesterId?: string | null;
  assigneeId?: string | null;
  customerId?: string | null;
  customerIdOrAssigneeId?: string | null;
  assigneeIdOrOtherParticipantId?: string | null;
  relatedToId?: string | null;
  /** @format date-time */
  dueAfter?: string | null;
  /** @format date-time */
  dueBefore?: string | null;
  closed?: boolean | null;
  onHold?: boolean | null;
  status?: TicketStatus[] | null;
  severity?: TicketSeverity[] | null;
  /** @format date-time */
  closedAfter?: string | null;
  /** @format date-time */
  closedBefore?: string | null;
  tagIds?: string[] | null;
  otherParticipantIds?: string[] | null;
  aggs?: TicketAggregationTypes[] | null;
}

export interface TicketAggregationResult {
  openCount?: AggregateSingleResult;
  closedCount?: AggregateSingleResult;
  onHoldCount?: AggregateSingleResult;
  unassignedCount?: AggregateSingleResult;
  overdueCount?: AggregateSingleResult;
  perTag?: Record<string, Aggregate>;
  perCustomer?: Record<string, Aggregate>;
  perAssignee?: Record<string, Aggregate>;
}

export interface TicketAggregationResultTicketAggregateQueryAggregationResults {
  results?: TicketAggregationResult;
  query?: TicketAggregateQuery;
}

export enum TicketAggregationTypes {
  OpenCount = "OpenCount",
  ClosedCount = "ClosedCount",
  OnHoldCount = "OnHoldCount",
  UnassignedCount = "UnassignedCount",
  OverdueCount = "OverdueCount",
  PerTag = "PerTag",
  PerCustomer = "PerCustomer",
  PerAssignee = "PerAssignee",
}

export interface TicketLinkView {
  id?: string | null;
  deleted?: boolean;
  name?: string | null;
  /** @format int64 */
  ticketNumber?: number;
  /** @format date-time */
  dueDate?: string | null;
}

export enum TicketSeverity {
  Low = "Low",
  Medium = "Medium",
  High = "High",
  Critical = "Critical",
}

export enum TicketSortOption {
  TicketNumber = "TicketNumber",
  Requester = "Requester",
  Assignee = "Assignee",
  Customer = "Customer",
  DueDate = "DueDate",
  LastModified = "LastModified",
  Status = "Status",
  Severity = "Severity",
  CustomerAndRequester = "CustomerAndRequester",
  ClosedDate = "ClosedDate",
}

export enum TicketStatus {
  Open = "Open",
  Closed = "Closed",
  OnHold = "OnHold",
}

export interface TicketView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  /** @format int64 */
  ticketNumber?: number;
  /** @format int64 */
  duration?: number;
  /** @format date-time */
  closedDate?: string | null;
  /** @format date-time */
  onHoldDate?: string | null;
  status?: TicketStatus;
  severity?: TicketSeverity;
  /** @format date-time */
  dueDate?: string | null;
  requester?: ActorLinkView;
  assignee?: PersonLinkView;
  customer?: ActorLinkView;
  tags?: TagLinkView[] | null;
  otherParticipants?: PersonLinkView[] | null;
}

export interface TicketViewEntityViewModel {
  view?: TicketView;
  links?: Record<string, EntityLinkValueView>;
}

export interface TicketViewPagedTicketQueryCollectionViewModel {
  items?: TicketView[] | null;
  query?: PagedTicketQuery;
  /** @format int64 */
  count?: number;
}

export enum TimebankTransactionType {
  In = "In",
  Out = "Out",
}

export interface TimebankTransactionView {
  /** @format date-time */
  dateTransferred?: string | null;
  /** @format date-time */
  date?: string;
  /** @format date-span */
  duration?: string;
  isConfirmed?: boolean;
  employee?: EmployeeLinkView;
  employeeNumber?: string | null;
  transactionReference?: string | null;
  attributes?: Record<string, string | null>;
  type?: TimebankTransactionType;
}

export interface TwoFactor {
  type?: TwoFactorType;
  value?: string | null;
}

export enum TwoFactorType {
  Email = "Email",
  SMS = "SMS",
  None = "None",
}

export interface UnitPrice {
  unit?: ProductUnit;
  /** @format double */
  sales?: number | null;
  /** @format double */
  cost?: number | null;
}

export interface UpcomingAppointmentView {
  id?: string | null;
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string | null;
  inProgress?: boolean;
  subject?: string | null;
  description?: string | null;
  participants?: ActorLinkView[] | null;
  location?: MeetingLocation;
  type?: string | null;
}

export interface UpcomingAppointmentViewPagedMeetingQueryCollectionViewModel {
  items?: UpcomingAppointmentView[] | null;
  query?: PagedMeetingQuery;
  /** @format int64 */
  count?: number;
}

export interface UpdateAlternatives {
  alternativeId?: string | null;
  title?: string | null;
}

export interface UpdateApplication {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  applicationUrl?: string | null;
  redirectUrls?: RedirectUrl[] | null;
  assignedApiCapabilities?: Record<string, string[] | null>;
  loginFlows?: LoginFlow[] | null;
}

export interface UpdateCallTracking {
  /** @minLength 1 */
  actorId: string;
  type: CallTrackingType;
  metadata?: Record<string, string | null>;
}

export interface UpdateCallTransfer {
  completed?: boolean;
  /** @minLength 1 */
  referrer: string;
  /** @minLength 1 */
  from: string;
  /** @minLength 1 */
  to: string;
}

export interface UpdateClientSecret {
  secret?: string | null;
}

export interface UpdateComment {
  /** @minLength 1 */
  text: string;
  /** @minLength 1 */
  actorId: string;
}

export interface UpdateCompletion {
  /** @format double */
  quantity?: number;
  /** @format date-time */
  timestamp?: string;
}

export interface UpdateContactPoint {
  description?: string | null;
  primary?: boolean;
  /** @minLength 1 */
  value: string;
  /** @minLength 1 */
  contactId: string;
}

export interface UpdateCustomerActivity {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  links: CustomerActivityTypeLinks;
}

export interface UpdateDashboard {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  modules?: string | null;
  roleIds?: string[] | null;
  actorIds?: string[] | null;
  availableAll?: boolean | null;
  standard?: boolean | null;
}

export interface UpdateEmployee {
  /** @format double */
  percentage?: number | null;
  /** @format int32 */
  employeeNumber?: number | null;
  /** @format date-time */
  birthday?: string | null;
  relative?: string | null;
  relativeContactInfo?: string | null;
  jobTitle?: string | null;
  salaryTypes?: EmployeeSalaryType[] | null;
}

export interface UpdateEmployeeHourBankCommand {
  /** @format int32 */
  year?: number;
  hourBank?: Record<string, number>;
}

export interface UpdateEmployeeIllnessChildCommand {
  /** @format int32 */
  year?: number;
  illnessChild?: Record<string, number>;
}

export interface UpdateEmployeeVacationDaysCommand {
  /** @format int32 */
  year?: number;
  employeeVacation?: Record<string, VacationRecord>;
}

export interface UpdateFileOrFolder {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  applicationIds?: string[] | null;
}

export interface UpdateIncident {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  /** @minLength 1 */
  categoryId: string;
  /** @format date-time */
  occuredDate?: string | null;
  /** @format date-time */
  expectedClosing?: string | null;
  ownerId?: string | null;
  routineId?: string | null;
  customers?: string[] | null;
  resources?: string[] | null;
  otherParticipants?: string[] | null;
  products?: string[] | null;
  tagIds?: string[] | null;
}

export interface UpdateInternalActivity {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  links: InternalActivityTypeLinks;
}

export interface UpdateInvoice {
  projectId?: string | null;
  /** @format date-time */
  issued?: string | null;
  /** @format date-time */
  dueDate?: string | null;
  label?: string | null;
}

export interface UpdateInvoiceline {
  unit?: ProductUnit;
  reference?: string | null;
  /** @format double */
  salesPrice?: number;
  /** @format double */
  discount?: number;
  /** @format double */
  quantity?: number;
  /** @format double */
  vat?: number;
  productId?: string | null;
  description?: string | null;
}

export interface UpdateLead {
  title?: string | null;
  description?: string | null;
  ownerId?: string | null;
  customerId?: string | null;
  contactPersonId?: string | null;
  /** @format int64 */
  fixedPrice?: number | null;
  /** @format int64 */
  annualPrice?: number | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format date-time */
  expectedClose?: string | null;
  /** @format date-time */
  closingDate?: string | null;
  probability?: LeadProbability;
  status?: LeadStatus;
  tagIds?: string[] | null;
  causeIds?: string[] | null;
}

export interface UpdateLogin {
  roleIds: string[];
  /** @minLength 1 */
  principalId: string;
}

export interface UpdateManyRequest {
  id?: string | null;
  changeVector?: string | null;
}

export interface UpdateMeasuringPoint {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  cautionThreshold?: MeasurementThreshold;
  criticalThreshold?: MeasurementThreshold;
}

export interface UpdateMeasuringPointTemplate {
  title?: string | null;
  description?: string | null;
}

export interface UpdateNote {
  /** @minLength 1 */
  text: string;
  sticky: boolean;
  private: boolean;
  newEntityId?: string | null;
}

export interface UpdateNotificationStatusCommand {
  status?: NotificationStatus;
}

export interface UpdateNotifications {
  userId?: string | null;
  updated?: string[] | null;
}

export interface UpdateOrder {
  type?: OrderType;
  label?: string | null;
  customerId?: string | null;
  dates?: {
    /** @format date-time */
    Quotation?: string;
    /** @format date-time */
    QuotationExpiry?: string;
    /** @format date-time */
    QuotationLost?: string;
    /** @format date-time */
    Sales?: string;
    /** @format date-time */
    SalesCorrection?: string;
    /** @format date-time */
    SalesProduction?: string;
    /** @format date-time */
    SalesCompleted?: string;
    /** @format date-time */
    RequestedDelivery?: string;
    /** @format date-time */
    Delivered?: string;
  } | null;
  deliveryAddress?: Address;
  invoiceAddress?: Address;
  projectId?: string | null;
  categoryId?: string | null;
  invoiceReceiverId?: string | null;
  externalContactId?: string | null;
  internalContactId?: string | null;
  tagIds?: string[] | null;
}

export interface UpdateOrderline {
  /** @minLength 1 */
  productId: string;
  /** @format double */
  quantity: number;
  unit?: ProductUnit;
  reference?: string | null;
  description?: string | null;
  salesPrice?: DecimalOrderlineStatus;
  costPrice?: DecimalOrderlineStatus;
  discount?: DecimalOrderlineStatus;
}

export interface UpdatePoll {
  question?: string | null;
  alternatives?: UpdateAlternatives[] | null;
  isActive?: boolean;
}

export interface UpdateProduct {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  categoryId?: string | null;
  defaultUnit?: ProductUnit;
  tagIds?: string[] | null;
}

export interface UpdateProject {
  projectLeaderId?: string | null;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  customerId?: string | null;
  orderCategoryId?: string | null;
  invoiceRecipientId?: string | null;
  contactPersonIds?: string[] | null;
  type: ProjectType;
  /** @format int64 */
  estimate?: number | null;
  /** @format int32 */
  price?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  /** @format date-time */
  actualStart?: string | null;
  /** @format date-time */
  actualEnd?: string | null;
  tagIds?: string[] | null;
}

export interface UpdateProjectActivity {
  description?: string | null;
  /** @format date-time */
  start: string;
  /** @format date-time */
  end?: string | null;
  tagIds?: string[] | null;
  participants?: string[] | null;
  suggestToOthers?: boolean;
  links: ProjectActivityTypeLinks;
}

export interface UpdateReport {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  reportPeriod?: ReportPeriod;
  /** @format date-time */
  from?: string | null;
  /** @format date-time */
  to?: string | null;
  modules?: string | null;
}

export interface UpdateRoutine {
  /** @minLength 1 */
  title: string;
  description?: string | null;
  severity?: RoutineSeverityType;
  frequency?: RoutineFrequency;
  /** @minLength 1 */
  ownerId: string;
  responsibleIds?: string[] | null;
  allEmployees?: boolean;
  organizationId?: string | null;
  resourceId?: string | null;
  /** @minLength 1 */
  categoryId: string;
  checklist?: string[] | null;
  tagIds?: string[] | null;
}

export interface UpdateSalaryRequest {
  /** @format date-time */
  start?: string;
  /** @format date-time */
  end?: string;
  requireCalculation?: boolean;
}

export interface UpdateSensor {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  resourceId?: string | null;
}

export interface UpdateSubscription {
  /** @minLength 1 */
  customerId: string;
  invoiceReceiverId?: string | null;
  description?: string | null;
  tagIds?: string[] | null;
  changeType?: UpdateSubscriptionType;
}

export interface UpdateSubscriptionType {
  /** @format date-time */
  application: string;
  type: SubscriptionType;
}

export interface UpdateTask {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  ownerId?: string | null;
  assigneeId?: string | null;
  requesterId?: string | null;
  categoryId?: string | null;
  /** @format int64 */
  estimate?: number | null;
  /** @format double */
  discount?: number | null;
  /** @format date-time */
  plannedStart?: string | null;
  /** @format date-time */
  plannedEnd?: string | null;
  tagIds?: string[] | null;
  type?: TaskType;
}

export interface VacationRecord {
  /** @format double */
  vacation?: number | null;
  /** @format double */
  transferredFromLastYear?: number | null;
}

export interface VacationView {
  employee?: EmployeeLinkView;
  /** @format double */
  fromLastYear?: number | null;
  /** @format double */
  toNextYear?: number | null;
  /** @format double */
  vacation?: number | null;
}

export interface WebAuthenticationInfo {
  isAuthenticated?: boolean;
  tenantId?: string | null;
  applicationId?: string | null;
  userInfo?: WebUserInfo;
}

export interface WebUserInfo {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  description?: string | null;
  actorType?: ActorType;
  tags?: TagLinkView[] | null;
  addresses?: AddressCollectionItem[] | null;
  contactPoints?: ContactPointLinkView[] | null;
  hasAvatar?: boolean;
  handlers?: HandlerView[] | null;
  metadata?: ActorMetadataView;
  isEmployee?: boolean;
  organizationRoles?: OrganizationRoleView[] | null;
  customerInfo?: CustomerInfoView;
  customerNumbers?: CustomerNumberView[] | null;
  supplierInfo?: SupplierInfoView;
  supplierNumbers?: SupplierNumberView[] | null;
  actorId?: string | null;
  handlerCompanies?: string[] | null;
  capabilities?: string[] | null;
  roleIds?: string[] | null;
  accountUrl?: string | null;
}

export enum WorkCategorySortOption {
  Name = "Name",
  LastModified = "LastModified",
}

export interface WorkCategoryView {
  id?: string | null;
  deleted?: boolean;
  changeVector?: string | null;
  systemPreset?: boolean;
  /** @format date-time */
  created?: string | null;
  createdBy?: PrincipalView;
  /** @format date-time */
  lastModified?: string | null;
  lastModifiedBy?: PrincipalView;
  attributes?: Record<string, string | null>;
  name?: string | null;
  color?: string | null;
  description?: string | null;
  product?: ProductLinkView;
  limitedToActivityTypes?: ActivityType[] | null;
  informationText?: string | null;
}

export interface WorkCategoryViewEntityViewModel {
  view?: WorkCategoryView;
  links?: Record<string, EntityLinkValueView>;
}

export interface WorkCategoryViewPagedWorkCategoryQueryCollectionViewModel {
  items?: WorkCategoryView[] | null;
  query?: PagedWorkCategoryQuery;
  /** @format int64 */
  count?: number;
}
