import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import client from "../ApiClient/client";
import { ExternalLinkView } from "../ApiClient/swagger/data-contracts";
import AppContext from "../Definitions/AppContext";
import { Capabilities } from "../Definitions/_capabilties";
import { MenuGroupIds, sessionStorageKey } from "../Definitions/_definitions";
import { IAppMenu, IAppMenuGroup, IAppMenuItem } from "../Models/MenuAndRouteConfig";
import { ItemType } from "antd/es/menu/interface";


interface AppMenuProps {
    onMenuClick?: () => void;
    menus: (IAppMenuGroup | IAppMenuItem)[];
    collapsed: boolean;
}

function AppMenu({ onMenuClick, menus, collapsed }: AppMenuProps) {

    const location = useLocation();
    const context = useContext(AppContext);

    const [openMenuGroups, setOpenMenuGroups] = useState<string[]>();
    const [locationKeys, setLocationKeys] = useState<string[]>();
    const [externalLinks, setExternalLinks] = useState<ExternalLinkView[]>();

    function getSessionMenus() {
        const openKeysString = sessionStorage.getItem(sessionStorageKey);
        return openKeysString == null ? null : openKeysString.split(",");
    }

    function onOpenMenuGroup(openKeys: string[]) {
        sessionStorage.setItem(sessionStorageKey, openKeys.toString());
        setOpenMenuGroups(openKeys);
    }

    function isMenuGroup(toBeDetermined: IAppMenu): toBeDetermined is IAppMenuGroup {
        if ((toBeDetermined as IAppMenuGroup).items) {
            return true;
        }
        return false;
    }

    function mapExternalLink(externalUrl: string) {
        const mappedUrl = externalUrl;

        if (mappedUrl.startsWith("http"))
            return mappedUrl;

        return '//' + mappedUrl;
    }

    const mapMenuItem = useCallback((menu: IAppMenu, externalLinks: ExternalLinkView[]) => {
        const isGroup = isMenuGroup(menu);
        const menuGroup = isGroup ? menu as IAppMenuGroup : null;
        const menuItem = isGroup ? null : menu as IAppMenuItem;

        if (menuGroup && menuGroup.id == MenuGroupIds.Links) {
            menuGroup.items = _.map(externalLinks, (link: ExternalLinkView) => {
                return {
                    id: link.id,
                    title: link.name,
                    route: link.url,
                    isExternal: true,
                    requiredCapability: Capabilities.MenuExternalLinks,
                    icon: null,
                } as IAppMenuItem;
            });
        }

        const children = menuGroup ? _.map(menuGroup.items ?? [], i => mapMenuItem(i, externalLinks)) : null

        if (menuItem && (!menuItem.requiredCapability || (menuItem.requiredCapability && !context.user.hasCapability(menuItem.requiredCapability)))) {
            return null;
        }

        const hasChildren = _.filter(children ?? [], c => c != null).length > 0;

        if (menuGroup && !hasChildren && !menuItem) return null;

        const item: ItemType = {
            key: menu.id,
            label: menuGroup ? menuGroup.title : <Link to={menuItem.isExternal ? mapExternalLink(menuItem.route) : menuItem.route} target={menuItem.isExternal ? "_blank" : undefined}>{!collapsed ? menuItem.title : ""}</Link>,
            children: children,
            icon: menuItem?.icon ?? null,
        };

        return item;
    }, [collapsed, context.user]);

    async function LoadExternalLinks() {
        if (context.user.hasCapability(Capabilities.ExternalLinksRead)) {
            const links = await client.externalLinks.queryExternalLinks({ group: "menu", limit: 100, deleted: false });
            if (links.data) {
                setExternalLinks(links.data.items);
            }
        }
    }

    useEffect(() => {
        LoadExternalLinks();
    }, []);

    useEffect(() => {
        const openKeys = getSessionMenus();
        if (openKeys != null) setOpenMenuGroups(openKeys);

        const keys = _.filter(location.pathname.split('/') ?? [], p => p != null && p.length > 0);
        if (keys != locationKeys)
            setLocationKeys(keys);

    }, [location.pathname])


    const memoizedMenuItems = useMemo(() => {
        return _.filter(_.map(menus ?? [], menu => mapMenuItem(menu, externalLinks ?? [])), m => m != null);
    }, [menus, mapMenuItem, externalLinks]);

    const menu = useMemo(() => (
        <Menu
            items={memoizedMenuItems}
            mode="inline"
            selectedKeys={!locationKeys || locationKeys.length == 0 ? ["dashboard"] : locationKeys}
            defaultOpenKeys={[MenuGroupIds.Overview, MenuGroupIds.Sales, MenuGroupIds.Documents, MenuGroupIds.Links, MenuGroupIds.Sensors, MenuGroupIds.Reports, MenuGroupIds.Company, MenuGroupIds.Operations, MenuGroupIds.Admin]}
            openKeys={openMenuGroups}
            onOpenChange={onOpenMenuGroup}
            onClick={context.isMobile && onMenuClick ? () => onMenuClick() : null}
            inlineIndent={12}
            />
    ), [memoizedMenuItems, locationKeys, openMenuGroups, context.isMobile, onMenuClick]);


    if (context.isMobile) return menu;

    return <Sider
        theme="light"
        className={`menu-sider-container ${context.isMobile ? "menu-sider-container-mobile" : "menu-sider-container-desktop"}`}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={220}
    >
        {menu}
    </Sider>;
}

export default AppMenu;