import * as React from "react";
import _ from 'lodash';
import AppContext from "../../Definitions/AppContext";
import { ActorLinkView, CallTargetView, ContactPointType, ContactPointView, ContactSortOption, OrganizationView, PersonView, UpdateContactPoint } from "../../ApiClient/swagger/data-contracts";
import { useState } from "react";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { Drawer } from "../../Modules/Shared/Drawer";
import ContactPointTypeSelector from "./ContactPointTypeSelector";
import { Button } from "antd";
import { PersonCreateForm } from "../People";
import { OrganizationCreateForm } from "../Organizations";
import { AdditionalInfo } from "../Shared/AdditionalInfo";
import { TextAreaInput } from "../Shared/TextAreaInput";
import ContactSelector from "../Contacts/ContactSelector";
import { TextInput } from "../Shared/TextInput";

interface EditContactPointProps {
    contactPoint: CallTargetView | ContactPointView;
    onComplete: (cp: ContactPointView) => void;
    actorId?: string;
    organization?: Partial<OrganizationView>;
    onCancel?: () => void;
    onDelete?: (cp: ContactPointView) => void;
}

export function ContactPointEditForm(props: EditContactPointProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [showCreatePersonDrawer, setShowCreatePersonDrawer] = useState<boolean>(false);
    const [showCreateOrganizationDrawer, setShowCreateOrganizationDrawer] = useState<boolean>(false);
    const [person, setPerson] = useState<Partial<PersonView> | ActorLinkView>(props.contactPoint?.contact);
    const [organization, setOrganization] = useState<Partial<OrganizationView>>(props.organization);

    const context = React.useContext(AppContext);

    async function onSubmit(request: UpdateContactPoint) {
        setLoading(true);

        if (props.actorId)
            request.contactId = props.actorId;

        else if (person)
            request.contactId = person.id;

        //if (!request.updatedValue || (request.updatedValue == props.contactPoint.value))
        //    request.updatedValue = null;

        const response = await client.contactPoints
            .updateContactPoint(props.contactPoint.id, request, null)
            .catch(exception => setError(exception.error));

        if (response) props.onComplete(response.data);

        setLoading(false);
    }

    async function onDelete() {
        setLoading(true);

        if (props.contactPoint != null) {

            const response = await client.contactPoints
                .deleteContactPoint(props.contactPoint.id, null)
                .catch(exception => setError(exception.error));

            if (response) { props.onDelete(response.data);}
        }
        setLoading(false);
    }

    function onPersonChange(person: PersonView) {
        setPerson(person);
    }

    function toggleAddPersonDrawer() {
        setShowCreatePersonDrawer((current) => !current);
    }

    function toggleAddOrganizationDrawer() {
        setShowCreateOrganizationDrawer((current) => !current);
    }

    async function onCompleteAddPerson(data) {
        if (data) {
            setPerson(data);
            toggleAddPersonDrawer();
        }
    }

    async function onCompleteAddOrganization(data) {
        if (data) {
            setOrganization(data);
            toggleAddOrganizationDrawer();
        }
    }

    if (!props.contactPoint) return <div>No contact point</div>;

    return (
        <BaseForm
            type={FormType.Edit}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={props.onCancel}
            isDeleted={false}
            loading={loading}
            error={error}
            className="cp-form"
            initialValues={{
                contactId: props.actorId ? props.actorId : props.contactPoint.contact ? props.contactPoint.contact.id : person ? person.id : organization ? organization.id : null,
                description: props.contactPoint.description,
                type: props.contactPoint.type,
                value: props.contactPoint.value
            }}
        >
            <SelectorInput
                title="Type"
                param="type"
                required
                selector={<ContactPointTypeSelector excludeAllOption disabled disableOptionIds={Object.values(ContactPointType)} className="cp-type-selector" type="radio" />}
                warningMessage="Please choose a type"
            />


            <TextInput
                param="value"
                required
                warningMessage="This field is required"
                placeholder="Enter contact info"
                title="Contact info"
            />

            {props.actorId ?
                null :
                <SelectorInput
                    title={
                        <React.Fragment>
                            <span style={{ width: '100%' }}>Contact</span>
                            <Button type="link" onClick={toggleAddPersonDrawer} className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>{context.isMobile ? "+ Add" : "+ Add contact"}</Button>
                            <Button type="link" onClick={toggleAddOrganizationDrawer} className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>{context.isMobile ? "+ Add" : "+ Add organization"}
                            </Button>
                        </React.Fragment>}
                    param="contactId"
                    required
                    selector={
                        <ContactSelector
                            onObjectChange={onPersonChange}
                            filters={{
                                sortBy: ContactSortOption.Name
                            }}
                        />
                    }
                />
            }

            <Drawer
                title="Create contact"
                onClose={toggleAddPersonDrawer}
                open={showCreatePersonDrawer}
                destroyOnClose={true}
                component={
                    <PersonCreateForm
                        onCancel={toggleAddPersonDrawer}
                        onComplete={(created) => created ? onCompleteAddPerson(created) : toggleAddPersonDrawer()}
                        cp={props.contactPoint}
                    />
                }
            />

            <Drawer
                title="Create organization"
                onClose={toggleAddOrganizationDrawer}
                open={showCreateOrganizationDrawer}
                destroyOnClose={true}
                component={showCreateOrganizationDrawer ?
                    <OrganizationCreateForm
                        onCancel={toggleAddOrganizationDrawer}
                        onComplete={(created) => created ? onCompleteAddOrganization(created) : toggleAddOrganizationDrawer()}
                        cp={props.contactPoint}
                    /> : null
                }
            />


            <AdditionalInfo defaultOpen={props.contactPoint?.description != null}>
                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                    rows={4}
                />
            </AdditionalInfo>

        </BaseForm>
    );
}

export default ContactPointEditForm;