import * as React from "react";
import _ from 'lodash';
import { formatMonthNr } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import { RequireCapability } from "../Shared/RequireCapability";
import { Statistics } from "../Shared/Statistics";
import { StackedColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import type { ActionType } from '@rc-component/trigger/lib/interface';
interface StatisticsProps {
    loading: boolean;
    aggregates: any;
    createActions?: any;
    onExportClick?: () => void;
    exporting?: boolean;
    activeQuickSearch: Record<string, boolean>;
    loadAggregates: () => void;
}

const hoverText = "Potential revenue are calculated based on active and postponed leads. When years are presented in the graph you can click them to drill down on months.";

interface StatisticsState { }

export class LeadStatisticsView extends React.Component<StatisticsProps, StatisticsState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    formatMonths = (data) => {
        const month = data.split("|")[1];
        return month ? formatMonthNr(month) : data;
    }

    render = () => {
        const { aggregates } = this.props;
        const activeQuickSearchesText = [];

        _.each(this.props.activeQuickSearch ?? {}, (value, key) => {
            if (value == true) {
                activeQuickSearchesText.push(key);
            }
        });
        const sumText = activeQuickSearchesText.length > 0 ? "Sum - " + activeQuickSearchesText.join(", ") : "Sum";

        const fixedPerYearAndMonth = aggregates ? aggregates["fixedPerMonth"] ?? {} : {};
        const annualPerYearAndMonth = aggregates ? aggregates["annualPerMonth"] ?? {} : {};

        const triggers: ActionType[] = ["click"];
        if (!this.context.isMobile)
            triggers.push("hover");

        return (
            <RequireCapability capability={Capabilities.LeadsRead}>
                <Statistics
                    className="lead-statistics-view"
                    actions={this.props.createActions ? this.props.createActions : null}
                    onExportClick={this.props.onExportClick ? this.props.onExportClick : null}
                    exporting={this.props.exporting ? this.props.exporting : null}
                    loadAggregates={this.props.loadAggregates}
                >

                    <StackedColumnChartCard
                        title={sumText}
                        cardClassName="sum"
                        valueBy={ValueType.Price}
                        xField="label"
                        enableLegend={true}
                        totalDisplayCount={999999}
                        height={200}
                        loading={this.props.loading}
                        data={[
                            {
                                data: aggregates ? aggregates["fixedPerStatus"] ?? [] : [],
                                color: '#A6D4F1',
                                seriesName: "Fixed",
                                seriesYField: "sum",
                                stacking: "normal"

                            },
                            {
                                data: aggregates ? aggregates["annualPerStatus"] ?? [] : [],
                                color: '#3589B6',
                                seriesName: "Annual",
                                seriesYField: "sum",
                                stacking: "normal"
                            }
                        ]}
                    />

                    <StackedColumnChartCard
                        title={
                            <div>Potential revenue
                                {(Object.keys(fixedPerYearAndMonth).length === 1 || Object.keys(annualPerYearAndMonth).length === 1) && 
                                    ` (${Object.keys(fixedPerYearAndMonth).length === 1 ? Object.keys(fixedPerYearAndMonth)[0] : Object.keys(annualPerYearAndMonth)[0]})`}
                                <Tooltip title={hoverText} trigger={triggers}>
                                    <InfoCircleOutlined className="information-icon" onClick={(e) => e.stopPropagation()} />
                                </Tooltip>
                            </div>}
                        cardClassName="potential-revenue"
                        valueBy={ValueType.Price}
                        xField="label"
                        enableLegend={true}
                        totalDisplayCount={999999}
                        height={200}
                        loading={this.props.loading}
                        //sortBy="label"
                        stopSort
                        xAxisFormatter={this.formatMonths}
                        data={[
                            {
                                data: Object.keys(fixedPerYearAndMonth).length == 1 ? fixedPerYearAndMonth[Object.keys(fixedPerYearAndMonth)[0]]?.drilldown ?? [] : fixedPerYearAndMonth,
                                color: '#A6D4F1',
                                seriesName: "Fixed",
                                seriesYField: "sum",
                                stacking: "normal",
                                drilldownField: "drilldown",
                                drilldownXField: "label",
                                drilldownYField: "sum"
                            },
                            {
                                data: Object.keys(annualPerYearAndMonth).length == 1 ? annualPerYearAndMonth[Object.keys(annualPerYearAndMonth)[0]]?.drilldown ?? [] : annualPerYearAndMonth,
                                color: '#3589B6',
                                seriesName: "Annual",
                                seriesYField: "sum",
                                stacking: "normal",
                                drilldownField: "drilldown",
                                drilldownXField: "label",
                                drilldownYField: "sum"
                            }
                        ]}
                    />
                </Statistics>
            </RequireCapability>
        );
    }
}