/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommentSortOption,
  CommentView,
  CommentViewEntityViewModel,
  CommentViewPagedCommentQueryCollectionViewModel,
  CreateComment,
  CreateFileOrFolder,
  CreateOrUpdateExecution,
  CreateRoutine,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  PagedRoutineExecutionQuery,
  PreviewType,
  RevertFileToVersion,
  RoutineAggregationResultRoutineAggregateQueryAggregationResults,
  RoutineAggregationTypes,
  RoutineExecutionSortOption,
  RoutineExecutionStatus,
  RoutineExecutionType,
  RoutineExecutionView,
  RoutineExecutionViewPagedRoutineExecutionQueryCollectionViewModel,
  RoutineSeverityType,
  RoutineSortOption,
  RoutineView,
  RoutineViewEntityViewModel,
  RoutineViewPagedRoutineQueryCollectionViewModel,
  SortDirection,
  UpdateComment,
  UpdateFileOrFolder,
  UpdateRoutine,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Routines<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Routine
   * @name GetRoutineById
   * @request GET:/api/routines/{id}
   * @secure
   */
  getRoutineById = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineViewEntityViewModel, any>({
      path: `/api/routines/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name UpdateRoutine
   * @request PUT:/api/routines/{id}
   * @secure
   */
  updateRoutine = (id: string, data: UpdateRoutine, params: RequestParams = {}) =>
    this.http.request<RoutineView, any>({
      path: `/api/routines/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name DeleteRoutine
   * @request DELETE:/api/routines/{id}
   * @secure
   */
  deleteRoutine = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineView, any>({
      path: `/api/routines/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name CreateRoutine
   * @request POST:/api/routines
   * @secure
   */
  createRoutine = (data: CreateRoutine, params: RequestParams = {}) =>
    this.http.request<RoutineView, any>({
      path: `/api/routines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name QueryRoutines
   * @request GET:/api/routines
   * @secure
   */
  queryRoutines = (
    query?: {
      sortBy?: RoutineSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      ownerId?: string;
      responsibleIds?: string[];
      executeableByActorId?: string;
      organizationId?: string;
      resourceId?: string;
      severities?: RoutineSeverityType[];
      categoryIds?: string[];
      /** @format date-time */
      nextExecutionStart?: string;
      /** @format date-time */
      nextExecutionEnd?: string;
      /** @format date-time */
      originalExecutionStart?: string;
      /** @format date-time */
      originalExecutionEnd?: string;
      executionTypes?: RoutineExecutionType[];
      isRecurring?: boolean;
      isOverdue?: boolean;
      hasUpcomingExecutions?: boolean;
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoutineViewPagedRoutineQueryCollectionViewModel, any>({
      path: `/api/routines`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name RestoreRoutine
   * @request POST:/api/routines/{id}/restore
   * @secure
   */
  restoreRoutine = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineView, any>({
      path: `/api/routines/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name UpdateRoutineAttributes
   * @request POST:/api/routines/{id}/attributes
   * @secure
   */
  updateRoutineAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<RoutineView, any>({
      path: `/api/routines/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name QueryAllExecutions
   * @request GET:/api/routines/_all/executions
   * @secure
   */
  queryAllExecutions = (
    query?: {
      sortBy?: RoutineExecutionSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      executeableByActorId?: string;
      /** @format date-time */
      nextExecutionStart?: string;
      /** @format date-time */
      nextExecutionEnd?: string;
      /** @format date-time */
      changingExecutionStart?: string;
      /** @format date-time */
      changingExecutionEnd?: string;
      /** @format date-time */
      actualExecutionStart?: string;
      /** @format date-time */
      actualExecutionEnd?: string;
      routineId?: string;
      resourceId?: string;
      statuses?: RoutineExecutionStatus[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoutineExecutionViewPagedRoutineExecutionQueryCollectionViewModel, any>({
      path: `/api/routines/_all/executions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name QueryRoutineExecutions
   * @request GET:/api/routines/{id}/executions
   * @secure
   */
  queryRoutineExecutions = (id: string, data: PagedRoutineExecutionQuery, params: RequestParams = {}) =>
    this.http.request<RoutineExecutionViewPagedRoutineExecutionQueryCollectionViewModel, any>({
      path: `/api/routines/${id}/executions`,
      method: "GET",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name CreateExecution
   * @request POST:/api/routines/{id}/executions
   * @secure
   */
  createExecution = (id: string, data: CreateOrUpdateExecution, params: RequestParams = {}) =>
    this.http.request<RoutineExecutionView, any>({
      path: `/api/routines/${id}/executions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name UpdateExecution
   * @request PUT:/api/routines/{id}/executions/{executionId}
   * @secure
   */
  updateExecution = (id: string, executionId: string, data: CreateOrUpdateExecution, params: RequestParams = {}) =>
    this.http.request<RoutineExecutionView, any>({
      path: `/api/routines/${id}/executions/${executionId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name DeleteExecution
   * @request DELETE:/api/routines/{id}/executions/{executionId}
   * @secure
   */
  deleteExecution = (id: string, executionId: string, params: RequestParams = {}) =>
    this.http.request<RoutineExecutionView, any>({
      path: `/api/routines/${id}/executions/${executionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name ReadExecutionAttachment
   * @request GET:/api/routines/{id}/executions/{executionId}/attachment/{attachmentName}
   * @secure
   */
  readExecutionAttachment = (
    id: string,
    executionId: string,
    attachmentName: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/routines/${id}/executions/${executionId}/attachment/${attachmentName}`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name UpdateExecutionAttachment
   * @request POST:/api/routines/{id}/executions/{executionId}/attachment
   * @secure
   */
  updateExecutionAttachment = (
    id: string,
    executionId: string,
    data: {
      /** @format binary */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoutineExecutionView, any>({
      path: `/api/routines/${id}/executions/${executionId}/attachment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name DeleteExecutionAttachment
   * @request DELETE:/api/routines/{id}/executions/{executionId}/attachment/{fileRef}
   * @secure
   */
  deleteExecutionAttachment = (id: string, executionId: string, fileRef: string, params: RequestParams = {}) =>
    this.http.request<RoutineExecutionView, any>({
      path: `/api/routines/${id}/executions/${executionId}/attachment/${fileRef}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name GetRoutineAggregates
   * @request GET:/aggs/routines
   * @secure
   */
  getRoutineAggregates = (
    query?: {
      routineId?: string;
      aggs?: RoutineAggregationTypes[];
      ownerId?: string;
      responsibleIds?: string[];
      executeableByActorId?: string;
      organizationId?: string;
      resourceId?: string;
      severities?: RoutineSeverityType[];
      categoryIds?: string[];
      /** @format date-time */
      nextExecutionStart?: string;
      /** @format date-time */
      nextExecutionEnd?: string;
      /** @format date-time */
      originalExecutionStart?: string;
      /** @format date-time */
      originalExecutionEnd?: string;
      executionTypes?: RoutineExecutionType[];
      isRecurring?: boolean;
      isOverdue?: boolean;
      hasUpcomingExecutions?: boolean;
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoutineAggregationResultRoutineAggregateQueryAggregationResults, any>({
      path: `/aggs/routines`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Routine
   * @name GetRoutineAggregatesById
   * @request GET:/aggs/routines/{id}
   * @secure
   */
  getRoutineAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/routines/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name GetRoutineCommentById
   * @request GET:/api/routines/{entityId}/comments/{commentId}
   * @secure
   */
  getRoutineCommentById = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentViewEntityViewModel, any>({
      path: `/api/routines/${entityId}/comments/${commentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name UpdateRoutineComment
   * @request PUT:/api/routines/{entityId}/comments/{commentId}
   * @secure
   */
  updateRoutineComment = (entityId: string, commentId: string, data: UpdateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/routines/${entityId}/comments/${commentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name DeleteRoutineComment
   * @request DELETE:/api/routines/{entityId}/comments/{commentId}
   * @secure
   */
  deleteRoutineComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/routines/${entityId}/comments/${commentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name CreateRoutineComment
   * @request POST:/api/routines/{entityId}/comments
   * @secure
   */
  createRoutineComment = (entityId: string, data: CreateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/routines/${entityId}/comments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name QueryRoutineComments
   * @request GET:/api/routines/{entityId}/comments
   * @secure
   */
  queryRoutineComments = (
    entityId: string,
    query?: {
      sortBy?: CommentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CommentViewPagedCommentQueryCollectionViewModel, any>({
      path: `/api/routines/${entityId}/comments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name RestoreRoutineComment
   * @request POST:/api/routines/{entityId}/comments/{commentId}/restore
   * @secure
   */
  restoreRoutineComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/routines/${entityId}/comments/${commentId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineComment
   * @name AddRoutineCommentEmoji
   * @request POST:/api/routines/{entityId}/comments/{commentId}/reactions/{emojicode}
   * @secure
   */
  addRoutineCommentEmoji = (entityId: string, commentId: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/routines/${entityId}/comments/${commentId}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name GetRoutineFileById
   * @request GET:/api/routines/{entityId}/files/{fileId}
   * @secure
   */
  getRoutineFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/routines/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name UpdateRoutineFile
   * @request PUT:/api/routines/{entityId}/files/{fileId}
   * @secure
   */
  updateRoutineFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name DeleteRoutineFile
   * @request DELETE:/api/routines/{entityId}/files/{fileId}
   * @secure
   */
  deleteRoutineFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name DownloadRoutineFile
   * @request GET:/api/routines/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadRoutineFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/routines/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name PreviewRoutineFile
   * @request GET:/api/routines/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewRoutineFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/routines/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name CreateRoutineFile
   * @request POST:/api/routines/{entityId}/files
   * @secure
   */
  createRoutineFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name QueryRoutineFiles
   * @request GET:/api/routines/{entityId}/files
   * @secure
   */
  queryRoutineFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/routines/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name RestoreRoutineFile
   * @request POST:/api/routines/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreRoutineFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name MoveRoutineFile
   * @request POST:/api/routines/{entityId}/files/{fileId}/move
   * @secure
   */
  moveRoutineFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name RevertRoutineFile
   * @request POST:/api/routines/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertRoutineFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/routines/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name GetRoutineFolderById
   * @request GET:/api/routines/{entityId}/folders/{folderId}
   * @secure
   */
  getRoutineFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/routines/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name UpdateRoutineFolder
   * @request PUT:/api/routines/{entityId}/folders/{folderId}
   * @secure
   */
  updateRoutineFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/routines/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name DeleteRoutineFolder
   * @request DELETE:/api/routines/{entityId}/folders/{folderId}
   * @secure
   */
  deleteRoutineFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/routines/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name CreateRoutineFolder
   * @request POST:/api/routines/{entityId}/folders
   * @secure
   */
  createRoutineFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/routines/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name QueryRoutineFolders
   * @request GET:/api/routines/{entityId}/folders
   * @secure
   */
  queryRoutineFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/routines/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name RestoreRoutineFolder
   * @request POST:/api/routines/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreRoutineFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/routines/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineFile
   * @name MoveRoutineFolder
   * @request POST:/api/routines/{entityId}/{folderId}/move
   * @secure
   */
  moveRoutineFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/routines/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
